import { getService } from "reducers/service";

export const findSearchProfiles = async () => {
  const service = getService('settings-security-search-profiles');
  return await service.find(); 
}

export const findSearchGroups = async () => {
  const service = getService('settings-sec-group');
  return await service.find(); 
}

export const findSearchProfileGroups = async () => {
  const service = getService('settings-sec-profile-groups');
  return await service.find();
}

export const getSearchProfileGroups = async (ptsProfileID) => {
  const service = getService('settings-sec-profile-groups');
  return await service.get(ptsProfileID);
}

export const saveSearchProfileGroups = async (data) => {
  const service = getService('settings-sec-profile-groups');
  return await service.create(data);
}

export const findSearchClearanceLevels = async () => {
  const service = getService('settings-sec-clearance-level');
  return await service.find(); 
}

export const findSearchUsers = async () => {
  const service = getService('settings-security-search-users');
  return await service.find();
}

export const updateSearchUser = async (userId, data) => {
  const service = getService('settings-security-search-users');
  return await service.patch(userId, data);
}

export const createSearchUser = async data => {
  const service = getService('settings-security-search-users');
  return await service.create(data);
};

export const deleteSearchUser = async userId => {
  const service = getService('settings-security-search-users');
  return await service.remove(userId);
};

export const findSecComponents = async () => {
  const service = getService('settings-sec-component');
  return await service.find();
}

export const findSecGroupComponents = async () => {
  const service = getService('settings-sec-group-components');
  return await service.find();
}

export const saveGroupBooleans = async (data) => {
  const service = getService('settings-security-permissions-apps');
  return await service.create(data);
}

export const getGroupComponentPermission = async (groupId, componentId) => {
  const service = getService('settings-sec-group-components');
  return await service.get(groupId, {
    query: {groupId, componentId}
  });
}

export const updateGroupComponentPermission = async (id, data) => {
  const service = getService('settings-sec-group-components');
  return await service.patch(id, data);
}

export const updateGroupComponentPermission2 = async (data) => {
  const service = getService('settings-sec-group-components');
  return await service.create(data);
}

export const findClearanceLevels = async () => {
  const service = getService('settings-security-clearance-levels');
  return await service.find();
}

export const findUsersHistory = async () => {
  const service = getService('settings-security-users-history');
  return await service.find();
}

export const findSecCode = async (type) => {
  const service = getService('settings-security-codes');
  return await service.find({
    query: {type}
  });
}

export const createSecGroup = async (data) => {
  const service = getService('settings-sec-group');
  return await service.create(data);
}

export const createSecProfile = async (data) => {
  const service = getService('settings-security-search-profiles');
  return await service.create(data);
}

export const getGroup = async (ptsGroupID) => {
  const service = getService('settings-sec-group');
  return await service.get(ptsGroupID);
}

export const updateGroup = async (ptsGroupID, data) => {
  const service = getService('settings-sec-group');
  return await service.patch(ptsGroupID, data);
}

export const getProfile = async (ptsProfileID) => {
  const service = getService('settings-security-search-profiles');
  return await service.get(ptsProfileID);
}

export const updateProfile = async (ptsProfileID, data) => {
  const service = getService('settings-security-search-profiles');
  return await service.patch(ptsProfileID, data);
}

export const removeProfile = async (ptsProfileID) => {
  const service = getService('settings-security-search-profiles');
  return await service.remove(ptsProfileID);
}

export const findDistinctPermissionTypes = async () => {
  const service = getService('settings-create-new-permission');
  return await service.find();
}

export const createSecComponent = async (data) => {
  const service = getService('settings-create-new-permission');
  return await service.create(data);
}

export const findDefaultPermissions = async () => {
  const service = getService('settings-default-permissions');
  return await service.find();
}

export const saveDefaultPermissions = async (data) => {
  const service = getService('settings-default-permissions');
  return await service.create(data);
}

export const saveCodePermissions = async (data) => {
  const service = getService('settings-codes-permissions');
  return await service.create(data);
}

export const findDeletedProfiles = async () => {
  const service = getService('settings-security-search-profiles');
  return await service.find({query: {isDeleted: 1}}); 
}

export const restoreProfile = async (ptsProfileID) => {
  const service = getService('settings-security-search-profiles');
  return await service.patch(ptsProfileID, {IsDeleted: 0});
}
