import React, { useState, useEffect, Fragment } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CustomAutocomplete from '../../components/CustomAutocomplete';
import Button from '@material-ui/core/Button';
import { XGrid } from '@material-ui/x-grid';
import Tooltip from '../../components/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import { 
  saveCallTypeCapability, getAllRequiredCapabilities, removeCallTypeCapability
} from '../../reducers/EsnReducer';
import { handleError } from '../../reducers/ErrorReducer';
import { showSpinner, hideSpinner } from '../../reducers/UiReducer';
import settings from '../../config/settings';
const CapabilityCategories = [ ...settings.CapabilityCategories, 'All' ];

const useStyles = makeStyles(theme => ({
  wrap: {
    padding: theme.spacing(2),
    '& > h4': {
      marginBottom: 20,
    },
  },
  form: {
    margin: '0 -4px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  buttons: {
    alignSelf: 'baseline',
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  textField: {
    margin: '0 4px 8px',
  },
  EventType: {
    maxWidth: 360,
    flexBasis: 360,
    minWidth: 360,
    flexGrow: 1,
  },
  Category: {
    maxWidth: 130,
    flexBasis: 130,
    minWidth: 130,
    flexGrow: 1,
    margin: '0 4px 8px',
  },
  Esn: {
    maxWidth: 250,
    flexBasis: 250,
    minWidth: 250,
    flexGrow: 1,
    margin: '0 4px 8px',
  },
  Number: {
    maxWidth: 100,
    flexBasis: 100,
    minWidth: 100,
    flexGrow: 1
  },
  list: {
    width: '100%',
    height: 500,
  },
  actions: {
    width: '100%',
    padding: theme.spacing(1, 0, 2),
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const renderCapability = (params) => {
  const { Capabilities } = params.row;
  const caps = Capabilities.map((cap, idx) => {
    const { Code, Description, Quantity } = cap;
    return (
      <Fragment key={Code}>
        {idx > 0 && <>,&nbsp;</>}
        <Tooltip title={Description}><span>{Code}</span></Tooltip> ({Quantity})
      </Fragment>
    );
  });
  return caps
}

const CallTypeColumns = [
  { field: 'Code',          headerName: 'Code',      width: 150},
  { field: 'Description',   headerName: 'Description', width: 300 },
  { field: 'ESN',           headerName: 'ESN',         width: 300},
  { field: 'Capabilities',   headerName: 'Required Capabilities', width: 600, renderCell: renderCapability },
];

const CapabilityColumns = [
  { field: 'Code',          headerName: 'Code',         width: 150},
  { field: 'Description',   headerName: 'Description',  width: 300 },
  { field: 'Category',      headerName: 'Category',     width: 150 },
  { field: 'Quantity',      headerName: 'Quantity',     width: 100 },
];

function RequiredCapabilites(props) {
  const classes = useStyles();
  const { dictionary } = props;
  const { CapabilityTypes, ESN } = dictionary;
  const [Category, setCategory] = useState('All');
  const [CallType, setCallType] = useState(null);
  const [CapabilityType, setCapabilityType] = useState(null);
  const [Quantity, setQuantity] = useState(1);
  const [view, setView] = useState('list'); // list, edit, selection
  const [loaded, setLoaded] = useState(true);
  const [selection, setSelection] = useState([]);
  const [CallTypeCapabilities, setCallTypeCapabilities] = useState([]);
  const [selectedCallType, setSelectedCallType] = useState(null);
  const [capSelection, setCapSelection] = useState([]);
  const [editMode, setEditMode] = useState('add'); // add, edit
  const [esn, setEsn] = useState(null);

  useEffect(() => {
    getAllCapabilities();
    // eslint-disable-next-line
  }, []);

  const getAllCapabilities = async () => {
    setLoaded(false);
    let CallTypeCapabilities = null;
    try {
      const result = await getAllRequiredCapabilities();
      CallTypeCapabilities = result.map(CallType => {
        CallType.id = CallType.Code;
        CallType.Capabilities = CallType.Capabilities.map(cap => {
          const Capability = CapabilityTypes.find(c => c.Code === cap.Code);
          cap.Description = Capability ? Capability.Description : 'Capability not found!!!';
          cap.id = cap.Code;
          return cap;
        });
        return CallType;
      });

      let clone = [];
      CallTypeCapabilities.forEach(CallType => {
        const { Code, Description, Capabilities, id } = CallType;
        const cur = [];
        if (Capabilities.length === 0) cur.push(CallType);
        
        // create new Calltypes for each ESN in current Code
        Capabilities.forEach(cap => {
          const { ESN } = cap;
          let i;
          for (i=0; i<cur.length; i++) {
            if (cur[i].ESN === ESN) {
              cur[i].Capabilities.push(cap);
              break;
            }
          }
          if (i === cur.length) {
            const curId = ESN ? id + ESN : id;
            cur.push({ Code, Description, id: curId, ESN, Capabilities: [cap] });
          }
        });
        clone = [...clone, ...cur];
      });
     
      setCallTypeCapabilities(clone);
    } catch (err) {
      props.handleError(err, 'Cannot acquire data from the server');
    }
    setLoaded(true);
    return CallTypeCapabilities;
  }

  const handleQuantityChange = (ev) => {
    setQuantity(parseInt(ev.target.value));
  }

  const handleSelectChange = ({selectionModel}) => {
    setSelection(selectionModel);
  }

  const editCallType = () => {
    const id = selection[0];
    const CallType = CallTypeCapabilities.find(c => c.id === id);
    setCallType(CallType.Code);
    setSelectedCallType(CallType);
    setView('selection');
  }

  const addCapability = () => {
    setCapabilityType(null);
    setQuantity(1);
    setEditMode('add');
    setView('edit');
  }

  const editCapability = () => {
    const CapabilityType = capSelection[0];
    const Capability = selectedCallType.Capabilities.find(c => c.Code === CapabilityType);
    setQuantity(Quantity);
    setCapabilityType(Capability);
    setQuantity(Capability.Quantity);
    setEditMode('edit');
    setView('edit');
  }

  const refreshCapabilities = async () =>{
    const CallTypeCapabilities = await getAllCapabilities();
    if (CallTypeCapabilities) {
      const CallTypeCapability = CallTypeCapabilities.find(c => c.Code === CallType);
      CallTypeCapability && setSelectedCallType(CallTypeCapability);
    }
  }

  const delCapability = async () => {
    if (!window.confirm('Are you sure you want to remove this capability?')) return;
    const CapabilityType = capSelection[0];
    const CallType = selection[0];
    props.showSpinner();
    try {
      await removeCallTypeCapability({ CallType, CapabilityType });
      await refreshCapabilities();
    } catch (err) {
      props.handleError(err, 'Error deleting capability');
    }
    props.hideSpinner();
  }

  const save = async () => {
    const CapabilityCode = CapabilityType.Code;
    const ESN = esn ? esn.ESN : null;
    const saveObj = { CallType, CapabilityType: CapabilityCode, Quantity, ESN };
    props.showSpinner();
    try {
      await saveCallTypeCapability(saveObj);
      setCapabilityType(null);
      setEsn(null);
      await refreshCapabilities();
      setView('selection');
    } catch (err) {
      props.handleError(err, 'Error saving capability');
    }
    props.hideSpinner();
  }

  const handleCapSelectChange = ({selectionModel}) => {
    setCapSelection(selectionModel);
  }

  const renderCapActions = () => {
    const disabled = capSelection.length === 0;
    return (
      <div className={classes.actions}>
        <Fab size="small" color="secondary" onClick={addCapability}>
          <AddIcon />
        </Fab>
        <Fab size="small" color="secondary" onClick={editCapability} disabled={disabled}>
          <EditIcon />
        </Fab>
        <Fab size="small" color="secondary" onClick={delCapability} disabled={disabled}>
          <DeleteIcon />
        </Fab>
        <Fab size="small" color="secondary" onClick={() => setView('list')}>
          <CloseIcon />
        </Fab>
      </div>
    );
  }

  const renderSelection = () => {
    const {Code, Description, Capabilities } = selectedCallType;
    const filteredCapabilities = Category === 'All' 
      ? Capabilities : Capabilities.filter(c => c.Category === Category);
    return (
      <>
        <h4>{Code} - {Description}</h4>
        {view === 'edit' && renderEditType()}
        {view !== 'edit' && renderCapActions()}
        <div className={classes.list}>
        <XGrid
          columns={CapabilityColumns}
          rows={filteredCapabilities}
          rowHeight={38}
          onSelectionModelChange={handleCapSelectChange}
          disableMultipleSelection
          hideFooter
          disableSelectionOnClick={view === 'edit'}
        />
      </div>
      </>
    );
  }

  const onCategorySelect = (ev, selection) => {
    if (!selection) return;
    setCapabilityType(null);
    setCategory(selection);
  }

  const renderEditType = () => {
    const Capabilities = editMode === 'edit' ? CapabilityTypes : 
      CapabilityTypes.filter(t => !selectedCallType.Capabilities.find(c => c.Code === t.Code));
    const filteredCapabilities = Category === 'All' 
      ? Capabilities : Capabilities.filter(c => c.Category === Category);
    return (
      <>
      <div className={classes.form}>
        <Autocomplete
          options={CapabilityCategories}
          value={Category}
          className={classes.Category}
          renderInput={(params) => <TextField {...params} label="Capability Type" variant="outlined" />}
          onChange={onCategorySelect}
          size="small"
          disableClearable
        />
        <CustomAutocomplete 
          className={classes.EventType}
          label="Capability Type"
          options={filteredCapabilities}
          value={CapabilityType}
          onChange={val => setCapabilityType(val)}
          disabled={editMode === 'edit'}
        />
        <TextField
          className={clsx(classes.textField, classes.Number)}
          label="Quantity"
          variant="outlined"
          value={Quantity}
          onChange={handleQuantityChange}
          size="small"
          type="number"
          min={0}
          InputProps={{ inputProps: { min: 1 }}}
        />
        <Autocomplete
          options={ESN}
          value={esn}
          className={classes.Esn}
          renderInput={(params) => <TextField {...params} label="ESN" variant="outlined" />}
          onChange={(ev, val) => setEsn(val)}
          size="small"
          getOptionLabel={(option) => option.ESN ? option.ESN : ''}
          getOptionSelected={(option, value) => option.ESN === value.ESN}
          renderOption={(option) => {
            const { ESN, Description } = option;
            return (
              <span><strong>{ESN}</strong>{!!Description && <> - {Description}</>}</span>
            )
          }}
        />
        <div className={classes.buttons}>
          <Button 
            variant="contained"
            color="primary"
            disabled={!CapabilityType || !CallType || !Quantity}
            onClick={save}
          >
            <SaveIcon />
            Save
          </Button>
          <Button 
            color="primary"
            onClick={() => {setView('selection')}}
          >
            <CloseIcon />
            Close
          </Button>
        </div>
      </div>
      </>
    );
  }

  const renderActions = () => {
    const disabled = !selection || !selection.length;
    return (
      <div className={classes.actions}>
        <Fab size="small" color="secondary" onClick={editCallType} disabled={disabled}>
          <EditIcon />
        </Fab>
      </div>
    );
  }
  
  const renderCapabilities = () => {
    return (
      <div className={classes.list}>
      <XGrid
        columns={CallTypeColumns}
        rows={CallTypeCapabilities}
        loading={!loaded}
        rowHeight={38}
        onSelectionModelChange={handleSelectChange}
        disableMultipleSelection
        hideFooter
      />
    </div>
    );
  }

  return (
    <div className={classes.wrap}>
      {view === 'list' && renderActions()}
      {view === 'list' && renderCapabilities()}
      {(view === 'selection' || view === 'edit') && renderSelection()}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    dictionary: state.dictionary,
  }
}

export default connect(mapStateToProps, {
  handleError, showSpinner, hideSpinner 
})(RequiredCapabilites);
