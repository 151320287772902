const attachmentsSubMenu = [
  {
    type: 'link',
    label: 'Attachment Types',
    link: 'attachment-types',
    service: 'codeattachmenttypes',
  },
  /* { type: 'link', label: 'Image View Types', service: '' }, */
];

const CADSubMenu = [
  { type: 'link', label: 'Alert Types', link: 'alert-types', service: 'codealerttypes' },
  // { type: 'link', label: 'Call Methods', service: '' },
  {
    type: 'link',
    label: 'Capability Types',
    link: 'capability-types',
    service: 'codecapabilitytypes',
  },
  { type: 'link', label: 'Designations', link: 'designations', service: 'codedesignation' },
  { type: 'link', label: 'Dispositions', link: 'dispositions', service: 'code-dispositions' },
  {
    type: 'link',
    label: 'Party Relationship',
    link: 'party-relationships',
    service: 'codepartyrelationship',
    table: 'codePartyRelationship',
  },
  {
    type: 'link',
    label: 'Resource Categories',
    link: 'resource-categories',
    service: 'coderesourcecategories',
  },
  {
    type: 'link',
    label: 'Unit Action Modifier',
    link: 'unit-action-modifier',
    service: 'codeunitactionmodifier',
  },
  {
    type: 'link',
    label: 'Unit Actions',
    link: 'unit-actions',
    service: 'settings-unit-actions',
    table: 'codeUnitActions',
  },
  /* { type: 'link', label: 'ESNs', service: '' }, */
  /* { type: 'link', label: 'Event Types', service: '' }, */
  /* { type: 'link', label: 'Event Sub Types', service: '' }, */
];

const contactSubMenu = [
  {
    type: 'link',
    label: 'Contact Info Method Types',
    link: 'contact-info-method-types',
    service: 'codecontactinfomethodtypes',
  },
];

const featureSubMenu = [
  { type: 'link', label: 'Appearances', link: 'appearances', service: 'codeappearance' },
  { type: 'link', label: 'Blood Types', link: 'blood-types', service: 'codebloodtype' },
  { type: 'link', label: 'Builds', link: 'builds', service: 'codebuilds' },
  { type: 'link', label: 'Complexions', link: 'complexions', service: 'codecomplexions' },
  { type: 'link', label: 'Ethnicity', link: 'ethnicity', service: 'codeethnicity' },
  { type: 'link', label: 'Eye Colors', link: 'eye-colors', service: 'codeeyecolor' },
  { type: 'link', label: 'Eye Defects', link: 'eye-defects', service: 'codeeyedefect' },
  { type: 'link', label: 'Facial Hair', link: 'facial-hair', service: 'codefacialhair' },
  { type: 'link', label: 'Glasses', link: 'glasses', service: 'codeglasses' },
  { type: 'link', label: 'Hair Colors', link: 'hair-colors', service: 'codehaircolor' },
  { type: 'link', label: 'Hair Lengths', link: 'hair-lengths', service: 'codehairlength' },
  { type: 'link', label: 'Hair Styles', link: 'hair-styles', service: 'codehairstyle' },
  { type: 'link', label: 'Handed', link: 'handed', service: 'codehanded' },
  { type: 'link', label: 'Mark Locations', link: 'mark-locations', service: 'codemarklocation' },
  { type: 'link', label: 'Marks', link: 'marks', service: 'codemarks' },
  {
    type: 'link',
    label: 'Methods of Operation',
    link: 'methods-of-operation',
    service: 'codemethodofoperation',
  },
  {
    type: 'link',
    label: 'Speech Qualities',
    link: 'speech-qualities',
    service: 'codespeechquality',
  },
  { type: 'link', label: 'Talks', link: 'talks', service: 'codetalk' },
  { type: 'link', label: 'Teeth', link: 'teeth', service: 'codeteeth' },
];

const credentialsSubMenu = [
  { type: 'link', label: 'Classes', link: 'classes', service: 'codecredentialclasses' },
  { type: 'link', label: 'Types', link: 'types', service: 'codecredentialtypes' },
];
const measureSubMenu = [
  { type: 'link', label: 'Units of Measure', link: 'units-of-measure', service: 'codemeasures' },
];

const itemsSubMenu = [
  { type: 'link', label: 'Categories', link: 'categories', service: 'codeitemcategory' },
  { type: 'link', label: 'Conditions', link: 'conditions', service: 'codeitemconditions' },
  {
    type: 'link',
    label: 'DEA Drug Classes',
    link: 'dea-drug-classes',
    service: 'codedeadrugclasses',
  },
  {
    type: 'link',
    label: 'Dispositions',
    link: 'item-dispositions',
    service: 'codeitemdisposition',
  },
  {
    type: 'link',
    label: 'Firearm Calibers',
    link: 'firearm-calibers',
    service: 'codefirearmcaliber',
  },
  {
    type: 'link',
    label: 'Firearm Categories',
    link: 'firearm-categories',
    service: 'codefirearmcategory',
  },
  {
    type: 'link',
    label: 'Identification Categories',
    link: 'identification-categories',
    service: 'codeitemidentificationcategory',
  },
  {
    type: 'link',
    label: 'Jewelry Categories',
    link: 'jewelry-categories',
    service: 'codejewelrycategory',
  },
  { type: 'link', label: 'Makes', link: 'makes', service: 'codeitemmakes' },
  { type: 'link', label: 'Materials', link: 'materials', service: 'codematerial' },
  { type: 'link', label: 'Models', link: 'models', service: 'codeitemmodels' },
  { type: 'link', label: 'Statuses', link: 'statuses', service: 'codeitemstatus' },
  { type: 'link', label: 'Stone Types', link: 'stone-types', service: 'codestonetype' },
  {
    type: 'link',
    label: 'Substance Categories',
    link: 'substance-categories',
    service: 'codesubstancecategory',
    table: 'codeSubstanceCategory',
  },
  { type: 'link', label: 'Usages', link: 'usages', service: 'codeitemusage' },
];
const placeSubMenu = [
  { type: 'link', label: 'Types', link: 'place-types', service: 'codeplacetypes' },
];
const rotationSubMenu = [
  { type: 'link', label: 'Rotation', link: 'rotation', service: 'coderotation' },
];
const zonesSubMenu = [
  { type: 'link', label: 'Types', link: 'zone-types', service: 'codezonetypes' },
];

const vehicleSubMenu = [
  { type: 'link', label: 'Branders', link: 'vehicle-branders', service: 'codevehiclebranders' },
  { type: 'link', label: 'Brands', link: 'vehicle-brands', service: 'codevehiclebrands' },
  {
    type: 'link',
    label: 'Classifications',
    link: 'vehicle-classifications',
    service: 'codevehicleclassifications',
  },
  { type: 'link', label: 'Colors', link: 'vehicle-colors', service: 'codevehiclecolors' },
  {
    type: 'link',
    label: 'Commercials',
    link: 'vehicle-commercials',
    service: 'codevehiclecommercial',
  },

  {
    type: 'link',
    label: 'Garage Facilities',
    link: 'garage-facilities',
    service: 'codegaragefacilities',
  },
  { type: 'link', label: 'Makes', link: 'vehicle-makes', service: 'codevehiclemakes' },
  { type: 'link', label: 'Models', link: 'vehicle-models', service: 'codevehiclemodels' },
  { type: 'link', label: 'Make Models', link: 'make-models', service: 'code-vehicle-make-models' },
  { type: 'link', label: 'Plate Colors', link: 'plate-colors', service: 'codeplatecolors' },
  { type: 'link', label: 'Plate Details', link: 'plate-details', service: 'codeplatedetails' },
  { type: 'link', label: 'Plate Statuses', link: 'plate-statuses', service: 'codeplatestatuses' },
  { type: 'link', label: 'Plate Types', link: 'plate-types', service: 'codeplatetypes' },
  { type: 'link', label: 'Properties', link: 'vehicle-properties', service: 'codevehicleproperty' },
  { type: 'link', label: 'Styles', link: 'vehicle-styles', service: 'codevehiclestyles' },
  { type: 'link', label: 'Transmissions', link: 'transmissions', service: 'codetransmissions' },
];

const employmentSubMenu = [
  { type: 'link', label: 'Occupations', link: 'occupations', service: 'codeoccupations' },
  { type: 'link', label: 'Positions', link: 'positions', service: 'codepositions' },
  { type: 'link', label: 'Ranks', link: 'ranks', service: 'coderanks' },
  {
    type: 'link',
    label: 'Statuses',
    link: 'employment-statuses',
    service: 'codeemploymentstatuses',
  },
  {
    type: 'link',
    label: 'Termination Reasons',
    link: 'termination-reasons',
    service: 'codeterminationreasons',
  },
];

const PersonSubmenu = [
  {
    type: 'link',
    label: 'Associate Relationships',
    link: 'associate-relationships',
    service: 'codeassociaterelationships',
  },
  { type: 'link', label: 'Citizenships', link: 'citizenships', service: 'codecitizenships' },
  { type: 'link', label: 'Dangerous Types', link: 'dangerous-types', service: 'codedangeroustype' },
  { type: 'link', label: 'Death Causes', link: 'death-causes', service: 'codedeathcauses' },
  { type: 'link', label: 'Gangs', link: 'gangs', service: 'codegang' },
  { type: 'link', label: 'Languages', link: 'languages', service: 'codelanguages' },
  { type: 'link', label: 'Marital Status', link: 'marital-status', service: 'codemaritalstatus' },
  { type: 'link', label: 'Name Prefixes', link: 'name-prefixes', service: 'codenameprefixes' },
  { type: 'link', label: 'Name Suffixes', link: 'name-suffixes', service: 'codenamesuffixes' },
  { type: 'link', label: 'Name Types', link: 'name-types', service: 'codepersonnametypes' },
  { type: 'link', label: 'Nationalities', link: 'nationalities', service: 'codenationalities' },
  { type: 'link', label: 'Races', link: 'races', service: 'coderace', table: 'codeRace' },
  { type: 'link', label: 'Residents', link: 'resident', service: 'coderesident' },
  { type: 'link', label: 'Sexes', link: 'sexes', service: 'codesex', table: 'codeSex' },
];

const locationSubMenu = [
  {
    type: 'link',
    label: 'Address Building Types',
    link: 'address-building-types',
    service: 'codeaddressbldgtypes',
  },
  {
    type: 'link',
    label: 'Address Categories',
    link: 'address-categories',
    service: 'codeaddresscategories',
  },
  {
    type: 'link',
    label: 'Address Floor Types',
    link: 'address-floor-types',
    service: 'codeaddressfloortypes',
  },
  {
    type: 'link',
    label: 'Address Party Relationships',
    link: 'address-party-relationships',
    service: 'codeaddresspartyrelationship',
  },
  {
    type: 'link',
    label: 'Address Unit Types',
    link: 'address-unit-types',
    service: 'codeaddressunittypes',
  },
  { type: 'link', label: 'Cities', link: 'cities', service: 'codecities' },
  {
    type: 'link',
    label: 'Coordinate Datum Types',
    link: 'coordinate-datum-types',
    service: 'codecoordinatedatumtypes',
  },
  { type: 'link', label: 'Counties', link: 'counties', service: 'codecounties' },
  { type: 'link', label: 'Countries', link: 'countries', service: 'codecountries' },
  { type: 'link', label: 'Garage Types', link: 'garage-types', service: 'codegaragetypes' },
  {
    type: 'link',
    label: 'Pre Plan Construction Types',
    link: 'pre-plan-construction-types',
    service: 'codepreplanconstructiontypes',
  },
  {
    type: 'link',
    label: 'Pre Plan Occupancy Types',
    link: 'pre-plan-occupancy-types',
    service: 'codepreplanoccupancytypes',
  },
  {
    type: 'link',
    label: 'Pre Plan Roof Types',
    link: 'pre-plan-roof-types',
    service: 'codepreplanrooftypes',
  },
  { type: 'link', label: 'States', link: 'states', service: 'codestates' },
  {
    type: 'link',
    label: 'Street Directions',
    link: 'street-directions',
    service: 'codestreetdirections',
  },
  { type: 'link', label: 'Street Types', link: 'street-types', service: 'codestreettypes' },
  {
    type: 'link',
    label: 'Structure Colors',
    link: 'structure-colors',
    service: 'codestructurecolors',
  },
  /* { type: 'link', label: 'Categories', service: '' }, */
];

const rmsSubMenu = [
  {
    type: 'link',
    label: 'IBR Status',
    link: 'ibr-status',
    service: 'codeibrstatus',
    table: 'codeIBRStatus',
  },
  {
    type: 'link',
    label: 'Party Relationship',
    link: 'code-party-relationship',
    service: 'codepartyrelationship',
    table: 'codePartyRelationship',
  },
  {
    type: 'link',
    label: 'Tattoo Location',
    link: 'code-tattoo-location',
    service: 'codetattoolocation',
  },
  {
    type: 'link',
    label: 'Designation',
    link: 'code-designation',
    service: 'codedesignation',
  },
  {
    type: 'link',
    label: 'Appearance',
    link: 'code-appearance',
    service: 'codeappearance',
  },
  {
    type: 'link',
    label: 'Builds',
    link: 'code-builds',
    service: 'codebuilds',
  },
  {
    type: 'link',
    label: 'Complexions',
    link: 'code-eye-defect',
    service: 'codeeyedefect',
  },
  {
    type: 'link',
    label: 'Facial Hair',
    link: 'code-facial-hair',
    service: 'codefacialhair',
  },
  {
    type: 'link',
    label: 'Glasses',
    link: 'code-glasses',
    service: 'codeglasses',
  },
  {
    type: 'link',
    label: 'Method Of Operation',
    link: 'code-method-of-operation',
    service: 'codemethodofoperation',
  },
  {
    type: 'link',
    label: 'Speech Quality',
    link: 'code-speech-quality',
    service: 'codespeechquality',
  },
  {
    type: 'link',
    label: 'Talk',
    link: 'code-talk',
    service: 'codetalk',
  },
  {
    type: 'link',
    label: 'Teeth',
    link: 'code-teeth',
    service: 'codeteeth',
  },
  {
    type: 'link',
    label: 'Termination Reasons',
    link: 'code-termination-reasons',
    service: 'codeterminationreasons',
  },
  {
    type: 'link',
    label: 'Item Makes',
    link: 'code-item-makes',
    service: 'codeitemmakes',
  },
  {
    type: 'link',
    label: 'Vehicle Commercial',
    link: 'code-vehicle-commercial',
    service: 'codevehiclecommercial',
  },
  {
    type: 'link',
    label: 'Item Models',
    link: 'code-item-models',
    service: 'codeitemmodels',
  },
  {
    type: 'link',
    label: 'Evidence Category',
    link: 'code-evidence-category',
    service: 'codeevidencecategory',
  },
  {
    type: 'link',
    label: 'Ranks',
    link: 'code-ranks',
    service: 'coderanks',
  },
  {
    type: 'link',
    label: 'Pre Plan Rooftypes',
    link: 'code-pre-plan-roof-types',
    service: 'codepreplanrooftypes',
  },
  {
    type: 'link',
    label: 'Pre plan Occupancytypes',
    link: 'code-pre-plan-occupancy-types',
    service: 'codepreplanoccupancytypes',
  },
  {
    type: 'link',
    label: 'Pre Plan Constructiontypes',
    link: 'code-pre-plan-construction-types',
    service: 'codepreplanconstructiontypes',
  },
  {
    type: 'link',
    label: 'Positions',
    link: 'code-positions',
    service: 'codepositions',
  },
  {
    type: 'link',
    label: 'Occupations',
    link: 'code-occupations',
    service: 'codeoccupations',
  },
  {
    type: 'link',
    label: 'Nationalities',
    link: 'code-nationalities',
    service: 'codenationalities',
  },
  {
    type: 'link',
    label: 'Person Name Types',
    link: 'code-person-name-types',
    service: 'codepersonnametypes',
  },
  {
    type: 'link',
    label: 'Marital Status',
    link: 'code-marital-status',
    service: 'codemaritalstatus',
  },
  {
    type: 'link',
    label: 'Languages',
    link: 'code-languages',
    service: 'codelanguages',
  },
  {
    type: 'link',
    label: 'Gang',
    link: 'code-gang',
    service: 'codegang',
  },
  {
    type: 'link',
    label: 'Employment Statuses',
    link: 'code-employment-statuses',
    service: 'codeemploymentstatuses',
  },
  {
    type: 'link',
    label: 'Death Causes',
    link: 'code-death-causes',
    service: 'codedeathcauses',
  },
  {
    type: 'link',
    label: 'Dangeroustype',
    link: 'code-dangerous-type',
    service: 'codedangeroustype',
  },
  {
    type: 'link',
    label: 'Credentialtypes',
    link: 'code-credential-types',
    service: 'codecredentialtypes',
  },
  {
    type: 'link',
    label: 'Credential Classes',
    link: 'code-credentialclasses',
    service: 'codecredentialclasses',
  },
  {
    type: 'link',
    label: 'Countries',
    link: 'code-countries',
    service: 'codecountries',
  },
  {
    type: 'link',
    label: 'Counties',
    link: 'code-counties',
    service: 'codecounties',
  },
  {
    type: 'link',
    label: 'Contact Info Method Types',
    link: 'code-contact-info-method-types',
    service: 'codecontactinfomethodtypes',
  },
  {
    type: 'link',
    label: 'Citizenships',
    link: 'code-citizenships',
    service: 'codecitizenships',
  },
  {
    type: 'link',
    label: 'Builds',
    link: 'code-builds',
    service: 'codebuilds',
  },
  {
    type: 'link',
    label: 'Associate Relationships',
    link: 'code-associate-relationships',
    service: 'codeassociaterelationships',
  },
  {
    type: 'link',
    label: 'Address Building Types',
    link: 'code-address-bldg-types',
    service: 'codeaddressbldgtypes',
  },
  {
    type: 'link',
    label: 'Address Floor Types',
    link: 'code-address-floor-types',
    service: 'codeaddressfloortypes',
  },
  {
    type: 'link',
    label: 'Address Party Relationship',
    link: 'code-address-party-relationship',
    service: 'codeaddresspartyrelationship',
  },
  {
    type: 'link',
    label: 'Address Unit Types',
    link: 'code-address-unit-types',
    service: 'codeaddressunittypes',
  },
  {
    type: 'link',
    label: 'Bloodtype',
    link: 'code-bloodtype',
    service: 'codebloodtype',
  },
  {
    type: 'link',
    label: 'Capabilitytypes',
    link: 'code-capability-types',
    service: 'codecapabilitytypes',
  },
  {
    type: 'link',
    label: 'Coordinate Datum Types',
    link: 'code-coordinatedatumtypes',
    service: 'codecoordinatedatumtypes',
  },
  {
    type: 'link',
    label: 'Custody Options',
    link: 'code-custody-options',
    service: 'codecustodyoptions',
  },
  {
    type: 'link',
    label: 'DEA Drug Classes',
    link: 'code-dea-drug-classes',
    service: 'codedeadrugclasses',
  },
  {
    type: 'link',
    label: 'DNA Collection',
    link: 'code-dna-collection',
    service: 'codednacollection',
  },
  {
    type: 'link',
    label: 'DNA Sample Method',
    link: 'code-dnasamplemethod',
    service: 'codednasamplemethod',
  },
  {
    type: 'link',
    label: 'DNA Sample Reason',
    link: 'code-dna-sample-reason',
    service: 'codednasamplereason',
  },
  {
    type: 'link',
    label: 'DNA Sample Type',
    link: 'code-dna-sample-type',
    service: 'codednasampletype',
  },
  {
    type: 'link',
    label: 'Evidence Custody Reason',
    link: 'code-evidence-custody-reason',
    service: 'codeevidencecustodyreason',
  },
  {
    type: 'link',
    label: 'Evidence Custody Status',
    link: 'code-evidencecustodystatus',
    service: 'codeevidencecustodystatus',
  },
  {
    type: 'link',
    label: 'Evidence Disposal Code',
    link: 'code-evidencedisposalcode',
    service: 'codeevidencedisposalcode',
  },
  {
    type: 'link',
    label: 'Evidence Final Disposition',
    link: 'code-evidence-final-disposition',
    service: 'codeevidencefinaldisposition',
  },
  {
    type: 'link',
    label: 'Evidence Required Action',
    link: 'code-evidence-required-action',
    service: 'codeevidencerequiredaction',
  },
  {
    type: 'link',
    label: 'Evidence Storage Container',
    link: 'code-evidence-storage-container',
    service: 'codeevidencestoragecontainer',
  },
  {
    type: 'link',
    label: 'Evidence Storage Location',
    link: 'code-evidence-storage-location',
    service: 'codeevidencestoragelocation',
  },
  {
    type: 'link',
    label: 'Evidence Storage Sublocation',
    link: 'code-evidence-storage-sublocation',
    service: 'codeevidencestoragesublocation',
  },
  {
    type: 'link',
    label: 'Evidence Temporary Location',
    link: 'code-evidence-temporary-location',
    service: 'codeevidencetemporarylocation',
  },
  {
    type: 'link',
    label: 'Evidence Type',
    link: 'code-evidence-type',
    service: 'codeevidencetype',
  },
  {
    type: 'link',
    label: 'Eye Color',
    link: 'code-eye-color',
    service: 'codeeyecolor',
  },
  {
    type: 'link',
    label: 'Firearm Caliber',
    link: 'code-firearm-caliber',
    service: 'codefirearmcaliber',
  },
  {
    type: 'link',
    label: 'Firearm Category',
    link: 'code-firearm-category',
    service: 'codefirearmcategory',
  },
  {
    type: 'link',
    label: 'Garage Facilities',
    link: 'code-garage-facilities',
    service: 'codegaragefacilities',
  },
  {
    type: 'link',
    label: 'Garage Types',
    link: 'code-garage-types',
    service: 'codegaragetypes',
  },
  {
    type: 'link',
    label: 'Hair Color',
    link: 'code-hair-color',
    service: 'codehaircolor',
  },
  {
    type: 'link',
    label: 'Hair Length',
    link: 'code-hair-length',
    service: 'codehairlength',
  },
  {
    type: 'link',
    label: 'Hair Style',
    link: 'code-hair-style',
    service: 'codehairstyle',
  },
  {
    type: 'link',
    label: 'Handed',
    link: 'code-handed',
    service: 'codehanded',
  },
  {
    type: 'link',
    label: 'Incident Categories',
    link: 'code-incident-categories',
    service: 'codeincidentcategories',
  },
  {
    type: 'link',
    label: 'Incident Status',
    link: 'code-incident-status',
    service: 'codeincidentstatus',
  },
  {
    type: 'link',
    label: 'Incident Types',
    link: 'code-incident-types',
    service: 'codeincidenttypes',
  },
  {
    type: 'link',
    label: 'Item Conditions',
    link: 'code-item-conditions',
    service: 'codeitemconditions',
  },
  {
    type: 'link',
    label: 'Jewelry Category',
    link: 'code-jewelry-category',
    service: 'codejewelrycategory',
  },
  {
    type: 'link',
    label: 'Lighting',
    link: 'code-lighting',
    service: 'codelighting',
  },
  {
    type: 'link',
    label: 'Mark Location',
    link: 'code-mark-location',
    service: 'codemarklocation',
  },
  {
    type: 'link',
    label: 'Marks',
    link: 'code-marks',
    service: 'codemarks',
  },
  {
    type: 'link',
    label: 'Material',
    link: 'code-material',
    service: 'codematerial',
  },
  {
    type: 'link',
    label: 'Measures',
    link: 'code-measures',
    service: 'codemeasures',
  },
  {
    type: 'link',
    label: 'Mugshot Types',
    link: 'code-mugshot-types',
    service: 'codemugshottypes',
  },
  {
    type: 'link',
    label: 'Name Prefixes',
    link: 'code-name-prefixes',
    service: 'codenameprefixes',
  },
  {
    type: 'link',
    label: 'Name Suffixes',
    link: 'code-name-suffixes',
    service: 'codenamesuffixes',
  },
  {
    type: 'link',
    label: 'Narrative Types',
    link: 'code-narrative-types',
    service: 'codenarrativetypes',
  },
  {
    type: 'link',
    label: 'Negligent Manslaughter',
    link: 'code-negligent-manslaughter',
    service: 'codenegligentmanslaughter',
  },
  {
    type: 'link',
    label: 'Place Types',
    link: 'code-place-types',
    service: 'codeplacetypes',
  },
  {
    type: 'link',
    label: 'Plate Colors',
    link: 'code-plate-colors',
    service: 'codeplatecolors',
  },
  {
    type: 'link',
    label: 'Plate Details',
    link: 'code-plate-details',
    service: 'codeplatedetails',
  },
  {
    type: 'link',
    label: 'Plate Statuses',
    link: 'code-plate-statuses',
    service: 'codeplatestatuses',
  },
  {
    type: 'link',
    label: 'Plate Types',
    link: 'code-plate-types',
    service: 'codeplatetypes',
  },
  {
    type: 'link',
    label: 'Property Description',
    link: 'code-property-description',
    service: 'codepropertydescription',
  },
  {
    type: 'link',
    label: 'Resource Categories',
    link: 'code-resource-categories',
    service: 'coderesourcecategories',
  },
  {
    type: 'link',
    label: 'States',
    link: 'code-states',
    service: 'codestates',
  },
  {
    type: 'link',
    label: 'Street Directions',
    link: 'code-street-directions',
    service: 'codestreetdirections',
  },
  {
    type: 'link',
    label: 'Street Types',
    link: 'code-street-types',
    service: 'codestreettypes',
  },
  {
    type: 'link',
    label: 'Structure Colors',
    link: 'code-structure-colors',
    service: 'codestructurecolors',
  },
  {
    type: 'link',
    label: 'Subject Involvement',
    link: 'code-subject-involvement',
    service: 'codesubjectinvolvement',
  },
  {
    type: 'link',
    label: 'Subject MO',
    link: 'code-subject-mo',
    service: 'codesubjectmo',
  },
  {
    type: 'link',
    label: 'Subject Status',
    link: 'code-subject-status',
    service: 'codesubjectstatus',
  },
  {
    type: 'link',
    label: 'Subject Weapon',
    link: 'code-subject-weapon',
    service: 'codesubjectweapon',
  },
  {
    type: 'link',
    label: 'Transmissions',
    link: 'code-transmissions',
    service: 'codetransmissions',
  },
  {
    type: 'link',
    label: 'Vehicle Branders',
    link: 'code-vehicle-branders',
    service: 'codevehiclebranders',
  },
  {
    type: 'link',
    label: 'Vehicle Brands',
    link: 'code-vehiclebrands',
    service: 'codevehiclebrands',
  },
  {
    type: 'link',
    label: 'Vehicle Classifications',
    link: 'code-vehicle-classifications',
    service: 'codevehicleclassifications',
  },
  {
    type: 'link',
    label: 'Vehicle Colors',
    link: 'code-vehicle-colors',
    service: 'codevehiclecolors',
  },
  {
    type: 'link',
    label: 'Vehicle Makes',
    link: 'code-vehicle-makes',
    service: 'codevehiclemakes',
  },
  {
    type: 'link',
    label: 'Vehicle Property',
    link: 'code-vehicle-property',
    service: 'codevehicleproperty',
  },
  {
    type: 'link',
    label: 'Vehicle Styles',
    link: 'code-vehicle-styles',
    service: 'codevehiclestyles',
  },
  {
    type: 'link',
    label: 'Victim Disposition',
    link: 'code-victim-disposition',
    service: 'codevictimdisposition',
  },
  {
    type: 'link',
    label: 'Victim Treatment',
    link: 'code-victim-treatment',
    service: 'codevictimtreatment',
  },
  {
    type: 'link',
    label: 'Weather',
    link: 'code-weather',
    service: 'codeweather',
  },
  {
    type: 'link',
    label: 'Address Categories',
    link: 'code-address-categories',
    service: 'codeaddresscategories',
  },
  {
    type: 'link',
    label: 'Zones',
    link: 'code-zones',
    service: 'codezones',
  },
  {
    type: 'link',
    label: 'Cities',
    link: 'code-cities',
    service: 'codecities',
  },
  {
    type: 'link',
    label: 'Tag Types',
    link: 'code-Tag-Types',
    service: 'codetagtypes',
  },
  {
    type: 'link',
    label: 'Vehicle Make Models',
    link: 'make-models',
    service: 'code-vehicle-make-models',
  },
  { type: 'link', label: 'Dispositions', link: 'dispositions', service: 'code-dispositions' },
];

// const RMSMenus = [...GlobalCodes, ...rmsSubMenu];
// const CadMenus = [...GlobalCodes, ...CADSubMenu];
const rmsSubMenuSorted = rmsSubMenu.sort((a, b) => a.label.localeCompare(b.label));

const nibrsSubMenu = [
  {
    type: 'link',
    label: 'Arrest Categories',
    link: 'code-arrest-categories',
    service: 'codearrestcategories',
  },
  {
    type: 'link',
    label: 'Arrestee Armed With',
    link: 'code-arrestee-armed-with',
    service: 'codearresteearmed',
    table: 'codeArresteeArmed',
  },
  {
    type: 'link',
    label: 'Arrestee Juvenile Disposition',
    link: 'code-arrestee-juvenile-disposition',
    service: 'codejuveniledisposition',
  },
  {
    type: 'link',
    label: 'Arrestee Subject Count',
    link: 'code-arrestee-subject-count',
    service: 'codearresteesegment',
  },
  {
    type: 'link',
    label: 'Criminal Activity Category',
    link: 'code-criminal-activities-category',
    service: 'codecriminalactivities',
    table: 'codeCriminalActivities',
  },
  {
    type: 'link',
    label: 'Drug Category',
    link: 'code-drug-category',
    service: 'codesubstancecategory',
    table: 'codeSubstanceCategory',
  },
  {
    type: 'link',
    label: 'Enforcement Official Activity Category',
    link: 'code-enforcement-Official-activity-category',
    service: 'codeofficeractivity',
  },
  {
    type: 'link',
    label: 'Enforcement Official Assignment Category',
    link: 'code-enforcement-official-assignment-category',
    service: 'codeofficerassignment',
  },
  {
    type: 'link',
    label: 'Code Force Category',
    link: 'code-force-category',
    service: 'codeforcecategory',
    table: 'codeForceCategory',
  },
  {
    type: 'link',
    label: 'Incident Exceptional Clearance',
    link: 'code-incident-exceptional-clearance',
    service: 'codeibrexceptionalclearance',
    table: 'codeIBRExceptionalClearance',
  },
  {
    type: 'link',
    label: 'Code Injury Category',
    link: 'code-injury-category',
    service: 'codeinjurycategory',
    table: 'codeInjuryCategory',
  },
  {
    type: 'link',
    label: 'Item Category NIBRS Property Category',
    link: 'code-item-category-nibrs-property-category',
    service: 'codepropertycategory',
    table: 'codePropertyCategory',
  },
  {
    type: 'link',
    label: 'Item Status',
    link: 'code-item-status',
    service: 'codepropertylosstype',
    table: 'codePropertyLossType',
  },
  {
    type: 'link',
    label: 'Location Categories',
    link: 'code-location-categories',
    service: 'codelocationcategories',
    table: 'codeLocationCategories',
  },
  {
    type: 'link',
    label: 'Method Of Entry',
    link: 'code-method-of-entry',
    service: 'codemethodofentry',
  },
  {
    type: 'link',
    label: 'Offense Factor Bias Motivation Code',
    link: 'code-offense-factor-bias-motivation-code',
    service: 'codebiasmotivations',
    table: 'codeBiasMotivations',
  },
  {
    type: 'link',
    label: 'Code Offense Factor',
    link: 'code-offense-factor',
    service: 'codeoffensefactor',
    table: 'codeOffenseFactor',
  },
  {
    type: 'link',
    label: 'Person Ethnicity',
    link: 'code-person-ethnicity',
    service: 'codeethnicity',
  },
  {
    type: 'link',
    label: 'Code Person Race NDEX',
    link: 'code-person-race-ndex',
    service: 'coderace',
    table: 'codeRace',
  },
  {
    type: 'link',
    label: 'Person Resident',
    link: 'code-person-resident',
    service: 'coderesident',
  },
  {
    type: 'link',
    label: 'Code Person Sex',
    link: 'code-person-sex',
    service: 'codesex',
    table: 'codeSex',
  },
  {
    type: 'link',
    label: 'Substance Unit',
    link: 'code-substance-unit',
    service: 'codedrugmeasurements',
    table: 'codeDrugMeasurements',
  },
  {
    type: 'link',
    label: 'Code Victim Aggravated Assault Homicide Factor',
    link: 'code-victim-aggravated-assault-homicide-factor',
    service: 'codeaggassaulttype',
    table: 'codeAggAssaultType',
  },
  {
    type: 'link',
    label: 'Code Victim Category',
    link: 'code-victim-category',
    service: 'codevictimcategory',
    table: 'codeVictimCategory',
  },
  {
    type: 'link',
    label: 'Victim Justifiable Homicide Factor',
    link: 'code-victim-justifiable-homicide-factor',
    service: 'codejustifiablehomicide',
  },
  {
    type: 'link',
    label: 'Code Victim To Subject Relationship',
    link: 'code-victim-to-subject-relationship',
    service: 'codevictimsubjectrelationship',
    table: 'codeVictimSubjectRelationship',
  },
];

const nibrsSubMenuSorted = nibrsSubMenu.sort((a, b) => a.label.localeCompare(b.label));

const menu = [
  { type: 'menu', label: 'Attachment', submenu: attachmentsSubMenu },

  { type: 'divider' },
  { type: 'menu', label: 'Contact', submenu: contactSubMenu },
  { type: 'divider' },
  { type: 'menu', label: 'Credentials', submenu: credentialsSubMenu },
  { type: 'divider' },
  { type: 'menu', label: 'Employment', submenu: employmentSubMenu },
  { type: 'divider' },
  { type: 'menu', label: 'Feature', submenu: featureSubMenu },
  { type: 'divider' },
  { type: 'menu', label: 'Items', submenu: itemsSubMenu },
  { type: 'divider' },
  { type: 'menu', label: 'Location', submenu: locationSubMenu },
  { type: 'divider' },
  { type: 'menu', label: 'Measure', submenu: measureSubMenu },
  { type: 'divider' },
  { type: 'menu', label: 'Person', submenu: PersonSubmenu },
  { type: 'divider' },
  { type: 'menu', label: 'Vehicle', submenu: vehicleSubMenu },
  { type: 'divider' },
  { type: 'menu', label: 'Place', submenu: placeSubMenu },
  { type: 'divider' },
  { type: 'menu', label: 'Rotation', submenu: rotationSubMenu },
  { type: 'divider' },
  { type: 'menu', label: 'Zones', submenu: zonesSubMenu },
  { type: 'divider' },
  { type: 'menu', label: 'RMS', submenu: rmsSubMenuSorted },
  { type: 'divider' },
  { type: 'menu', label: 'NIBRS', submenu: nibrsSubMenuSorted },
];
export const rmsMenu = [
  { type: 'divider' },
  { type: 'menu', label: 'RMS', submenu: rmsSubMenuSorted },
];
export const cadMenu = [{ type: 'divider' }, { type: 'menu', label: 'CAD', submenu: CADSubMenu }];
export const NIBRSMenu = [
  { type: 'divider' },
  { type: 'menu', label: 'NIBRS', submenu: nibrsSubMenuSorted },
];
export default menu;
