import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Content from '../../Content';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  TextField,
  Button,
  Toolbar,
  IconButton,
  Tooltip,
  Typography,
  Grid,
  Box,
} from '@material-ui/core';
import { FormatBold, FormatItalic, FormatUnderlined, KeyboardReturn } from '@material-ui/icons';
import SaveIcon from '@material-ui/icons/Save';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getService } from 'reducers/service';
import {
  findEmailInDatabase,
  findMailTemplateData,
  saveCDRdata,
  updateCDRdata,
} from './emailTempFunctions';
import { notify } from 'reducers/NotifierReducer';
import { handleError } from 'reducers/ErrorReducer';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  editor: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  saveBtn: {
    textAlign: 'left',
    margin: 25,
  },
}));

function DefaultEmailTemplate(props) {
  const classes = useStyles();
  const [text, setText] = useState('');
  const [defaultApplication, setDefaultApplication] = useState(null);
  const [link, setLink] = useState('');

  const cdr = getService('cdr');

  useEffect(() => {
    async function fetchData() {
      const data = await findMailTemplateData(defaultApplication.name);

      setText(data.emailText);
      setLink(data.url);
    }
    if (defaultApplication) {
      fetchData();
    }
  }, [defaultApplication]);

  const TextEditor = () => {
    const handleFormat = style => {
      const selectionStart = document.getElementById('editor').selectionStart;
      const selectionEnd = document.getElementById('editor').selectionEnd;
      const selectedText = text.substring(selectionStart, selectionEnd);
      const beforeSelection = text.substring(0, selectionStart);
      const afterSelection = text.substring(selectionEnd);
      let formattedText = '';

      switch (style) {
        case 'bold':
          formattedText = `<b>${selectedText}</b>`;
          break;
        case 'italic':
          formattedText = `<i>${selectedText}</i>`;
          break;
        case 'underline':
          formattedText = `<u>${selectedText}</u>`;
          break;
        case 'newLine':
          formattedText = `${selectedText}<br/>`;
          break;
        default:
          formattedText = selectedText;
          break;
      }

      setText(beforeSelection + formattedText + afterSelection);
    };

    return (
      <Paper className={classes.root}>
        <div className={classes.editor}>
          <TextField
            id="editor"
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            label="Email Body"
            value={text}
            onChange={e => setText(e.target.value)}
          />
          <Toolbar>
            <Tooltip title="Bold">
              <IconButton onClick={() => handleFormat('bold')}>
                <FormatBold />
              </IconButton>
            </Tooltip>
            <Tooltip title="Italic">
              <IconButton onClick={() => handleFormat('italic')}>
                <FormatItalic />
              </IconButton>
            </Tooltip>
            <Tooltip title="Underline">
              <IconButton onClick={() => handleFormat('underline')}>
                <FormatUnderlined />
              </IconButton>
            </Tooltip>
            <Tooltip title="New Line">
              <IconButton onClick={() => handleFormat('newLine')}>
                <KeyboardReturn />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </div>
      </Paper>
    );
  };

  const renderApplication = () => {
    const applicationList = [
      { name: 'RMS', index: 1 },
      { name: 'CAD', index: 2 },
      { name: 'CAD-Mobile', index: 3 },
    ];

    return (
      <Box style={{ marginLeft: 10 }}>
        <Autocomplete
          style={{ padding: 5, paddingBottom: 20 }}
          options={applicationList}
          disablePortal
          getOptionLabel={option => option.name}
          renderInput={params => (
            <TextField {...params} label="Default Application" variant="outlined" />
          )}
          renderOption={option => `${option.name}`}
          size="small"
          value={defaultApplication}
          onChange={(e, value) => setDefaultApplication(value)}
        />
      </Box>
    );
  };

  const renderSiteLink = () => {
    return (
      <Box style={{ marginLeft: 10 }}>
        <TextField
          style={{ padding: 5, paddingBottom: 20 }}
          label="Application Log In URL*"
          variant="outlined"
          value={link}
          size="small"
          onChange={e => setLink(e.target.value)}
          fullWidth
        />
      </Box>
    );
  };

  const handleSaveClick = async () => {
    if (validateInput()) return;
    const dataexists = await findEmailInDatabase(defaultApplication.name);
    if (dataexists) {
      const data = await updateCDRdata(defaultApplication.name, text, link);
      if (data) props.notify('Updated.', 'success');
      else props.notify('Save failure.', 'error');
    } else {
      const data = await saveCDRdata(defaultApplication.name, text, link);
      if (data) props.notify('Saved.', 'success');
      else props.notify('Save failure.', 'error');
    }
  };

  const validateInput = () => {
    if (!defaultApplication) {
      props.notify('Default Application is required.', 'error');
      return true;
    }
    if (!link) {
      props.notify('Log In URl is required.', 'error');
      return true;
    }
    if (!text) {
      props.notify('Email Body can not be empty.', 'error');
      return true;
    }
    return false;
  };

  const renderSave = () => {
    return (
      <div className={classes.saveBtn}>
        <Button onClick={handleSaveClick} variant="contained" color="primary">
          <SaveIcon className="mr-2" /> Save
        </Button>
      </div>
    );
  };

  return (
    <Content>
      <div className="m-4">
        <h4 className="mb-2">Email Templates</h4>
        <Grid container item spacing={2} xs={12} style={{ marginTop: 10, marginBottom: 15 }}>
          <Grid item xs={6} style={{ width: '100%' }}>
            {renderApplication()}
          </Grid>
          <Grid item xs={6} style={{ width: '100%' }}>
            {renderSiteLink()}
          </Grid>
          <Grid item xs={12}>
            {TextEditor()}
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.root}>
              <h4 className="mb-2">Email Preview</h4>
              <div dangerouslySetInnerHTML={{ __html: text }} />
            </Paper>
          </Grid>
        </Grid>
      </div>
      {renderSave()}
    </Content>
  );
}

export default connect(null, { handleError, notify })(DefaultEmailTemplate);
