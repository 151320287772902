import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Content from '../Content';
import DispatchTooltips from './DispatchTooltips';
import TimeFormat from './TimeFormat';

const useStyles = makeStyles((theme) => ({
  tabs: {
    width: '100%',
    '& button': {
      '& > span': {
        textTransform: 'none',
      },
    },
  },
  settings: {
    height: '100%',
    overflowY: 'auto',
  },
}));

function Appearance() {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState('timeformat');

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Content>
      <div className={classes.settings}>
        <Tabs
          variant="scrollable"
          value={tabValue}
          onChange={handleTabChange}
          className={classes.tabs}
        >
          <Tab label="Time Format" value="timeformat" />
          <Tab label="Dispatch Tool Tips" value="tooltips" />
        </Tabs>
        <div role="tabpanel" className={classes.tabPanel}>
          <Box p={3}>
            {tabValue === 'timeformat' && <TimeFormat />}
            {tabValue === 'tooltips' && <DispatchTooltips />}
          </Box>
        </div>
      </div>
    </Content>
  );
}
export default Appearance;
