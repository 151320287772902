import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Content from '../Content';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import FormControl from '@material-ui/core/FormControl';
import {getService} from '../../reducers/service';
import { notify } from '../../reducers/NotifierReducer';

const useStyles = makeStyles((theme) => ({
  formControl: {
    maxWidth: "210px",
    marginLeft: 20
  },
}));

function VehiclesDuplicate(props) {
  const classes = useStyles();
  const service = getService('settings-duplicate-checking-vehicles');

  const [checkForDuplicates, setCheckForDuplicates] = useState(1);
  const [plate, setPlate] = useState(0);
  const [vin, setVin] = useState(0);


  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    service.find()
      .then(res => {
        //console.log(res)
        handleFetchedData(res);
      })
      .catch(err => console.log(err));
  }

  const handleSaveClick = async () => {
    try{
      await service.create({Path: 'PTS.Vehicles.DuplicateVehicle.CheckForDuplicates', ValueBoolean: checkForDuplicates});
      await service.create({Path: 'PTS.Vehicles.DuplicateVehicle.SearchParameters.Plate', ValueBoolean: plate});
      await service.create({Path: 'PTS.Vehicles.DuplicateVehicle.SearchParameters.VIN', ValueBoolean: vin});
      props.notify("Duplicate Settings Saved!", "success");
      // fetchData();
    }
    catch(err){
      console.log(err);
    }
  }

  const boolToBit = (value) => {
    return value === true ? 1 : 0;
  }

  const handleFetchedData = (data) => {
    data.forEach(d => {
      switch(d.Path) {
        case 'PTS.Vehicles.DuplicateVehicle.CheckForDuplicates':  
          setCheckForDuplicates(boolToBit(d.ValueBoolean));
          break;
        case 'PTS.Vehicles.DuplicateVehicle.SearchParameters.Plate':
          setPlate(boolToBit(d.ValueBoolean));
          break;
        case 'PTS.Vehicles.DuplicateVehicle.SearchParameters.VIN':
          setVin(boolToBit(d.ValueBoolean));
          break;
        default:
          console.log('no setState available');
          break;
      }
    })
  }

  const toggle = (num) => {
    return num === 0 ? 1 : 0;
  }

  return (
    <Content>
      <div className="ml-4 mt-4">
        <h4>Vehicles Duplicate Checking</h4>
        <div className="mt-4">
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkForDuplicates===1}
                  onChange={()=>setCheckForDuplicates(toggle(checkForDuplicates))}
                  name="Check"
                  color="primary"
                />
              }
              label="Check for Duplicates"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={plate===1}
                  onChange={()=>setPlate(toggle(plate))}
                  name="Plate"
                  color="primary"
                  disabled={checkForDuplicates===0}
                />
              }
              label="Plate Number and Plate State"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={vin===1}
                  onChange={()=>setVin(toggle(vin))}
                  name="VIN"
                  color="primary"
                  disabled={checkForDuplicates===0}
                />
              }
              label="VIN"
            />
          </div>
          <div>
            <Button 
              onClick={handleSaveClick}
              variant="contained" 
              className="mt-4 mb-4"
              color="primary">
              <SaveIcon /> Save 
            </Button> 
          </div>
        </div>
      </div>
    </Content>
  );
}

export default connect(null, { 
  notify
})(VehiclesDuplicate);