import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Content from '../Content';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import {getService} from '../../reducers/service';
import { notify } from '../../reducers/NotifierReducer';

const useStyles = makeStyles((theme) => ({
  formControl: {
    maxWidth: "210px",
    marginLeft: 20
  },
}));

function EventsDuplicate(props) {
  const classes = useStyles();
  const service = getService('settings-duplicate-checking-events');

  const [checkForDuplicates, setCheckForDuplicates] = useState(1);
  const [coordinates, setCoordinates] = useState(0);
  const [searchRadius, setSearchRadius] = useState(1);
  const [searchRadiusCheck, setsearchRadiusCheck] = useState(0);
  const [searchRadiusUnit, setSearchRadiusUnit] = useState('Feet');
  const [searchTime, setSearchTime] = useState(1);
  const [searchTimeUnit, setSearchTimeUnit] = useState('Days');
  const [streetRange, setStreetRange] = useState(1);
  const [streetRangeCheck, setStreetRangeCheck] = useState(0);
  const [callType, setCallType] = useState(0);
  const [recentlyClosed, setRecentlyClosed] = useState(0);
  const [address, setAddress] = useState(0);


  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    service.find()
      .then(res => {
        //console.log(res)
        handleFetchedData(res);
      })
      .catch(err => console.log(err));
  }

  const handleSaveClick = async () => {
    try{
      await service.create({Path: 'CAD.Events.DuplicateEvent.CheckForDuplicates', ValueNumeric: null, 
        ValueString: null, ValueBoolean: checkForDuplicates});
      await service.create({Path: 'CAD.Events.DuplicateEvent.Coordinates', ValueNumeric: null, 
        ValueString: null, ValueBoolean: coordinates});
      await service.create({Path: 'CAD.Events.DuplicateEvent.SearchRadius', ValueNumeric: searchRadius, 
        ValueString: null, ValueBoolean: searchRadiusCheck});
      await service.create({Path: 'CAD.Events.DuplicateEvent.SearchRadiusUnit', ValueNumeric: null, 
        ValueString: searchRadiusUnit, ValueBoolean: null});
      await service.create({Path: 'CAD.Events.DuplicateEvent.SearchTime', ValueNumeric: searchTime, 
        ValueString: null, ValueBoolean: null});
      await service.create({Path: 'CAD.Events.DuplicateEvent.SearchTimeUnit', ValueNumeric: null, 
        ValueString: searchTimeUnit, ValueBoolean: null});
      await service.create({Path: 'CAD.Events.DuplicateEvent.StreetRange', ValueNumeric: streetRange, 
        ValueString: null, ValueBoolean: streetRangeCheck});
      await service.create({Path: 'CAD.Events.DuplicateEvent.Address', ValueNumeric: null, 
        ValueString: null, ValueBoolean: address});
      await service.create({Path: 'CAD.Events.DuplicateEvent.CallType', ValueNumeric: null, 
        ValueString: null, ValueBoolean: callType});
      await service.create({Path: 'CAD.Events.DuplicateEvent.RecentlyClosed', ValueNumeric: null, 
        ValueString: null, ValueBoolean: recentlyClosed});
      props.notify("Duplicate Settings Saved!", "success");
      // fetchData();
    }
    catch(err){
      console.log(err);
    }
  }

  const boolToBit = (value) => {
    return value === true ? 1 : 0;
  }

  const handleFetchedData = (data) => {
    data.forEach(d => {
      switch(d.Path) {
        case 'CAD.Events.DuplicateEvent.CheckForDuplicates':  
          setCheckForDuplicates(boolToBit(d.ValueBoolean));
          break;
        case 'CAD.Events.DuplicateEvent.Coordinates':
          setCoordinates(boolToBit(d.ValueBoolean));
          break;
        case 'CAD.Events.DuplicateEvent.SearchRadius':
          setSearchRadius(d.ValueNumeric);
          setsearchRadiusCheck(boolToBit(d.ValueBoolean))
          break;
        case 'CAD.Events.DuplicateEvent.SearchRadiusUnit':
          setSearchRadiusUnit(d.ValueString);
          break;
        case 'CAD.Events.DuplicateEvent.SearchTime':
          setSearchTime(d.ValueNumeric);
          break;
        case 'CAD.Events.DuplicateEvent.SearchTimeUnit':
          setSearchTimeUnit(d.ValueString);
          break;
        case 'CAD.Events.DuplicateEvent.StreetRange':
          setStreetRange(d.ValueNumeric);
          setStreetRangeCheck(boolToBit(d.ValueBoolean));
          break;
        case 'CAD.Events.DuplicateEvent.CallType':
          setCallType(boolToBit(d.ValueBoolean));
          break;
        case 'CAD.Events.DuplicateEvent.Address':
          setAddress(boolToBit(d.ValueBoolean));
          break;
        case 'CAD.Events.DuplicateEvent.RecentlyClosed':
          setRecentlyClosed(boolToBit(d.ValueBoolean));
          break;
        default:
          console.log('no setState available');
          break;
      }
    })
  }

  const toggle = (num) => {
    return num === 0 ? 1 : 0;
  }

  const disableSave = () => {
    return !searchRadius || !streetRange || !searchTime;
  }

  return (
    <Content>
      <div className="ml-4 mt-4">
        <h4>Events Duplicate Checking</h4>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkForDuplicates===1}
                onChange={()=>setCheckForDuplicates(toggle(checkForDuplicates))}
                name="Check"
                color="primary"
              />
            }
            label="Check for Duplicates"
          />
        </div>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={callType===1}
                onChange={()=>setCallType(toggle(callType))}
                name="EventType"
                color="primary"
                disabled={checkForDuplicates===0}
              />
            }
            label="Event Type"
          />
        </div>
        <div>
          <Typography variant="body1" gutterBottom> 
            <strong>Location: </strong>
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={address===1}
                onChange={()=>setAddress(toggle(address))}
                name="StreetAddress"
                color="primary"
                disabled={checkForDuplicates===0}
              />
            }
            label="Street Address"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={coordinates===1}
                onChange={()=>setCoordinates(toggle(coordinates))}
                name="Coordinates"
                color="primary"
                disabled={checkForDuplicates===0}
              />
            }
            label="Coordinates"
          />
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={searchRadiusCheck===1}
                  onChange={()=>setsearchRadiusCheck(toggle(searchRadiusCheck))}
                  name="SearchRadius"
                  color="primary"
                  disabled={checkForDuplicates===0}
                />
              }
              label="Search Radius"
            />
          </div>
          <div>
            <TextField
              id="outlined-number"
              error={!searchRadius}
              helperText={!searchRadius ? 'can not be empty' : ''}
              label="Number"
              type="number"
              value={searchRadius}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              onChange={e => setSearchRadius(e.target.value)}
              size="small"
              disabled={checkForDuplicates===0 || searchRadiusCheck===0}
            />
            <FormControl variant="outlined" size="small" fullWidth={true} className={classes.formControl}>
              <Select
                value={searchRadiusUnit}
                onChange={e => setSearchRadiusUnit(e.target.value)}
                size="small"
                inputProps={{ 'aria-label': 'Without label' }}
                disabled={checkForDuplicates===0 || searchRadiusCheck===0}
              >
                <MenuItem value='Feet'>Feet</MenuItem>
                <MenuItem value='Miles'>Miles</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={streetRangeCheck===1}
                  onChange={()=>setStreetRangeCheck(toggle(streetRangeCheck))}
                  name="AddressesWithin"
                  color="primary"
                  disabled={checkForDuplicates===0}
                />
              }
              label="Addresses Within"
            />
          </div>
          <div>
            <Grid container alignItems='center'>
              <Grid item>
                <TextField
                  id="outlined-number"
                  error={!streetRange}
                  helperText={!streetRange ? 'can not be empty' : ''}
                  label="Number"
                  type="number"
                  value={streetRange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  onChange={e => setStreetRange(e.target.value)}
                  size="small"
                  disabled={checkForDuplicates===0 || streetRangeCheck===0}
                />
              </Grid>
              <Grid item className="ml-3">
                <Typography variant="body1" gutterBottom>
                  Street Numbers
                </Typography>
              </Grid>
            </Grid>
          </div>
        </div>  
        <div className="mt-4">
          <div>
            <Typography variant="body1" gutterBottom> 
              <strong>Display: </strong>
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={recentlyClosed===1}
                  onChange={()=>setRecentlyClosed(toggle(recentlyClosed))}
                  name="RecentlyClosedEvents"
                  color="primary"
                  disabled={checkForDuplicates===0}
                />
              }
              label="Recently Closed Events"
            />
          </div>
          <Typography variant="body1" gutterBottom> 
            Closed in the last 
          </Typography>
          <div>
            <TextField
              id="outlined-number"
              error={!searchTime}
              helperText={!searchTime ? 'can not be empty' : ''}
              label="Number"
              type="number"
              value={searchTime}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              onChange={e => setSearchTime(e.target.value)}
              size="small"
              disabled={checkForDuplicates===0 || recentlyClosed===0}
            />
            <FormControl variant="outlined" size="small" fullWidth={true} className={classes.formControl}>
              <Select
                value={searchTimeUnit}
                onChange={e => setSearchTimeUnit(e.target.value)}
                size="small"
                inputProps={{ 'aria-label': 'Without label' }}
                disabled={checkForDuplicates===0 || recentlyClosed===0}
              >
                <MenuItem value='Minutes'>Minutes</MenuItem>
                <MenuItem value='Hours'>Hours</MenuItem>
                <MenuItem value='Days'>Days</MenuItem>
              </Select>
            </FormControl>
          </div>
          <Button 
            onClick={handleSaveClick} 
            disabled={disableSave()}
            variant="contained" 
            className="mt-4 mb-4"
            color="primary">
            <SaveIcon /> Save 
          </Button> 
        </div>
      </div>
    </Content>
  );
}

export default connect(null, { 
  notify
})(EventsDuplicate);