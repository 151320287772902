import React, { useEffect, useState } from 'react';
import SettingsPage from './../Content';
import {
  Grid,
  Paper,
  Typography,
  Divider,
  Box,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import { getService } from './../../reducers/service';
export default function Appearance() {
  const cdr = getService('cdr');
  const [is24Hours, setIs24Hours] = useState(false);

  const handleChangingTime = event => {
    //TODO:need to implement
    setIs24Hours(event.target.checked);
  };
  const handleSaveClick = async () => {
    const CDRKey = '24hour';
    const Path = 'Application.TimeFormat';
    try {
      await cdr.patch(null, {
        ValueBoolean: is24Hours,
        Path,
        CDRKey,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const getTimeSetting = async () => {
    try {
      const timeSetting = await cdr.find({ query: { Path: 'Application.TimeFormat' } });
      if (timeSetting.length) setIs24Hours(timeSetting[0].ValueBoolean);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getTimeSetting();
  }, []);
  const renderSaveBtn = () => {
    return (
      <Button onClick={handleSaveClick} variant="contained" className="mt-2 ml-2" color="primary">
        <SaveIcon className="mr-2" /> Save
      </Button>
    );
  };
  return (
    <>
      <Grid container style={{ margin: 10 }}>
        <Paper style={{ width: 150, height: 150 }}>
          <Grid item style={{ justifyContent: 'center' }}>
            <Typography
              variant="subtitle2"
              style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
              Time Setting
            </Typography>
          </Grid>

          <Divider />
          <Grid
            item
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
            }}>
            <Box style={{ marginLeft: 10 }}>
              <FormControlLabel
                control={<Checkbox checked={is24Hours} onChange={handleChangingTime} />}
                label="24Hour"
              />
              {renderSaveBtn()}
            </Box>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
}
