import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { XGrid } from '@material-ui/x-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import { handleError } from '../reducers/ErrorReducer';
import { showSpinner, hideSpinner } from '../reducers/UiReducer';
import { getSettings } from '../reducers/ConfigReducer';
import Content from './Content';
import { getService } from '../reducers/service';
import Grid from '@mui/material/Grid';
import Menu from '@material-ui/core/Menu';
import { formatDate, sortObjArr } from 'utils/functions';
import QuickFilter from 'components/QuickFilter';
import CheckIcon from '@material-ui/icons/Check';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from 'components/Tooltip';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { showAddSecUser } from 'reducers/DialogsReducer';


const useStyles = makeStyles(theme => ({
  wrap: {
    padding: theme.spacing(2),
    '& > h5': {
      marginBottom: 20,
    },
  },
  edit: {
    padding: theme.spacing(2, 0),
    paddingBottom: 12,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },
  form: {
    margin: '0 -4px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'start',
    flexGrow: 1,
  },
  buttons: {
    '& button': {
      marginLeft: theme.spacing(1),
    },
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  textField: {
    margin: '0 4px 8px',
  },
  list: {
    width: '100%',
    height: 500,
  },
  actions: {
    width: '100%',
    padding: theme.spacing(1, 0, 2),
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const userColumns = [
  { field: 'Username', headerName: 'User Name', width: 150 },
  { field: 'ApplicationName', headerName: 'Application Name', width: 150 },
  { field: 'ActivityDateTime', 
    headerName: 'Activity DateTime', 
    width: 150,
    valueFormatter: (params) => formatDate(params.value),
    format: "date" 
  },
  { field: 'Activity', headerName: 'Activity', width: 150 },
  { field: 'PasswordEmail', headerName: 'Email', width: 150 },
  { field: 'IsLockedOut', 
    headerName: 'Locked Out', 
    width: 150,
    format: "bool",
    renderCell: bool 
  },
  { field: 'UserIP', headerName: 'User IP', width: 150 },
  { field: 'NetName', headerName: 'Net Name', width: 150 },
  { field: 'UserMachineName', headerName: 'User Machine Name', width: 150 },
];

function SecUsers(props) {
  const classes = useStyles();
  const service = getService('settings-sec-users');
  const { dictionary } = props;
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    fetchSecUserData();
    // eslint-disable-next-line
  }, []);

  const fetchSecUserData = async () => {
    setLoaded(false);
    setSelection(null);

    try {
      const users = await service.find();
      const sorted = sortObjArr(users, 'Username');
      setRows(processData(sorted));
    } catch(err) {
      props.handleError(err, 'Error, cannot get user data.');
    }

    setLoaded(true);
  }

  const processData = (data) => {
    return data.map(row => {
      return {
        ...row,
        id: row.ptsUserID,
      };
    });
  };

  const addSecUser = () => { props.showAddSecUser() };

  const editSecUser = () => {};

  return (
    <Content>
      <div className={classes.wrap}>
        <h4> Security Users </h4>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className={classes.filterWrap}>
            <div className={classes.filterWrap}>
              <QuickFilter
                rows={rows}
                columns={userColumns}
                setFilteredRows={setFilteredRows}
              />
            </div>
            <div className={classes.actions}>
              <Fab onClick={addSecUser} size="small" color="secondary">
                <AddIcon />
              </Fab>
              {selection !== null && (
                <Tooltip title="Edit User">
                  <Fab className="ml-2" size="small" color="secondary" onClick={editSecUser}>
                    <EditIcon />
                  </Fab>
                </Tooltip>
              )}
            </div>
          </div>
        </MuiPickersUtilsProvider>
        <div className={classes.gridWrap}>
          <XGrid
            columns={userColumns}
            rows={filteredRows}
            loading={!loaded}
            rowHeight={38}
            disableMultipleSelection={true}
            showToolbar
            disableColumnFilter
            onSelectionModelChange={(newSelection) => {
              setSelection(newSelection.selectionModel[0]);
            }}
          />
        </div>
      </div>
    </Content>
  );
}

const mapStateToProps = state => {
  return {
    dictionary: state.dictionary
  }
}

export default connect(mapStateToProps, {
  handleError, showSpinner, hideSpinner, getSettings, showAddSecUser
})(SecUsers);
