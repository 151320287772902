import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Content from '../Content';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import {getService} from '../../reducers/service';
import { notify } from '../../reducers/NotifierReducer';

const useStyles = makeStyles((theme) => ({}));

function PlacesDuplicate(props) {
  const classes = useStyles();
  const service = getService('settings-duplicate-checking-places');

  const [checkForDuplicates, setCheckForDuplicates] = useState(1);
  const [placeName, setPlaceName] = useState(0);


  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    service.find()
      .then(res => {
        console.log(res)
        handleFetchedData(res);
      })
      .catch(err => console.log(err));
  }

  const handleSaveClick = async () => {
    try{
      await service.create({Path: 'PTS.Places.DuplicatePlace.CheckForDuplicates', ValueBoolean: checkForDuplicates});
      await service.create({Path: 'PTS.Places.DuplicatePlace.SearchParameters.PlaceName', ValueBoolean: placeName});
      props.notify("Duplicate Settings Saved!", "success");
      // fetchData();
    }
    catch(err){
      console.log(err);
    }
  }

  const boolToBit = (value) => {
    return value === true ? 1 : 0;
  }

  const handleFetchedData = (data) => {
    data.forEach(d => {
      switch(d.Path) {
        case 'PTS.Places.DuplicatePlace.CheckForDuplicates':  
          setCheckForDuplicates(boolToBit(d.ValueBoolean));
          break;
        case 'PTS.Places.DuplicatePlace.SearchParameters.PlaceName':
          setPlaceName(boolToBit(d.ValueBoolean));
          break;
        default:
          console.log('no setState available');
          break;
      }
    })
  }

  const toggle = (num) => {
    return num === 0 ? 1 : 0;
  }

  return (
    <Content>
      <div className="ml-4 mt-4">
        <h4>Places Duplicate Checking</h4>
        <div className="mt-4">
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkForDuplicates===1}
                  onChange={()=>setCheckForDuplicates(toggle(checkForDuplicates))}
                  name="Check"
                  color="primary"
                />
              }
              label="Check for Duplicates"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={placeName===1}
                  onChange={()=>setPlaceName(toggle(placeName))}
                  name="place"
                  color="primary"
                  disabled={checkForDuplicates===0}
                />
              }
              label="Place Name"
            />
          </div>
          <div>
            <Button 
              onClick={handleSaveClick}
              variant="contained" 
              className="mt-4 mb-4"
              color="primary">
              <SaveIcon /> Save 
            </Button> 
          </div>
        </div>
      </div>
    </Content>
  );
}

export default connect(null, { 
  notify
})(PlacesDuplicate);