import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Content from '../../Settings/Content';
import { getService } from '../../reducers/service';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { notify } from '../../reducers/NotifierReducer';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CustomAgencyIdSequenceOrder from 'global-components/CustomIDGenerator';
import ReOrderedSequence from './ReOrderedSequence';
import { getExampleID } from './customIDHelper';
import Autocomplete2 from 'components/Autocomplete2';
import { gridStyle } from 'utils/formStyles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& h4': {
      marginBottom: theme.spacing(2),
    },
  },
  form: {
    margin: '0 -4px 4px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexGrow: 1,
  },
  formControl: {
    minWidth: '210px',
  },
  selectEmpty: {
    height: '40px',
  },
  item: {
    ...gridStyle(200, 300),
    margin: 0,
  },
}));

function CustomIDGenerator(props) {
  const classes = useStyles();
  const service = getService('pts-custom-agency-id');

  const { initialData, dataForNewItem } = props;
  const { Agencies = [] } = props.dictionary || {};

  // states
  const [exId, setExId] = useState('');
  const [getRow, setGetRow] = useState(null);
  const [dbId, setDbId] = useState({ ...initialData });
  const [createNew, setCreateNew] = useState('');
  const [currentOrder, setCurrentOrder] = useState(0);
  // fetch current row from db
  useEffect(() => {
    service
      .get(dbId)
      .then(res => setGetRow(res[0]))
      .catch(err => console.log(err));
  }, [dbId]);

  // build example ID
  useEffect(() => {
    let ex = '';
    if (getRow) {
      ex = getExampleID(getRow);
    }
    setExId(ex);
  });

  // getRow rendered
  useEffect(() => {
    if (getRow === undefined) {
      setCreateNew('Create A New Entry Below');
      setGetRow({
        ...dataForNewItem,
        AgencyID: `${dbId.AgencyID}`,
      });
    } else if (getRow && getRow.Created) setCreateNew('');
  }, [getRow]);

  // for whenever prefix checkbox clicked
  // calling setState multiple times, take a look -> https://github.com/facebook/react/issues/16858

  const handleResetChange = event => {
    const targetValue = event.target.value;
    if (targetValue === '') setGetRow({ ...getRow, SequenceResetType: 'N' });
    else setGetRow({ ...getRow, SequenceResetType: targetValue });
  };

  const handlePrefixCheckboxChange = () => {
    if (getRow.PrefixOrder === 0) setGetRow({ ...getRow, PrefixOrder: 1 });
    else setGetRow({ ...getRow, PrefixOrder: 0 });
  };

  const handleMonthCheckboxChange = () => {
    if (getRow.MonthOrder !== 0) setGetRow({ ...getRow, MonthOrder: 0 });
    else {
      setGetRow({ ...getRow, MonthOrder: currentOrder + 1 });
    }
  };

  const handleDayCheckboxChange = () => {
    if (getRow.DayOrder !== 0) setGetRow({ ...getRow, DayOrder: 0 });
    else {
      setGetRow({ ...getRow, DayOrder: currentOrder + 1 });
    }
  };

  const handleYearCheckboxChange = () => {
    if (getRow.YearOrder !== 0) setGetRow({ ...getRow, YearOrder: 0 });
    else {
      setGetRow({ ...getRow, YearOrder: currentOrder + 1 });
    }
  };

  const handleSuffixCheckboxChange = () => {
    if (getRow.SuffixOrder !== 0) setGetRow({ ...getRow, SuffixOrder: 0 });
    else {
      setGetRow({ ...getRow, SuffixOrder: currentOrder + 1 });
    }
  };

  const handleUse4digitsCheckboxChange = () => {
    if (getRow.UseFourDigitYear) setGetRow({ ...getRow, UseFourDigitYear: false });
    else setGetRow({ ...getRow, UseFourDigitYear: true });
  };

  const handleUseSeparatorChange = () => {
    if (getRow.UseSeparator) setGetRow({ ...getRow, UseSeparator: false });
    else setGetRow({ ...getRow, UseSeparator: true });
  };

  const handleSaveClick = e => {
    // create new
    if (!getRow.Created) {
      service
        .create(getRow)
        .then(res => props.notify('Custom ID Saved!', 'success'))
        .catch(err => props.notify('Save Failed!', 'error'));
    }

    // exists already
    else {
      let {
        RecordID,
        Application,
        AgencyID,
        Created,
        CreatedBy,
        Deleted,
        DeletedBy,
        DeletedIP,
        EntityName,
        Flags,
        IsDeleted,
        LastUsed,
        ROWGUID,
        Updated,
        UpdatedBy,
        UpdatedIP,
        ...rest
      } = getRow;

      if (getRow.Separator === '') getRow.Separator = null;
      service
        .patch(dbId, rest)
        .then(res => props.notify('Custom ID Saved!', 'success'))
        .catch(err => props.notify('Save Failed!', 'error'));
    }
  };

  const prefixError1 = getRow?.Prefix?.length > 9;
  const prefixError2 = getRow?.PrefixOrder === 1 && !getRow?.Prefix;
  const sequenceLengthError = getRow?.SequenceLength > 100 || getRow?.SequenceLength < 4;
  const suffixError1 = getRow?.Suffix?.length > 9;
  const suffixError2 = getRow?.SuffixOrder !== 0 && !getRow?.Suffix;
  const separatorError1 = getRow?.Separator?.length > 1;
  const separatorError2 = getRow?.UseSeparator === true && !getRow?.Separator;
  const sequencerError = getRow?.Sequencer > 9223372036854775807;

  const disableSave = () => {
    return (
      prefixError1 ||
      prefixError2 ||
      sequenceLengthError ||
      suffixError1 ||
      suffixError2 ||
      separatorError1 ||
      separatorError2 ||
      sequencerError
    );
  };

  const prefixHelperText = () => {
    if (prefixError1) return 'Prefix can not be more than 9 letters!';
    if (prefixError2) return 'Prefix can not be empty!';
    return '';
  };

  const suffixHelperText = () => {
    if (suffixError1) return 'Suffix can not be more than 9 letters!';
    if (suffixError2) return 'Suffix can not be empty!';
    return '';
  };

  const separatorHelperText = () => {
    if (separatorError1) return 'Single Character only!';
    if (separatorError2) return 'Separator can not be empty!';
    return '';
  };

  const handleAgencyChange = (event, value, reason) => {
    if (value === null) value = { AgencyID: '' };

    setDbId({ ...dbId, AgencyID: value.AgencyID });
  };

  return (
    <Content>
      <div className="ml-4 mt-4">
        {props.showAgency && (
          <>
            <h4> Agency </h4>
            <Autocomplete2
              options={Agencies}
              getOptionLabel={option => option.AgencyID}
              onChange={(event, value, reason) => handleAgencyChange(event, value, reason)}
              className={classes.item}
              label="Agency"
              renderInput={params => <TextField {...params} label="Agency" variant="outlined" />}
              renderOption={option =>
                `${option.AgencyID} ${option.Description ? '- ' + option.Description : ''}`
              }
            />
          </>
        )}

        <div className="mb-4">
          <Typography variant="h6" color="primary">
            {createNew}
          </Typography>
        </div>

        <h4 className="mb-4">Custom ID For {initialData.EntityName || ''}</h4>
        {getRow && (
          <ReOrderedSequence row={getRow} setGetRow={setGetRow} setCurrentOrder={setCurrentOrder} />
        )}
        <Typography variant="body1" gutterBottom className="mt-4 mb-4">
          <span className="mr-5">Example Identifier:</span>
          <span className="ml-4">{exId}</span>
        </Typography>
        <Grid container>
          <Grid item md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={getRow?.PrefixOrder !== 0}
                  onChange={handlePrefixCheckboxChange}
                  name="prefix"
                  color="primary"
                />
              }
              label="Prefix"
            />
          </Grid>

          <Grid item>
            <TextField
              error={prefixError1 || prefixError2}
              helperText={prefixHelperText()}
              label="Text"
              variant="outlined"
              value={getRow ? getRow.Prefix : ''}
              required={getRow?.PrefixOrder === 1}
              disabled={getRow?.PrefixOrder === 0}
              onChange={e => setGetRow({ ...getRow, Prefix: e.target.value })}
              size="small"
            />
          </Grid>
        </Grid>

        <Grid container className="mt-2">
          <Grid item md={2}>
            <Typography variant="body1" gutterBottom>
              Sequencer Length
            </Typography>
          </Grid>
          <Grid item md={3}>
            <TextField
              error={sequenceLengthError}
              helperText={sequenceLengthError ? 'Sequence should be between 4 and 100' : ''}
              label="Number"
              type="number"
              value={getRow ? getRow.SequenceLength : 5}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              onChange={e => setGetRow({ ...getRow, SequenceLength: e.target.value })}
              size="small"
            />
          </Grid>
        </Grid>

        <Grid container className="mt-3 mb-3">
          <Grid item md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={getRow?.MonthOrder !== 0}
                  onChange={handleMonthCheckboxChange}
                  name="month"
                  color="primary"
                />
              }
              label="Month"
            />
          </Grid>

          <Grid item md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={getRow?.DayOrder !== 0}
                  onChange={handleDayCheckboxChange}
                  name="day"
                  color="primary"
                />
              }
              label="Day"
            />
          </Grid>

          <Grid item md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={getRow?.YearOrder !== 0}
                  onChange={handleYearCheckboxChange}
                  name="year"
                  color="primary"
                />
              }
              label="Year"
            />

            <span className="mr-5"></span>

            <FormControlLabel
              control={
                <Checkbox
                  checked={getRow?.UseFourDigitYear === true}
                  onChange={handleUse4digitsCheckboxChange}
                  name="use4digits"
                  color="primary"
                  disabled={getRow?.YearOrder === 0}
                />
              }
              label="Use 4 digits"
            />
          </Grid>

          <Grid container item md={12}>
            <Grid item md={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={getRow?.SuffixOrder !== 0}
                    onChange={handleSuffixCheckboxChange}
                    name="suffix"
                    color="primary"
                  />
                }
                label="Suffix"
              />
            </Grid>
            <Grid item>
              <TextField
                error={suffixError1 || suffixError2}
                helperText={suffixHelperText()}
                label="Text"
                value={getRow && getRow.Suffix ? getRow.Suffix : ''}
                variant="outlined"
                required={getRow?.SuffixOrder !== 0}
                disabled={getRow?.SuffixOrder === 0}
                size="small"
                onChange={e => setGetRow({ ...getRow, Suffix: e.target.value })}
              />
            </Grid>
          </Grid>

          <Grid container item md={12}>
            <Grid item md={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={getRow?.UseSeparator === true}
                    onChange={handleUseSeparatorChange}
                    name="includeSeparator"
                    color="primary"
                  />
                }
                label="Include Separator"
              />
            </Grid>
            <Grid item>
              <TextField
                error={separatorError1 || separatorError2}
                helperText={separatorHelperText()}
                label="Character"
                onChange={e => setGetRow({ ...getRow, Separator: e.target.value })}
                variant="outlined"
                value={getRow && getRow.Separator ? getRow.Separator : ''}
                required={getRow?.UseSeparator === true}
                disabled={getRow?.UseSeparator === false}
                size="small"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="mt-3 mb-3">
          <Grid item md={2}>
            <Typography variant="body1" gutterBottom>
              Reset Interval
            </Typography>
          </Grid>
          <Grid item>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth={true}
              className={classes.formControl}>
              <Select
                value={getRow ? getRow.SequenceResetType : ''}
                onChange={handleResetChange}
                size="small"
                className={classes.selectEmpty}
                inputProps={{ 'aria-label': 'Without label' }}>
                <MenuItem value="N">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="D">Day</MenuItem>
                <MenuItem value="M">Month</MenuItem>
                <MenuItem value="Y">Year</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container className="mt-3 mb-3">
          <Grid item md={2}>
            <Typography variant="body1" gutterBottom>
              Last Used Sequencer
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              error={sequencerError}
              helperText={sequencerError ? 'Should not put more than 18 digits!' : ''}
              label="Number"
              value={getRow && getRow.Sequencer ? getRow.Sequencer : ''}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              onChange={e => setGetRow({ ...getRow, Sequencer: e.target.value })}
              size="small"
            />
          </Grid>
        </Grid>

        <div>
          <Button
            onClick={handleSaveClick}
            disabled={disableSave()}
            variant="contained"
            className="mb-4"
            color="primary">
            <SaveIcon /> Save
          </Button>
        </div>
      </div>
    </Content>
  );
}
const mapStateToProps = state => {
  return {
    dictionary: state.dictionary,
  };
};
//export default CustomCitation;
export default connect(mapStateToProps, {
  notify,
})(CustomIDGenerator);
