import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import QuickFilterSearch from 'components/QuickFilterSearch';
import XGrid3 from 'components/XGrid3';
import Tooltip from 'components/Tooltip';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { getPtsPlace, getAgency } from 'Settings/Agencies/AgencyFunctions';
import { showPlaceEmployment } from 'reducers/DialogsReducer';
import { deletePlaceEmployment } from 'reducers/PlacesReducer';

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: theme.spacing(3),
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  fileInput: {
    display: 'none',
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: 8,
  },
}));

const defaultColumns = [
  { field: 'FullName', headerName: 'FullName' },
  { field: 'FirstName', headerName: 'FirstName' },
  { field: 'LastName', headerName: 'LastName' },
  { field: 'Position', headerName: 'Position' },
  { field: 'Rank', headerName: 'Rank' },
  { field: 'ptsSupervisorID', headerName: 'SupervisorID' },
  {
    field: 'Hired',
    headerName: 'Hired',
    width: 120,
    format: 'date',
  },
  {
    field: 'Terminated',
    headerName: 'Terminated',
    width: 120,
    format: 'date',
    hide: false,
  },
  {
    field: 'Created',
    headerName: 'Created',
    width: 120,
    format: 'date',
    hide: false,
  },
  { field: 'CreatedBy', headerName: 'Created By'},
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 120,
    format: 'date',
    hide: false,
  },
  { field: 'UpdatedBy', headerName: 'Updated By'},
];

function AgencyEmployees(props) {
  const classes = useStyles();
  const { agencyId, dataUpdate } = props;
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [columns, setColumns] = useState(defaultColumns);
  const [selection, setSelection] = useState(null);
  const [ptsPlaceID, setPtsPlaceID] = useState(null);


  useEffect(() => {
    search();
  }, []);

  useEffect(() => {
    if (dataUpdate && dataUpdate.type === "agency-employees") search();
  }, [dataUpdate])

  const search = async () => {
    const curAgency = await getAgency(agencyId);
    const { ptsPlaceID } = curAgency;
    setPtsPlaceID(ptsPlaceID);
    const curPlace = await getPtsPlace(ptsPlaceID);
    setRows(processData(curPlace.Employement));
  }

  const processData = (data) => {
    return data.map(emp => {
      const { FirstName, FullName, LastName } = emp.PersonInfo;
      emp.id = emp.ptsPersonID;
      emp.FirstName = FirstName;
      emp.FullName = FullName;
      emp.LastName = LastName;
      return emp;
    });
  }

  const add = () => props.showPlaceEmployment({ ptsPlaceID });

  const edit = () => {
    const item = rows.find(row => row.id == selection);
    props.showPlaceEmployment(item);
  }

  const deleteEmployee = () => {
    if (!window.confirm('Are you sure you want to remove this employee?')) return;
    const item = rows.find(row => row.id == selection);
    deletePlaceEmployment({ ptsPlaceID: item.ptsPlaceID, ptsPersonID: item.ptsPersonID });
    search();
  }

  return (
    <div className={classes.wrap}>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickFilterSearch rows={rows} columns={columns} setFilteredRows={setFilteredRows} />
        </div>
        <div className={classes.actions}>
          <Tooltip title="Add">
            <Fab size="small" color="secondary" onClick={add}>
              <AddIcon />
            </Fab>
          </Tooltip>

          {selection !== null && (
            <>
              <Tooltip title="Edit">
                <Fab size="small" color="secondary" onClick={edit}>
                  <EditIcon />
                </Fab>
              </Tooltip>

              <Tooltip title="Delete">
                <Fab size="small" color="secondary" onClick={deleteEmployee}>
                  <DeleteIcon />
                </Fab>
              </Tooltip>
            </>
          )}
        </div>
      </div>
      <XGrid3 
        columns={columns}
        setColumns={setColumns}
        rows={filteredRows}
        setSelection={setSelection}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    dataUpdate: state.dataUpdate
  };
};

export default connect(mapStateToProps, { showPlaceEmployment })(AgencyEmployees);
