import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField2 from 'components/TextField2';
import formStyles, {
  gridStyle, Row, RowInner, ColCard, Fills
} from 'utils/formStyles';
import Checkbox2 from 'components/Checkbox2';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import {getService} from 'reducers/service';
import { notify } from 'reducers/NotifierReducer';
import { handleError } from 'reducers/ErrorReducer';
import FormDivider from 'components/FormDivider';

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  wrap: {
    padding: theme.spacing(3),
  },
  item: gridStyle(200, 320),
  btn: {
    marginTop: theme.spacing(3),
    display: 'block',
    marginRight: 0,
    marginLeft: 'auto',
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  title: {
    width: 200
  }
}));

function AgencySettings(props) {
  const classes = useStyles();
  const { agencyId } = props;
  const service = getService('settings-agency-settings');
  const [HighestAge, setHighestAge] = useState("17");
  const [MaleOfficers, setMaleOfficers] = useState('');
  const [FemaleOfficers, setFemaleOfficers] = useState('');
  const [MaleCivilians, setMaleCivilians] = useState('');
  const [FemaleCivilians, setFemaleCivilians] = useState('');
  const [AutoCitations, setAutoCitations] = useState(false);
  const [CitationAssignments, setCitationAssignments] = useState(false);
  const [SystemGenerated, setSystemGenerated] = useState(false);
  const [usePredefined, setUsePredefined] = useState(false);
  const [StoredFiles, setStoredFiles] = useState('');
  const [DefaultStart, setDefaultStart] = useState('');
  const [DefaultTempopen, setDefaultTempopen] = useState('');

  useEffect(() => {
    search();
  }, []);

  const search = async () => {
    const result = await service.get(agencyId);
    renderInitial(result);
  }

  const renderInitial = (data) => {
    for (const row of data) {
      const { Path } = row;
      switch(true) {
        case Path.includes('Employees.FemaleCivilians'):
          setFemaleCivilians(row.ValueNumeric?.toString() || '');
          break;
        case Path.includes('Employees.FemaleOfficers'): 
          setFemaleOfficers(row.ValueNumeric?.toString() || '');
          break;
        case Path.includes('Employees.MaleCivilians'): 
          setMaleCivilians(row.ValueNumeric?.toString() || '');
          break;
        case Path.includes('Employees.MaleOfficers'): 
          setMaleOfficers(row.ValueNumeric?.toString() || '');
          break;
        case Path.includes('Numbering.AutoCitations'): 
          setAutoCitations(row.ValueBoolean);
          break;
        case Path.includes('Numbering.CitationBooks'): 
          setCitationAssignments(row.ValueBoolean);
          break;
        case Path.includes('Numbering.SystemNumbers'): 
          setSystemGenerated(row.ValueBoolean);
          break;
        case Path.includes('OldestJuvenile'): 
          setHighestAge(row.ValueNumeric?.toString() || '');
          break;
        case Path.includes('SetDefaultPath.StartPath'): 
          setDefaultStart(row.ValueString || '');
          break;
        case Path.includes('SetDefaultPath.StoredFiles'): 
          setStoredFiles(row.ValueString || '');
          break;
        case Path.includes('SetDefaultPath.TempopenPath'): 
          setDefaultTempopen(row.ValueString || '');
          break;
        case Path.includes('UsePre-definedDefaultPaths'): 
          setUsePredefined(row.ValueBoolean);
          break;
      }
    }
  }

  const handleSave = async () => {
    const saveObj = formatSaveData();
    try {
      await service.create(saveObj);  
      props.notify("Agency Settings Saved.", "success");
    }
    catch (err) {
      props.handleError(err, 'Error Saving Agency Settings.');
    }
  }

  const formatSaveData = () => {
    return (
      [
        {
          Path: `${agencyId}.OldestJuvenile`,
          CDRKey: 'Oldest Juvenile',
          ValueNumeric: Number(HighestAge)
        },
        {
          Path: `${agencyId}.Employees.MaleOfficers`,
          CDRKey: 'Employees',
          ValueNumeric: Number(MaleOfficers)
        },
        {
          Path: `${agencyId}.Employees.FemaleOfficers`,
          CDRKey: 'Employees',
          ValueNumeric: Number(FemaleOfficers)
        },
        {
          Path: `${agencyId}.Employees.MaleCivilians`,
          CDRKey: 'Employees',
          ValueNumeric: Number(MaleCivilians)
        },
        {
          Path: `${agencyId}.Employees.FemaleCivilians`,
          CDRKey: 'Employees',
          ValueNumeric: Number(FemaleCivilians)
        },
        {
          Path: `${agencyId}.Numbering.AutoCitations`,
          CDRKey: 'Numbering',
          ValueBoolean: AutoCitations
        },
        {
          Path: `${agencyId}.Numbering.CitationBooks`,
          CDRKey: 'Numbering',
          ValueBoolean: CitationAssignments
        },
        {
          Path: `${agencyId}.Numbering.SystemNumbers`,
          CDRKey: 'Numbering',
          ValueBoolean: SystemGenerated
        },
        {
          Path: `${agencyId}.UsePre-definedDefaultPaths`,
          CDRKey: 'Default Paths',
          ValueBoolean: usePredefined
        },
        {
          Path: `${agencyId}.SetDefaultPath.StoredFiles`,
          CDRKey: 'Default Paths',
          ValueString: StoredFiles
        },
        {
          Path: `${agencyId}.SetDefaultPath.StartPath`,
          CDRKey: 'Default Paths',
          ValueString: DefaultStart
        },
        {
          Path: `${agencyId}.SetDefaultPath.TempopenPath`,
          CDRKey: 'Default Paths',
          ValueString: DefaultTempopen
        },
      ]
    )
  }

  const renderHighestAge = () => {
    const handleChange = (e) => setHighestAge(e.target.value);
    return (
      <div>
        <FormDivider title="Highest Age Considered As Juvenile" />
        <RowInner>
          <FormControl component="fieldset">
            <RadioGroup value={HighestAge} onChange={handleChange}>
              <FormControlLabel value="17" control={<Radio />} label="17 (18 is Adult)" />
              <FormControlLabel value="16" control={<Radio />} label="16 (17 is Adult)" />
              <FormControlLabel value="15" control={<Radio />} label="15 (16 is Adult)" />
            </RadioGroup>
          </FormControl>
        </RowInner>
      </div>
    )
  }

  const renderMaleOfficers = () => {
    const onChange = (ev, val) => setMaleOfficers(val);
    return (
      <TextField2
        className={classes.item}
        label="Male"
        value={MaleOfficers}
        onChange={onChange}
      />
    );
  }

  const renderFemaleOfficers = () => {
    const onChange = (ev, val) => setFemaleOfficers(val);
    return (
      <TextField2
        className={classes.item}
        label="Female"
        value={FemaleOfficers}
        onChange={onChange}
      />
    );
  }

  const renderMaleCivilians = () => {
    const onChange = (ev, val) => setMaleCivilians(val);
    return (
      <TextField2
        className={classes.item}
        label="Male"
        value={MaleCivilians}
        onChange={onChange}
      />
    );
  }

  const renderFemaleCivilians = () => {
    const onChange = (ev, val) => setFemaleCivilians(val);
    return (
      <TextField2
        className={classes.item}
        label="Female"
        value={FemaleCivilians}
        onChange={onChange}
      />
    );
  }

  const renderEmployeeCounts = () => {
    return (
      <div>
        <FormDivider title="Employee Counts" />
        <RowInner>
          <h6 className={classes.title}> Fulltime Sworn Officers </h6>
          {renderMaleOfficers()}
          {renderFemaleOfficers()}
        </RowInner>
        <RowInner>
          <h6 className={classes.title}> Fulltime Civilians </h6>
          {renderMaleCivilians()}
          {renderFemaleCivilians()}
        </RowInner>
      </div>
    )
  }

  const renderAutoCitations = () => {
    const onChange = () => setAutoCitations(!AutoCitations);
    return (
      <div>
        <Checkbox2
          checked={AutoCitations}
          onChange={onChange}
          label="Auto Number Citations"
        />
      </div>
    );
  }

  const renderCitationAssignments = () => {
    const onChange = () => setCitationAssignments(!CitationAssignments);
    return (
      <div>
        <Checkbox2
          checked={CitationAssignments}
          onChange={onChange}
          label="Use Citation Number Assignments"
        />
      </div>
    );
  }

  const renderSystemGen = () => {
    const onChange = () => setSystemGenerated(!SystemGenerated);
    return (
      <div>
        <Checkbox2
          checked={SystemGenerated}
          onChange={onChange}
          label="Use System Generated Numbers Only"
        />
      </div>
    );
  }

  const renderNumbering = () => {
    return (
      <div>
        <FormDivider title="Numbering" />
        <RowInner>
          {renderAutoCitations()}
          {renderCitationAssignments()}
          {renderSystemGen()}
        </RowInner>
      </div>
    )
  }

  const renderUsePredefined = () => {
    const onChange = () => setUsePredefined(!usePredefined);
    return (
      <div>
        <Checkbox2
          checked={usePredefined}
          onChange={onChange}
          label="Use Pre-defined Path Defaults"
        />
      </div>
    );
  }

  const renderStoredFiles = () => {
    const onChange = (ev, val) => setStoredFiles(val);
    return (
      <TextField2
        className={classes.item}
        label="Stored Files Path"
        value={StoredFiles}
        onChange={onChange}
      />
    );
  }

  const renderDefaultStart = () => {
    const onChange = (ev, val) => setDefaultStart(val);
    return (
      <TextField2
        className={classes.item}
        label="Default Start Path"
        value={DefaultStart}
        onChange={onChange}
      />
    );
  }

  const renderDefaultTempopen = () => {
    const onChange = (ev, val) => setDefaultTempopen(val);
    return (
      <TextField2
        className={classes.item}
        label="Default Tempopen Path"
        value={DefaultTempopen}
        onChange={onChange}
      />
    );
  }

  const renderPaths = () => {
    return (
      <div>
        <FormDivider title="Paths" />
        {renderUsePredefined()}
        {renderStoredFiles()}
        {renderDefaultStart()}
        {renderDefaultTempopen()}
      </div>
    )
  }

  const renderSaveBtn = () => {
    return (
      <div>
        <Button 
          color="primary"
          variant="contained"
          onClick={handleSave}
          className="mt-4"
        >
          <SaveIcon /> Save
        </Button>
      </div>
    )
  }

  return (
    <div className={classes.wrap} >
      <Row>
        <ColCard minWidth={500}>
          {renderHighestAge()}
        </ColCard>
        <ColCard minWidth={500}>
          {renderEmployeeCounts()}
        </ColCard>
      </Row>
      <Row>
        <ColCard minWidth={500}>
          {renderNumbering()}
        </ColCard>
        <ColCard minWidth={500}>
          {renderPaths()}
        </ColCard>
      </Row>
      {renderSaveBtn()}
    </div>
  )
}


export default connect(null, {
  handleError, notify, 
})(AgencySettings);