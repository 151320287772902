import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField2 from 'components/TextField2';
import formStyles, {
  gridStyle, Row, RowInner, ColCard, Fills
} from 'utils/formStyles';
import Checkbox2 from 'components/Checkbox2';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import {getService} from 'reducers/service';
import { notify } from 'reducers/NotifierReducer';
import { handleError } from 'reducers/ErrorReducer';
import FormDivider from 'components/FormDivider';

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  wrap: {
    padding: theme.spacing(3),
  },
  item: gridStyle(200, 320),
  btn: {
    marginTop: theme.spacing(3),
    display: 'block',
    marginRight: 0,
    marginLeft: 'auto',
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  txtfield: {
    width: '100%'
  }
}));

function AgencyRms(props) {
  const { agencyId } = props;
  const classes = useStyles();
  const service = getService('settings-agency-rms');
  const [courtTitle, setCourtTitle] = useState('');
  const [ibrReporting, setIbrReporting] = useState('IBR Only');
  const [ucrRape, setUcrRape] = useState('New');
  const [ucrHate, setUcrHate] = useState('Monthly');
  const [agencyName, setAgencyName] = useState('');
  const [baseDate, setBaseDate] = useState('');
  const [atnOri, setAtnOri] = useState('');
  const [defaultComplaintID, setDefaultComplaintID] = useState('');
  const [defaultOffenderID, setDefaultOffenderID] = useState('');
  const [defaultOffenseCode, setDefaultOffenseCode] = useState('');
  const [showImport, setShowImport] = useState(false);
  const [sharedNumbers, setSharedNumbers] = useState(false);
  const [viewNarratives, setViewNarratives] = useState(false);
  const [importDefault, setImportDefault] = useState(false);
  const [userDefined, setUserDefined] = useState(false);
  const [dontPrintSSN, setDontPrintSSN] = useState(false);
  const [printArrestSign, setPrintArrestSign] = useState(false);
  const [dontPrintPOB, setDontPrintPOB] = useState(false);
  const [collegeAgency, setCollegeAgency] = useState(false);
  const [hideJuvInc, setHideJuvInc] = useState(false);
  const [enableIncDef, setEnableIncDef] = useState(false);
  const [enableImportSelect, setEnableImportSelect] = useState(false);
  const [enablePrintOther, setEnablePrintOther] = useState(false);
  const [enablePrintChoice, setEnablePrintChoice] = useState(false);
  const [enablePopup, setEnablePopup] = useState(false);
  const [allowSupplement, setAllowSupplement] = useState(false);
  const [disableApproval, setDisableApproval] = useState(false);
  const [requireArrest, setRequireArrest] = useState(false);
  const [richText, setRichText] = useState(false);
  const [enableOfficer, setEnableOfficer] = useState(false);
  const [openNarratives, setOpenNarratives] = useState(false);
  const [enableExpunge, setEnableExpunge] = useState(false);
  const [enableUnknown, setEnableUnknown] = useState(false);
  const [disablePress, setDisablePress] = useState(false);
  const [disableAutoStatus, setDisableAutoStatus] = useState(false);
  const [overridePrint, setOverridePrint] = useState(false);

  useEffect(() => {
    search();
  }, []);

  const search = async () => {
    const result = await service.get(agencyId);
    renderInitial(result);
  }

  const renderInitial = (data) => {
    for (const row of data) {
      const { Path } = row;
      switch(true) {
        case Path.includes('.RMS.CourtTitle'): 
          setCourtTitle(row.ValueString || '');
          break;
        case Path.includes('.RMS.IBR.UCR.Reporting'): 
          setIbrReporting(row.ValueString || '');
          break;
        case Path.includes('.RMS.UCR.RapeDefinition'): 
          setUcrRape(row.ValueString || '');
          break;
        case Path.includes('.RMS.UCR.HateCrimeReportingPeriod'): 
          setUcrHate(row.ValueString || '');
          break;
        case Path.includes('.RMS.AgencyName'): 
          setAgencyName(row.ValueString || '');
          break;
        case Path.includes('.RMS.BaseDate'): 
          setBaseDate(row.ValueString || '');
          break;
        case Path.includes('.RMS.ATN.ORI'): 
          setAtnOri(row.ValueString || '');
          break;
        case Path.includes('.RMS.DefaultComplainID'): 
          setDefaultComplaintID(row.ValueString || '');
          break;
        case Path.includes('.RMS.DefaultOffenderID'): 
          setDefaultOffenderID(row.ValueString || '');
          break;
        case Path.includes('.RMS.DefaultOffenseCode'): 
          setDefaultOffenseCode(row.ValueString || '');
          break;
        case Path.includes('.RMS.ShowImportFromCAD'):
          setShowImport(row.ValueBoolean || false);
          break;
        case Path.includes('.RMS.SharedNumbers'):
          setSharedNumbers(row.ValueBoolean || false);
          break;
        case Path.includes('.RMS.ViewNarrativeWithPasswords'):
          setViewNarratives(row.ValueBoolean || false);
          break;
        case Path.includes('.RMS.ImportDefaultAgencySpecific'):
          setImportDefault(row.ValueBoolean || false);
          break;
        case Path.includes('.RMS.UserDefinedWarrantNumbers'):
          setUserDefined(row.ValueBoolean || false);
          break;
        case Path.includes('.RMS.DontPrintSSN'):
          setDontPrintSSN(row.ValueBoolean || false);
          break;
        case Path.includes('.RMS.PrintArrestSign'):
          setPrintArrestSign(row.ValueBoolean || false);
          break;
        case Path.includes('.RMS.DontPrintPOB'):
          setDontPrintPOB(row.ValueBoolean || false);
          break;
        case Path.includes('.RMS.CollegeAgency'):
          setCollegeAgency(row.ValueBoolean || false);
          break;
        case Path.includes('.RMS.HideJuvenileIncidents'):
          setHideJuvInc(row.ValueBoolean || false);
          break;
        case Path.includes('.RMS.EnableIncidentDefaults'):
          setEnableIncDef(row.ValueBoolean || false);
          break;
        case Path.includes('.RMS.EnableImportSelect'):
          setEnableImportSelect(row.ValueBoolean || false);
          break;
        case Path.includes('.RMS.EnablePrintOther'):
          setEnablePrintOther(row.ValueBoolean || false);
          break;
        case Path.includes('.RMS.EnablePrintChoice'):
          setEnablePrintChoice(row.ValueBoolean || false);
          break;
        case Path.includes('.RMS.EnablePopup'):
          setEnablePopup(row.ValueBoolean || false);
          break;
        case Path.includes('.RMS.AllowSupplement'):
          setAllowSupplement(row.ValueBoolean || false);
          break;
        case Path.includes('.RMS.DisableApproval'):
          setDisableApproval(row.ValueBoolean || false);
          break;
        case Path.includes('.RMS.RequireArrest'):
          setRequireArrest(row.ValueBoolean || false);
          break;
        case Path.includes('.RMS.EnableOfficer'):
          setEnableOfficer(row.ValueBoolean || false);
          break;
        case Path.includes('.RMS.OpenNarratives'):
          setOpenNarratives(row.ValueBoolean || false);
          break;
        case Path.includes('.RMS.EnableExpunge'):
          setEnableExpunge(row.ValueBoolean || false);
          break;
        case Path.includes('.RMS.EnableUnknown'):
          setEnableUnknown(row.ValueBoolean || false);
          break;
        case Path.includes('.RMS.DisablePress'):
          setDisablePress(row.ValueBoolean || false);
          break;
        case Path.includes('.RMS.DisableAutoStatus'):
          setDisableAutoStatus(row.ValueBoolean || false);
          break;
        case Path.includes('.RMS.OverridePrint'):
          setOverridePrint(row.ValueBoolean || false);
          break;
        case Path.includes('.RMS.RichText'):
          setRichText(row.ValueBoolean || false);
          break;
      }
    }
  }


  const handleSave = async () => {
    const saveObj = formatSaveData();
    try {
      await service.create(saveObj);  
      props.notify("Agency RMS Settings Saved.", "success");
    }
    catch (err) {
      props.handleError(err, 'Error Saving Agency RMS Settings.');
    }
  }

  const formatSaveData = () => {
    return (
      [
        {
          Path: `${agencyId}.RMS.CourtTitle`,
          CDRKey: 'RMS Agency Settings',
          ValueString: courtTitle
        },
        {
          Path: `${agencyId}.RMS.IBR.UCR.Reporting`,
          CDRKey: 'RMS Agency Settings',
          ValueString: ibrReporting
        },
        {
          Path: `${agencyId}.RMS.UCR.RapeDefinition`,
          CDRKey: 'RMS Agency Settings',
          ValueString: ucrRape
        },
        {
          Path: `${agencyId}.RMS.UCR.HateCrimeReportingPeriod`,
          CDRKey: 'RMS Agency Settings',
          ValueString: ucrHate
        },
        {
          Path: `${agencyId}.RMS.AgencyName`,
          CDRKey: 'RMS Agency Settings',
          ValueString: agencyName
        },
        {
          Path: `${agencyId}.RMS.BaseDate`,
          CDRKey: 'RMS Agency Settings',
          ValueString: baseDate
        },
        {
          Path: `${agencyId}.RMS.ATN.ORI`,
          CDRKey: 'RMS Agency Settings',
          ValueString: atnOri
        },
        {
          Path: `${agencyId}.RMS.DefaultComplainID`,
          CDRKey: 'RMS Agency Settings',
          ValueString: defaultComplaintID
        },
        {
          Path: `${agencyId}.RMS.DefaultOffenderID`,
          CDRKey: 'RMS Agency Settings',
          ValueString: defaultOffenderID
        },
        {
          Path: `${agencyId}.RMS.DefaultOffenseCode`,
          CDRKey: 'RMS Agency Settings',
          ValueString: defaultOffenseCode
        },
        {
          Path: `${agencyId}.RMS.ShowImportFromCAD`,
          CDRKey: 'RMS Agency Settings',
          ValueBoolean: showImport
        },
        {
          Path: `${agencyId}.RMS.SharedNumbers`,
          CDRKey: 'RMS Agency Settings',
          ValueBoolean: sharedNumbers
        },
        {
          Path: `${agencyId}.RMS.ViewNarrativeWithPasswords`,
          CDRKey: 'RMS Agency Settings',
          ValueBoolean: viewNarratives
        },
        {
          Path: `${agencyId}.RMS.ImportDefaultAgencySpecific`,
          CDRKey: 'RMS Agency Settings',
          ValueBoolean: importDefault
        },
        {
          Path: `${agencyId}.RMS.UserDefinedWarrantNumbers`,
          CDRKey: 'RMS Agency Settings',
          ValueBoolean: userDefined
        },
        {
          Path: `${agencyId}.RMS.DontPrintSSN`,
          CDRKey: 'RMS Agency Settings',
          ValueBoolean: dontPrintSSN
        },
        {
          Path: `${agencyId}.RMS.PrintArrestSign`,
          CDRKey: 'RMS Agency Settings',
          ValueBoolean: printArrestSign
        },
        {
          Path: `${agencyId}.RMS.DontPrintPOB`,
          CDRKey: 'RMS Agency Settings',
          ValueBoolean: dontPrintPOB
        },
        {
          Path: `${agencyId}.RMS.CollegeAgency`,
          CDRKey: 'RMS Agency Settings',
          ValueBoolean: collegeAgency
        },
        {
          Path: `${agencyId}.RMS.HideJuvenileIncidents`,
          CDRKey: 'RMS Agency Settings',
          ValueBoolean: hideJuvInc
        },
        {
          Path: `${agencyId}.RMS.EnableIncidentDefaults`,
          CDRKey: 'RMS Agency Settings',
          ValueBoolean: enableIncDef
        },
        {
          Path: `${agencyId}.RMS.EnableImportSelect`,
          CDRKey: 'RMS Agency Settings',
          ValueBoolean: enableImportSelect
        },
        {
          Path: `${agencyId}.RMS.EnablePrintOther`,
          CDRKey: 'RMS Agency Settings',
          ValueBoolean: enablePrintOther
        },
        {
          Path: `${agencyId}.RMS.EnablePrintChoice`,
          CDRKey: 'RMS Agency Settings',
          ValueBoolean: enablePrintChoice
        },
        {
          Path: `${agencyId}.RMS.EnablePopup`,
          CDRKey: 'RMS Agency Settings',
          ValueBoolean: enablePopup
        },
        {
          Path: `${agencyId}.RMS.AllowSupplement`,
          CDRKey: 'RMS Agency Settings',
          ValueBoolean: allowSupplement
        },
        {
          Path: `${agencyId}.RMS.DisableApproval`,
          CDRKey: 'RMS Agency Settings',
          ValueBoolean: disableApproval
        },
        {
          Path: `${agencyId}.RMS.RequireArrest`,
          CDRKey: 'RMS Agency Settings',
          ValueBoolean: requireArrest
        },
        {
          Path: `${agencyId}.RMS.RichText`,
          CDRKey: 'RMS Agency Settings',
          ValueBoolean: richText
        },
        {
          Path: `${agencyId}.RMS.EnableOfficer`,
          CDRKey: 'RMS Agency Settings',
          ValueBoolean: enableOfficer
        },
        {
          Path: `${agencyId}.RMS.OpenNarratives`,
          CDRKey: 'RMS Agency Settings',
          ValueBoolean: openNarratives
        },
        {
          Path: `${agencyId}.RMS.EnableExpunge`,
          CDRKey: 'RMS Agency Settings',
          ValueBoolean: enableExpunge
        },
        {
          Path: `${agencyId}.RMS.EnableUnknown`,
          CDRKey: 'RMS Agency Settings',
          ValueBoolean: enableUnknown
        },
        {
          Path: `${agencyId}.RMS.DisablePress`,
          CDRKey: 'RMS Agency Settings',
          ValueBoolean: disablePress
        },
        {
          Path: `${agencyId}.RMS.DisableAutoStatus`,
          CDRKey: 'RMS Agency Settings',
          ValueBoolean: disableAutoStatus
        },
        {
          Path: `${agencyId}.RMS.OverridePrint`,
          CDRKey: 'RMS Agency Settings',
          ValueBoolean: overridePrint
        },
      ]
    )
  }


  const renderCourtTitle = () => {
    const onChange = (ev, val) => setCourtTitle(val);
    return (
      <TextField2
        className={classes.txtfield}
        label="Court Title"
        value={courtTitle}
        onChange={onChange}
      />
    );
  }

  const renderIbrReporting = () => {
    const handleChange = (e) => setIbrReporting(e.target.value);
    return (
      <div>
        <FormDivider title="IBR/UCR Reporting" />
        <RowInner>
          <FormControl component="fieldset">
            <RadioGroup value={ibrReporting} onChange={handleChange}>
              <FormControlLabel value="IBR Only" control={<Radio />} label="IBR Only" />
              <FormControlLabel value="UCR Paper Only" control={<Radio />} label="UCR Paper Only" />
              <FormControlLabel value="UCR Electronic Only" control={<Radio />} label="UCR Electronic Only" />
              <FormControlLabel value="Both IBR/UCR Paper" control={<Radio />} label="Both IBR/UCR Paper" />
              <FormControlLabel value="Both IBR/UCR Electronic" control={<Radio />} label="Both IBR/UCR Electronic" />
            </RadioGroup>
          </FormControl>
        </RowInner>
      </div>
    )
  }

  const renderUcrRape = () => {
    const handleChange = (e) => setUcrRape(e.target.value);
    return (
      <div>
        <FormDivider title="UCR Rape Definition" />
        <RowInner>
          <FormControl component="fieldset">
            <RadioGroup value={ucrRape} onChange={handleChange}>
              <FormControlLabel value="Original" control={<Radio />} label="Original" />
              <FormControlLabel value="New" control={<Radio />} label="New" />
            </RadioGroup>
          </FormControl>
        </RowInner>
      </div>
    )
  }

  const renderUcrHate = () => {
    const handleChange = (e) => setUcrHate(e.target.value);
    return (
      <div>
        <FormDivider title="UCR Hate Crime Reporting Period" />
        <RowInner>
          <FormControl component="fieldset">
            <RadioGroup value={ucrHate} onChange={handleChange}>
              <FormControlLabel value="Quarterly" control={<Radio />} label="Quarterly" />
              <FormControlLabel value="Monthly" control={<Radio />} label="Monthly" />
            </RadioGroup>
          </FormControl>
        </RowInner>
      </div>
    )
  }

  const renderNibrsReporting = () => {
    return (
      <div>
        <FormDivider title="NIBRS Reporting Settings" />
        <TextField2
          className={classes.txtfield}
          label="Agency Name"
          value={agencyName}
          onChange={(ev, val) => setAgencyName(val)}
        />
        <TextField2
          className={classes.txtfield}
          label="Base Date"
          value={baseDate}
          onChange={(ev, val) => setBaseDate(val)}
        />
        <TextField2
          className={classes.txtfield}
          label="ATN ORI"
          value={atnOri}
          onChange={(ev, val) => setAtnOri(val)}
        />
      </div>
    )
  }

  const renderShortForm = () => {
    return (
      <div>
        <FormDivider title="Short Form Defaults" />
        <TextField2
          className={classes.txtfield}
          label="Default Complaint ID"
          value={defaultComplaintID}
          onChange={(ev, val) => setDefaultComplaintID(val)}
        />
        <TextField2
          className={classes.txtfield}
          label="Default Offender ID"
          value={defaultOffenderID}
          onChange={(ev, val) => setDefaultOffenderID(val)}
        />
        <TextField2
          className={classes.txtfield}
          label="Default Offense Code"
          value={defaultOffenseCode}
          onChange={(ev, val) => setDefaultOffenseCode(val)}
        />
      </div>
    )
  }

  const renderShowImport = () => {
    const onChange = () => setShowImport(!showImport);
    return (
      <span className={classes.item}>
        <Checkbox2
          checked={showImport}
          onChange={onChange}
          label="Show Import From CAD or Incident Program"
        />
      </span>
    );
  }
  
  const renderSharedNumbers = () => {
    const onChange = () => setSharedNumbers(!sharedNumbers);
    return (
      <span className={classes.item}>
        <Checkbox2
          checked={sharedNumbers}
          onChange={onChange}
          label="Shared CAD/Incident Numbers"
        />
      </span>
    );
  }
  const renderViewNarratives = () => {
    const onChange = () => setViewNarratives(!viewNarratives);
    return (
      <span className={classes.item}>
        <Checkbox2
          checked={viewNarratives}
          onChange={onChange}
          label="View Narratives With Password"
        />
      </span>
    );
  }
  const renderImportDefault = () => {
    const onChange = () => setImportDefault(!importDefault);
    return (
      <span className={classes.item}>
        <Checkbox2
          checked={importDefault}
          onChange={onChange}
          label="Import Default Agency Specific"
        />
      </span>
    );
  }
  const renderUserDefWarrant = () => {
    const onChange = () => setUserDefined(!userDefined);
    return (
      <span className={classes.item}>
        <Checkbox2
          checked={userDefined}
          onChange={onChange}
          label="User Defined Warrant Numbers Preference"
        />
      </span>
    );
  }
  const renderDontPrintSSN = () => {
    const onChange = () => setDontPrintSSN(!dontPrintSSN);
    return (
      <span className={classes.item}>
        <Checkbox2
          checked={dontPrintSSN}
          onChange={onChange}
          label="Do not Print SSN or Incident Prints"
        />
      </span>
    );
  }
  const renderPrintArrest = () => {
    const onChange = () => setPrintArrestSign(!printArrestSign);
    return (
      <span className={classes.item}>
        <Checkbox2
          checked={printArrestSign}
          onChange={onChange}
          label="Print Arrest Signature Detail"
        />
      </span>
    );
  }
  const renderDontPrintPOB = () => {
    const onChange = () => setDontPrintPOB(!dontPrintPOB);
    return (
      <span className={classes.item}>
        <Checkbox2
          checked={dontPrintPOB}
          onChange={onChange}
          label="Do Not Print Place of Birth"
        />
      </span>
    );
  }
  const renderCollegeAgency = () => {
    const onChange = () => setCollegeAgency(!collegeAgency);
    return (
      <span className={classes.item}>
        <Checkbox2
          checked={collegeAgency}
          onChange={onChange}
          label="This is a College Agency"
        />
      </span>
    );
  }
  const renderHideJuvInc = () => {
    const onChange = () => setHideJuvInc(!hideJuvInc);
    return (
      <span className={classes.item}>
        <Checkbox2
          checked={hideJuvInc}
          onChange={onChange}
          label="Hide Juvenile Incidents"
        />
      </span>
    );
  }
  const renderEnableIncDef = () => {
    const onChange = () => setEnableIncDef(!enableIncDef);
    return (
      <span className={classes.item}>
        <Checkbox2
          checked={enableIncDef}
          onChange={onChange}
          label="Enable Incidents Defaults"
        />
      </span>
    );
  }
  const renderEnableImpSelectAll = () => {
    const onChange = () => setEnableImportSelect(!enableImportSelect);
    return (
      <span className={classes.item}>
        <Checkbox2
          checked={enableImportSelect}
          onChange={onChange}
          label="Enable Import Select All"
        />
      </span>
    );
  }
  const renderEnablePrintOther = () => {
    const onChange = () => setEnablePrintOther(!enablePrintOther);
    return (
      <span className={classes.item}>
        <Checkbox2
          checked={enablePrintOther}
          onChange={onChange}
          label="Enable Print Other Party Details"
        />
      </span>
    );
  }
  const renderEnablePrintChoice = () => {
    const onChange = () => setEnablePrintChoice(!enablePrintChoice);
    return (
      <span className={classes.item}>
        <Checkbox2
          checked={enablePrintChoice}
          onChange={onChange}
          label="Enable Print Choice On Unapproved"
        />
      </span>
    );
  }
  const renderEnablePopup = () => {
    const onChange = () => setEnablePopup(!enablePopup);
    return (
      <span className={classes.item}>
        <Checkbox2
          checked={enablePopup}
          onChange={onChange}
          label="Enable Popup for Warrant on Insert"
        />
      </span>
    );
  }
  const renderAllowSupp = () => {
    const onChange = () => setAllowSupplement(!allowSupplement);
    return (
      <span className={classes.item}>
        <Checkbox2
          checked={allowSupplement}
          onChange={onChange}
          label="Allow Suppment Insert On Locked Incident"
        />
      </span>
    );
  }
  const renderDisableApprovalSign = () => {
    const onChange = () => setDisableApproval(!disableApproval);
    return (
      <span className={classes.item}>
        <Checkbox2
          checked={disableApproval}
          onChange={onChange}
          label="Disable Approval Signature On Selection Print"
        />
      </span>
    );
  }
  const renderReqArrestLocation = () => {
    const onChange = () => setRequireArrest(!requireArrest);
    return (
      <span className={classes.item}>
        <Checkbox2
          checked={requireArrest}
          onChange={onChange}
          label="Require Arrest Location From Address File"
        />
      </span>
    );
  }
  const renderRichText = () => {
    const onChange = () => setRichText(!richText);
    return (
      <span className={classes.item}>
        <Checkbox2
          checked={richText}
          onChange={onChange}
          label="Rich Text For Narratives"
        />
      </span>
    );
  }
  const renderEnableOfficer = () => {
    const onChange = () => setEnableOfficer(!enableOfficer);
    return (
      <span className={classes.item}>
        <Checkbox2
          checked={enableOfficer}
          onChange={onChange}
          label="Enable Officer Involved Shooting Data"
        />
      </span>
    );
  }
  const renderOpenNarratives = () => {
    const onChange = () => setOpenNarratives(!openNarratives);
    return (
      <span className={classes.item}>
        <Checkbox2
          checked={openNarratives}
          onChange={onChange}
          label="Open Narratives In View Mode On Double Click"
        />
      </span>
    );
  }
  const renderEnableExpunge = () => {
    const onChange = () => setEnableExpunge(!enableExpunge);
    return (
      <span className={classes.item}>
        <Checkbox2
          checked={enableExpunge}
          onChange={onChange}
          label="Enable Expunge Party"
        />
      </span>
    );
  }
  const renderEnableUnknownOffender = () => {
    const onChange = () => setEnableUnknown(!enableUnknown);
    return (
      <span className={classes.item}>
        <Checkbox2
          checked={enableUnknown}
          onChange={onChange}
          label="Enable Unknown Offender Button"
        />
      </span>
    );
  }
  const renderDisablePressRelease = () => {
    const onChange = () => setDisablePress(!disablePress);
    return (
      <span className={classes.item}>
        <Checkbox2
          checked={disablePress}
          onChange={onChange}
          label="Disable Press Release Property Print"
        />
      </span>
    );
  }
  const renderDisableAutoStatus = () => {
    const onChange = () => setDisableAutoStatus(!disableAutoStatus);
    return (
      <span className={classes.item}>
        <Checkbox2
          checked={disableAutoStatus}
          onChange={onChange}
          label="Disable Automatic Status Add"
        />
      </span>
    );
  }
  const renderOverridePrint = () => {
    const onChange = () => setOverridePrint(!overridePrint);
    return (
      <span className={classes.item}>
        <Checkbox2
          checked={overridePrint}
          onChange={onChange}
          label="Override Print With Local Ordinance"
        />
      </span>
    );
  }

  const renderSaveBtn = () => {
    return (
      <div>
        <Button 
          color="primary"
          variant="contained"
          onClick={handleSave}
          className="mt-4"
        >
          <SaveIcon /> Save
        </Button>
      </div>
    )
  }


  return (
    <div className={classes.wrap}>
      <Row>
        <ColCard minWidth={300}>
          {renderCourtTitle()}
          {renderIbrReporting()}
        </ColCard>
        <ColCard minWidth={300}>
          {renderUcrRape()}
          {renderUcrHate()}
        </ColCard>
        <ColCard minWidth={300}>
          {renderNibrsReporting()}
          {renderShortForm()}
        </ColCard>
      </Row>
      <Row>
        <ColCard minWidth={300}>
          <RowInner>
          {renderShowImport()}
          {renderSharedNumbers()}
          {renderViewNarratives()}
          {renderImportDefault()}
          </RowInner>
          <RowInner>
          {renderUserDefWarrant()}
          {renderDontPrintSSN()}
          {renderPrintArrest()}
          {renderDontPrintPOB()}
          </RowInner>
          <RowInner>
          {renderCollegeAgency()}
          {renderHideJuvInc()}
          {renderEnableIncDef()}
          {renderEnableImpSelectAll()}
          </RowInner>
          <RowInner>
          {renderEnablePrintOther()}
          {renderEnablePrintChoice()}
          {renderEnablePopup()}
          {renderAllowSupp()}
          </RowInner>
          <RowInner>
          {renderDisableApprovalSign()}
          {renderReqArrestLocation()}
          {renderRichText()}
          {renderEnableOfficer()}
          </RowInner>
          <RowInner>
          {renderOpenNarratives()}
          {renderEnableExpunge()}
          {renderEnableUnknownOffender()}
          {renderDisablePressRelease()}
          </RowInner>
          <RowInner>
          {renderDisableAutoStatus()}
          {renderOverridePrint()}
          </RowInner>
        </ColCard>
      </Row>
      {renderSaveBtn()}
    </div>
  )
}


export default connect(null, {
  handleError, notify, 
})(AgencyRms);