import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

import { handleError } from '../reducers/ErrorReducer';
import { showSpinner, hideSpinner } from '../reducers/UiReducer';
import { notify } from '../reducers/NotifierReducer';
import { closeAddEditEventTypeInitials } from '../reducers/DialogsReducer';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  Fab,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { DialogContent } from '@mui/material';
import { getService } from 'reducers/service';
import DatePicker2 from 'components/DatePicker2';
import { processPhoneNoWithoutExtra } from 'utils/functions';
import options from 'components/EventTypeIcons';
import Checkbox2 from 'components/Checkbox2';
import { SketchPicker } from 'react-color';
const useStyles = makeStyles(theme => ({
  actions: {
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  textField: {
    width: 600,
    maxWidth: '100%',
    marginTop: theme.spacing(1),
  },
  dialog: {
    '& $content': {
      margin: 0,
      padding: 0,
      background: 'red !important',
    },
  },
  popover: {
    position: 'absolute',
    zIndex: '999',
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
}));
const ITEM_HEIGHT = 48;
const DEFAULT_COLOR = '#dedfe3';
function AddEditEventTypeInitials(props) {
  const classes = useStyles();
  const { wsClient, user } = props;

  const { type } = props.data;
  const username = user?.userData?.user?.Username;
  const ptsUserID = user?.userData?.user?.ptsUserID;
  const service = getService('settings-event-type-initials');
  const [Code, setCode] = useState('');
  const [saveError, setSaveError] = useState(null);
  const [initial, setInitial] = useState('');
  const [selectedIconIndex, setSelectedIconIndex] = useState(0);
  const [filterText, setFilterText] = useState('');
  const [Description, setDescription] = useState('');
  const [IsActive, setIsActive] = useState(false);
  const [color, setColor] = useState(DEFAULT_COLOR);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  useEffect(() => {
    const info = props.data.info;
    if (type == 'edit' && info?.Code) {
      setCode(info.Code);
      setInitial(info.Initial);
      setSelectedIconIndex(info.Icon);
      setDescription(info.Description);
      setIsActive(info.IsActive);
      setColor(info.Color || DEFAULT_COLOR);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.info, type]);

  const close = () => {
    props.closeAddEditEventTypeInitials();
  };

  const save = async () => {
    props.showSpinner();

    let result;

    try {
      //   await addEventNote(text, ptsEventID);
      const newObj = {
        CDRKey: Code,
        ValueString: initial,
        ValueNumeric: selectedIconIndex,
        IsActive,
        Color: color,
        Description,
      };
     
      if (type == 'add') {
        const id = props.data?.info?.id;

        result = await service.create(newObj);
        props.notify('New File Created', 'success');
      } else {
        result = await service.patch(Code, newObj);
        props.notify('File Updated', 'success');
      }
    } catch (err) {
      props.handleError(err, 'Error, File not saved.', err);
    }
    props.hideSpinner();
    close();
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleIconOptionsClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleIconOptionsClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (ev, idx) => {
 
    setSelectedIconIndex(idx);
    setAnchorEl(null);
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={save}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const CodeErrorHelpertext = () => {
    if (Code.length > 10) return '* Code should be 10 characters max!';
    if (saveError) return `* ${saveError}`;
  };

  const MenuIcon = options[selectedIconIndex];
  return (
    <Dialog
      onClose={close}
      title={type === 'edit' ? 'Edit' : 'Add' + 'Event Type Initials and Icons'}
      open={true}
      actions={renderActions()}
      //   className={classes.dialog}

      // toolbar={false}
      // draggable={false}
      fullWidth
      maxWidth="lg">
      <DialogTitle>{type == 'edit' ? 'Edit' : 'Add'} Event Type Initials and Icons</DialogTitle>
      <DialogContent className="py-2">
        <Grid container spacing={2} style={{ width: '100%', alignItems: 'center' }}>
          <Grid item xs={12} sm={2}>
            <TextField
              className={classes.textField}
              error={Code.length > 10 || typeof saveError === 'string'}
              helperText={CodeErrorHelpertext()}
              label="Event Type"
              inputProps={{ maxLength: 10 }}
              value={Code}
              onChange={ev => setCode(ev.target.value)}
              variant="outlined"
              size="small"
              disabled={type === 'edit'}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              error={initial.length > 3}
              helperText={initial.length <= 3 ? '' : '* Max 3 Chars!'}
              className={classes.textField}
              inputProps={{ maxLength: 3 }}
              label="Initials"
              value={initial}
              onChange={ev => setInitial(ev.target.value)}
              variant="outlined"
              size="small"
            />
          </Grid>

          <Grid item xs={12} sm={5}>
            <TextField
              className={classes.textField}
              label="Description"
              value={Description}
              onChange={ev => setDescription(ev.target.value)}
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <FormControlLabel
              style={{ paddingTop: '12px' }}
              label="Active"
              control={
                <Checkbox
                  checked={IsActive}
                  onChange={ev => setIsActive(ev.target.checked)}
                  label="Active"
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Fab
              title="Pick a color"
              className="ml-2 mr-2"
              size="small"
              style={{ backgroundColor: color }}
              onClick={() => setDisplayColorPicker(true)}></Fab>
            {displayColorPicker ? (
              <Dialog maxWidth="sm" open={true} onClose={() => setDisplayColorPicker(false)}>
                <SketchPicker color={color} onChange={color => setColor(color.hex)} />
              </Dialog>
            ) : null}
            <Fab
              className="ml-2 mr-2"
              size="small"
              color="primary"
              onClick={handleIconOptionsClick}>
              <MenuIcon />
            </Fab>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleIconOptionsClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 10,
                  width: '48ch',
                },
              }}>
              <Grid container>
                {Object.entries(options)
                  .filter(option => {
                    const iconName = option[1].name;
                    return iconName.toLowerCase().includes(filterText.toLowerCase());
                  })
                  .map(([key, Icon]) => (
                    <span
                      className="m-3"
                      key={key}
                      onClick={event => handleMenuItemClick(event, key)}>
                      <Fab size="small" color="inherit">
                        <Icon />
                      </Fab>
                    </span>
                  ))}
              </Grid>
            </Menu>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className=" mb-1 mr-3">{renderActions()}</div>
      </DialogActions>
    </Dialog>
  );
}
const mapStateToProps = state => {
  return {
    dictionary: state.dictionary,
    wsClient: state.websocket,
    user: state.user,
  };
};
export default connect(mapStateToProps, {
  showSpinner,
  hideSpinner,
  handleError,
  notify,
  closeAddEditEventTypeInitials,
})(AddEditEventTypeInitials);
