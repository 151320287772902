import { getService } from "reducers/service";

export const getAgency = async (AgencyID) => {
  const service = getService('cad-search-agencies');
  return await service.get(AgencyID);
}

export const editAgency = (AgencyID, data) => {
  const service = getService('cad-search-agencies');
  return service.patch(AgencyID, data);
};

export const getPtsPlace = (ptsPlaceID) => {
  const service = getService('places');
  return service.get(ptsPlaceID);
};

export const findVehicleReg = async (ptsPlaceID) => {
  const service = getService('ptsvehiclereg');
  const result = await service.find({
    query: {
      ptsParentID: ptsPlaceID,
      PartyType: 'Place',
      IsDeleted: 0,
      $sort: {
        Created: -1
      },
    },
  });
  return result.data;
}

export const searchPlaceAddresses = async (ptsPlaceID) => {
  const service = getService('cad-search-place-address');
  const result = await service.find({
    query: { ptsPlaceID, IsDeleted: 0 },
  });

  if (!result?.data?.length) return [];
  return result.data.map(r => r.Address);
};

export const addVehicleReg = (data) => {
  const service = getService('cad-pts-vehicle-reg');
  return service.create(data);
};

export const searchAddress = (ptsAddressID) => {
  const service = getService('cad-search-addresses');
  return service.get(ptsAddressID);
};

export const updateVehicleReg = (id, data) => {
  const service = getService('cad-pts-vehicle-reg');
  return service.patch(id, data);
};

export const savePlaceAddress = async (data) => {
  const service = getService('cad-search-place-address');
  return service.create(data);
};

export const removePlaceAddress = (data) => {
  const service = getService('cad-place-address');
  return service.remove(data);
};