import {
  // MainLayout,
  CadLayout,
  // PagesLayout,
  // CollapsedSidebar,
  // LeftSidebar,
  // PresentationLayout
} from './layout-blueprints';

// Pages
import DevTest from './pages/DevTest';
import PageDebugInfo from './pages/PageDebugInfo';
import Settings from './Settings';
import Resources from './Settings/Resources';
import Automation from './Settings/Automation';
import CustomIdentifiers from './Settings/CustomIdentifiers';
import CustomStatuses from './Settings/CustomStatuses';
import Defaults from './Settings/Defaults';
// import DuplicateChecking from './Settings/DuplicateChecking';
import E911Setup from './Settings/E911Setup';
import E911Workstations from './Settings/E911Workstations';
import ExternalConnections from './Settings/ExternalConnections';
import LookupCodes from './Settings/LookupCodes';
import Notifications from './Settings/Notifications';
import SpellingDictionary from './Settings/SpellingDictionary';
import SystemNotifications from './Settings/SystemNotifications';
import ColorSettings from './Settings/ColorSettings';
import CustomAddresses from './Settings/CustomAddresses';
import CustomAlerts from './Settings/CustomAlerts';
import CustomItems from './Settings/CustomItems';
import CustomCases from './Settings/CustomCases';
import CustomPeople from './Settings/CustomPeople';
import CustomPlaces from './Settings/CustomPlaces';
import CustomVehicles from './Settings/CustomVehicles';
import CustomWarrant from './Settings/CustomWarrant';
import CustomCitation from './Settings/CustomCitation';
import CustomFirearm from './Settings/CustomFirearm';
import CustomJewelry from './Settings/CustomJewelry';
import CustomStructure from './Settings/CustomStructure';
import GeofenceDesigner from './Settings/GeofenceDesigner';
import SOPs from './Settings/SOPs';
import EventsDuplicate from './Settings/DuplicateChecking/EventsDuplicate';
import VehiclesDuplicate from './Settings/DuplicateChecking/VehiclesDuplicate';
import PeopleDuplicate from './Settings/DuplicateChecking/PeopleDuplicate';
import AddressesDuplicate from './Settings/DuplicateChecking/AddressesDuplicate';
import PlacesDuplicate from './Settings/DuplicateChecking/PlacesDuplicate';
import DndTest from './pages/DndTest2';
import AgencyTypeIcons from './Settings/AgencyTypeIcons';
import SecUsers from 'Settings/SecUsers';
import WorkFlows from './Settings/WorkFlows/index';
import AddEditWorkFlowStep from './Settings/WorkFlows/Forms/AddEditWorkFlowStep';
import AddEditWorkFlow from './Settings/WorkFlows/Forms/AddEditWorkFlow';
import SearchProfiles from './Settings/Security/Search/SearchProfiles';
import SearchUsers from './Settings/Security/Search/SearchUsers';
import SearchClearance from './Settings/Security/Search/SearchClearance';
import SearchUsersHistory from 'Settings/Security/Search/SearchUsersHistory';
import Permissions from './Settings/Security/Permissions';
import RipAndRun from './Settings/RipAndRun';
import Appearance from './Settings/Appearance/index';
import MobileApp from 'Settings/Mobile/MobileApp';
import MobileNcicApp from 'Settings/Mobile/NcicApp';
import CitationFileInfo from 'Settings/Mobile/CitationFileInfo';
import AgencyBasedUnitSelection from 'Settings/Mobile/AgencyBasedUnitSelection';
import Agencies from 'Settings/Agencies';
import TwoFactorAuth from 'Settings/TwoFactorAuth/TwoFactorAuth';
import AddVehicleDefaultPlace from 'Settings/AddVehicleDefaultPlace';
import CitationCourt from 'Settings/Mobile/CitationCourt';
import DefaultEmailTemplate from 'Settings/Security/EmailTemplate/DefaultEmailTemplate';
import EventTypeInitial from 'Settings/EventTypeInitial';

export const defaultUrl = '/settings';

export default [
  { url: '/dev-test/:tab?', component: DevTest, layout: CadLayout },
  { url: '/debug-info', component: PageDebugInfo, layout: CadLayout },
  { url: '/resources', component: Resources, layout: CadLayout },
  { url: '/custom-identifiers', component: CustomIdentifiers, layout: CadLayout },
  { url: '/custom-statuses', component: CustomStatuses, layout: CadLayout },
  { url: '/custom-addresses', component: CustomAddresses, layout: CadLayout },
  { url: '/custom-alerts', component: CustomAlerts, layout: CadLayout },
  { url: '/custom-cases', component: CustomCases, layout: CadLayout },
  { url: '/custom-items', component: CustomItems, layout: CadLayout },
  { url: '/custom-people', component: CustomPeople, layout: CadLayout },
  { url: '/custom-places', component: CustomPlaces, layout: CadLayout },
  { url: '/custom-vehicles', component: CustomVehicles, layout: CadLayout },
  { url: '/custom-warrant', component: CustomWarrant, layout: CadLayout },
  { url: '/custom-citation', component: CustomCitation, layout: CadLayout },
  { url: '/custom-firearm', component: CustomFirearm, layout: CadLayout },
  { url: '/custom-jewelry', component: CustomJewelry, layout: CadLayout },
  { url: '/custom-structure', component: CustomStructure, layout: CadLayout },
  { url: '/defaults', component: Defaults, layout: CadLayout },
  { url: '/lookup-codes', component: LookupCodes, layout: CadLayout },
  { url: '/lookup-codes-rms', component: LookupCodes, layout: CadLayout },
  { url: '/lookup-codes-cad', component: LookupCodes, layout: CadLayout },
  { url: '/lookup-codes-ibr', component: LookupCodes, layout: CadLayout },
  { url: '/spelling-dictionary', component: SpellingDictionary, layout: CadLayout },
  { url: '/automation', component: Automation, layout: CadLayout },
  { url: '/system-notifications', component: SystemNotifications, layout: CadLayout },
  { url: '/e911-setup', component: E911Setup, layout: CadLayout },
  { url: '/e911-workstations', component: E911Workstations, layout: CadLayout },
  { url: '/external-connections', component: ExternalConnections, layout: CadLayout },
  { url: '/notifications', component: Notifications, layout: CadLayout },
  { url: '/colors', component: ColorSettings, layout: CadLayout },
  { url: '/settings', component: Settings, layout: CadLayout },
  { url: '/dnd', component: DndTest, layout: CadLayout },
  { url: '/geofence-designer', component: GeofenceDesigner, layout: CadLayout },
  { url: '/sops', component: SOPs, layout: CadLayout },
  { url: '/events-duplicate-checking', component: EventsDuplicate, layout: CadLayout },
  { url: '/vehicles-duplicate-checking', component: VehiclesDuplicate, layout: CadLayout },
  { url: '/people-duplicate-checking', component: PeopleDuplicate, layout: CadLayout },
  { url: '/addresses-duplicate-checking', component: AddressesDuplicate, layout: CadLayout },
  { url: '/places-duplicate-checking', component: PlacesDuplicate, layout: CadLayout },
  { url: '/event-type-initials', component: EventTypeInitial, layout: CadLayout },
  { url: '/agency-type-icons', component: AgencyTypeIcons, layout: CadLayout },
  { url: '/appearance', component: Appearance, layout: CadLayout },
  { url: '/search-profiles', component: SearchProfiles, layout: CadLayout },
  { url: '/search-users', component: SearchUsers, layout: CadLayout },
  { url: '/default-email-template', component: DefaultEmailTemplate, layout: CadLayout },
  { url: '/search-clearance-levels', component: SearchClearance, layout: CadLayout },
  { url: '/search-users-history', component: SearchUsersHistory, layout: CadLayout },
  { url: '/security-permissions', component: Permissions, layout: CadLayout },
  { url: '/sec-users', component: SecUsers, layout: CadLayout },
  { url: '/ripandrun', component: RipAndRun, layout: CadLayout },
  { url: '/mobile', component: MobileApp, layout: CadLayout },
  { url: '/agencies', component: Agencies, layout: CadLayout },
  { url: '/vehicle-default-place', component: AddVehicleDefaultPlace, layout: CadLayout },
  { url: '/agency-unit-selection', component: AgencyBasedUnitSelection, layout: CadLayout },
  { url: '/citation-info', component: CitationFileInfo, layout: CadLayout },
  { url: '/citation-court', component: CitationCourt, layout: CadLayout },
  { url: '/mobile-ncic', component: MobileNcicApp, layout: CadLayout },
  { url: '/two-factor-auth', component: TwoFactorAuth, layout: CadLayout },
  { url: '/workflows/:id/steps/add', component: AddEditWorkFlowStep, layout: CadLayout },
  { url: '/workflows/:id/steps/edit/:stepid', component: AddEditWorkFlowStep, layout: CadLayout },
  { url: '/workflows/edit/:id', component: AddEditWorkFlow, layout: CadLayout },
  { url: '/workflows/add', component: AddEditWorkFlow, layout: CadLayout },
  { url: '/workflows', component: WorkFlows, layout: CadLayout },
];
