import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PersonLookup from 'components/PersonLookup';
import { gridStyle } from 'utils/formStyles';
import { findSearchProfiles, findSearchClearanceLevels } from 'Settings/Security/SecFunctions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Box, Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getPartyPerson } from 'reducers/EventsReducer';

const useStyles = makeStyles(theme => ({
  item: gridStyle(200, 320),
  autoComplete: gridStyle(200, 300),
}));

function AddUser(props) {
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [person, setPerson] = useState(null);
  const [profile, setProfile] = useState(null);
  const [clearance, setClearance] = useState(null);
  const [email, setEmail] = useState('');
  const [allProfiles, setAllProfiles] = useState([]);
  const [allClearanceLevels, setAllClearanceLevels] = useState([]);
  const [isLockedOut, setIsLockedOut] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [defaultAgency, setDefaultAgency] = useState('');
  const [NCICUsername, setNCICUsername] = useState('');
  const [ori, setOri] = useState(null);
  const [rid, setRid] = useState(null);
  const [forcePW, setForcePW] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [defaultApplication, setDefaultApplication] = useState(null);

  const [userNameError, setUserNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const { setNewUser, selectedUser } = props;

  const agencies = useSelector(state => state.dictionary.Agencies);

  const handleChangeWithValidation = (key, value, setState, validateInput, setError) => {
    setState(value);
    setNewUser(user => {
      return { ...user, [key]: value };
    });
    if (validateInput(value)) setError(false);
    else setError(true);
  };

  const handleChange = (key, value, setState, stateValue) => {
    setState(value);
    setNewUser(user => {
      return { ...user, [key]: stateValue };
    });
  };

  const validateUserName = value => {
    if (!/^[a-zA-Z0-9]+$/.test(value)) {
      return false;
    } else {
      return true;
    }
  };

  const validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  useEffect(() => {
    fetchProfiles();
    fetchClearanceLevels();
  }, []);

  useEffect(() => {
    if (person) {
      setNewUser(user => {
        return { ...user, ptsPersonId: person.ptsPersonID };
      });
    } else {
      setNewUser(user => {
        return { ...user, ptsPersonId: null };
      });
    }
  }, [person]);

  useEffect(() => {
    if (allProfiles && allClearanceLevels && selectedUser) {
      const { ptsProfileID, ptsClearanceLevelID } = selectedUser;
      const currentProfile = allProfiles.find(profile => profile.ptsProfileID === ptsProfileID);
      const currentClearance = allClearanceLevels.find(
        c => c.ptsClearanceLevelID === ptsClearanceLevelID
      );
      const currentAgency = agencies.find(agn => agn.AgencyID === selectedUser.AgencyID);

      getPartyPerson(selectedUser.ptsPersonID).then(person => {
        setSelectedPerson(person);
        setNewUser(user => {
          return { ...user, ptsPersonId: selectedUser.ptsPersonID };
        });
      });

      setProfile(currentProfile);
      setClearance(currentClearance);
      setUsername(selectedUser.Username);
      setIsLockedOut(selectedUser.IsLockedOut);
      setIsDisabled(selectedUser.IsDisabled);
      setEmail(selectedUser.PasswordEmail);
      setDefaultAgency(currentAgency);
      setNCICUsername(selectedUser.NCICUsername);
      setRid(selectedUser.RID);
      setOri(selectedUser.ORI);
    }
  }, [allProfiles, allClearanceLevels]);

  const fetchProfiles = async () => {
    const result = await findSearchProfiles();
    setAllProfiles(result);
  };

  const fetchClearanceLevels = async () => {
    const result = await findSearchClearanceLevels();
    setAllClearanceLevels(result);
  };

  const renderUsername = () => {
    return (
      <Box style={{ marginLeft: 10 }}>
        <TextField
          label="Username*"
          variant="outlined"
          value={username}
          disabled={selectedUser?.Username}
          size="small"
          onChange={e =>
            handleChangeWithValidation(
              'userName',
              e.target.value,
              setUsername,
              validateUserName,
              setUserNameError
            )
          }
          fullWidth
          className={classes.item}
          error={userNameError}
          helperText={userNameError ? 'Provide a valid Username.' : ''}
        />
      </Box>
    );
  };

  const renderEmail = () => {
    return (
      <Box style={{ marginLeft: 10 }}>
        <TextField
          label="Email*"
          variant="outlined"
          value={email}
          size="small"
          onChange={e =>
            handleChangeWithValidation(
              'email',
              e.target.value,
              setEmail,
              validateEmail,
              setEmailError
            )
          }
          fullWidth
          className={classes.item}
          type="email"
          error={emailError}
          helperText={emailError ? 'Please enter a valid email address' : ''}
        />
      </Box>
    );
  };

  const renderPersonLookup = () => {
    return (
      <Box style={{ marginLeft: 10 }}>
        <PersonLookup
          className={classes.item}
          onPersonChange={setPerson}
          label="Person Lookup*"
          selectedPerson={selectedPerson}
        />
      </Box>
    );
  };

  const renderProfileLookup = () => {
    return (
      <Box style={{ marginLeft: 10 }}>
        <Autocomplete
          disablePortal
          options={allProfiles || []}
          className={classes.item}
          renderInput={params => (
            <TextField {...params} label="Profile Lookup*" variant="outlined" />
          )}
          onChange={(e, value) =>
            handleChange('ptsProfileLookupId', value, setProfile, value?.ptsProfileID || null)
          }
          getOptionLabel={option => option.Label}
          renderOption={option => `${option.Label} ${option.Type ? '- ' + option.Type : ''}`}
          getOptionSelected={(option, value) => option.ptsProfileID === value?.ptsProfileID}
          size="small"
          value={profile}
        />
      </Box>
    );
  };

  const renderClearanceLevel = () => {
    return (
      <Box style={{ marginLeft: 10 }}>
        <Autocomplete
          disablePortal
          options={allClearanceLevels || []}
          className={classes.item}
          renderInput={params => (
            <TextField {...params} label="Clearance Levels Lookup" variant="outlined" />
          )}
          onChange={(e, value) =>
            handleChange(
              'ptsClearanceLevelId',
              value,
              setClearance,
              value?.ptsClearanceLevelID || null
            )
          }
          getOptionLabel={option => option.Level}
          renderOption={option =>
            `${option.Level} ${option.Description ? '- ' + option.Description : ''}`
          }
          size="small"
          value={clearance}
        />
      </Box>
    );
  };

  const renderLockedOut = () => {
    return (
      <Box style={{ marginLeft: 20 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isLockedOut}
              onChange={() =>
                handleChange('isLockedOut', !isLockedOut, setIsLockedOut, !isLockedOut)
              }
            />
          }
          label="Locked Out"
        />
      </Box>
    );
  };

  const renderDisabled = () => {
    return (
      <Box style={{ marginLeft: 20 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isDisabled}
              onChange={() => handleChange('isDisable', !isDisabled, setIsDisabled, !isDisabled)}
            />
          }
          label="Disable"
        />
      </Box>
    );
  };

  const renderForcePW = () => {
    return (
      <Box style={{ marginLeft: 20 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={forcePW}
              onChange={() => handleChange('forcePWChange', !forcePW, setForcePW, !forcePW)}
            />
          }
          label="Force Password Change"
        />
      </Box>
    );
  };

  const renderDefaultAgency = () => {
    return (
      <Box style={{ marginLeft: 10 }}>
        <Autocomplete
          disablePortal
          options={agencies || []}
          className={classes.item}
          onChange={(e, value) =>
            handleChange('defaultAgencyId', value, setDefaultAgency, value?.AgencyID || null)
          }
          getOptionLabel={option => option.AgencyID}
          renderInput={params => (
            <TextField {...params} label="Default Agency" variant="outlined" />
          )}
          renderOption={option =>
            `${option.AgencyID} ${option.Description ? '- ' + option.Description : ''}`
          }
          size="small"
          value={defaultAgency}
        />
      </Box>
    );
  };

  const renderNCICUsername = () => {
    return (
      <Box style={{ marginLeft: 10 }}>
        <TextField
          label="NCIC Username"
          variant="outlined"
          value={NCICUsername}
          size="small"
          onChange={e =>
            handleChange('NCICUserName', e.target.value, setNCICUsername, e.target.value)
          }
          fullWidth
          className={classes.item}
        />
      </Box>
    );
  };

  const renderORI = () => {
    return (
      <Box style={{ marginLeft: 10 }}>
        <TextField
          label="ORI"
          variant="outlined"
          value={ori}
          size="small"
          onChange={e => handleChange('ori', e.target.value, setOri, e.target.value)}
          fullWidth
          className={classes.item}
        />
      </Box>
    );
  };

  const renderRID = () => {
    return (
      <Box style={{ marginLeft: 10 }}>
        <TextField
          label="RID"
          variant="outlined"
          value={rid}
          size="small"
          onChange={e => handleChange('rid', e.target.value, setRid, e.target.value)}
          fullWidth
          className={classes.item}
        />
      </Box>
    );
  };

  const renderApplication = () => {
    const applicationList = [
      { name: 'RMS', index: 1 },
      { name: 'CAD', index: 2 },
      { name: 'CAD-Mobile', index: 3 },
    ];

    return (
      <Box style={{ marginLeft: 10 }}>
        <Autocomplete
          style={{ padding: 5, paddingBottom: 20 }}
          options={applicationList || []}
          disabled={selectedUser?.Username}
          disablePortal
          getOptionLabel={option => option.name}
          renderInput={params => (
            <TextField {...params} label="Default Application" variant="outlined" />
          )}
          renderOption={option => `${option.name}`}
          size="small"
          value={defaultApplication}
          onChange={(e, value) =>
            handleChange('defaultApplication', value, setDefaultApplication, value?.name || null)
          }
        />
      </Box>
    );
  };

  return (
    <div>
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        {!selectedUser?.Username && (
          <Grid item xs={12} style={{ width: '100%' }}>
            {renderApplication()}
          </Grid>
        )}
        <Grid item xs={6} style={{ width: '100%' }}>
          {renderUsername()}
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          {renderProfileLookup()}
        </Grid>
      </Grid>

      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          {renderPersonLookup()}
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          {renderClearanceLevel()}
        </Grid>
      </Grid>

      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          {renderEmail()}
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          {renderDefaultAgency()}
        </Grid>
      </Grid>

      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          {renderNCICUsername()}
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          {renderRID()}
        </Grid>
      </Grid>

      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          {renderORI()}
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          {renderLockedOut()}
        </Grid>
      </Grid>

      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          {renderDisabled()}
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          {renderForcePW()}
        </Grid>
      </Grid>
    </div>
  );
}

export default AddUser;
