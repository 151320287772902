import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { XGrid } from '@material-ui/x-grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import { handleError } from '../reducers/ErrorReducer';
import { showSpinner, hideSpinner } from '../reducers/UiReducer';
import { getSettings } from '../reducers/ConfigReducer';
import Content from './Content';
import { getService } from '../reducers/service';
import Grid from '@mui/material/Grid';
import Menu from '@material-ui/core/Menu';

import options from 'components/IconsAgency';

const useStyles = makeStyles(theme => ({
  wrap: {
    padding: theme.spacing(2),
    '& > h5': {
      marginBottom: 20,
    },
  },
  edit: {
    padding: theme.spacing(2, 0),
    paddingBottom: 12,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },
  form: {
    margin: '0 -4px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'start',
    flexGrow: 1,
  },
  buttons: {
    '& button': {
      marginLeft: theme.spacing(1),
    },
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  textField: {
    margin: '0 4px 8px',
  },
  list: {
    width: '100%',
    height: 500,
  },
  actions: {
    width: '100%',
    padding: theme.spacing(1, 0, 2),
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  iconFilter: {
    textAlign: 'center',
  },
  filterField: {
    width: 1500,
  },
}));

const iconRender = params => {
  const Icon = options[params.value];
  return Icon ? <Icon /> : ' ';
};

const initialsColumns = [
  { field: 'AgencyID', headerName: 'Agency Type', width: 250 },
  { field: 'Description', headerName: 'Description', width: 300 },
  { field: 'Icon', headerName: 'Icon', width: 150, renderCell: iconRender },
];

const ITEM_HEIGHT = 48;

function AgencyTypeIcons(props) {
  const classes = useStyles();
  const service = getService('settings-agency-type-icons');
  const { dictionary } = props;
  const { Agencies } = dictionary;
  const [view, setView] = useState('list');
  const [agencyID, setAgencyID] = useState('');
  const [selection, setSelection] = useState([]);
  const [edit, setEdit] = useState(false);
  const [saveError, setSaveError] = useState(null);
  const [agencyTypes, setAgencyTypes] = useState([]);
  const [selectedIconIndex, setSelectedIconIndex] = useState(0);
  const [filterText, setFilterText] = useState('');

  console.log('props2: ', props);

  //icon options menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleIconOptionsClick = event => {
    setFilterText('');
    setAnchorEl(event.currentTarget);
  };

  const handleIconOptionsClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (ev, idx) => {
    setSelectedIconIndex(idx);
    setAnchorEl(null);
  };

  useEffect(() => {
    renderList();
  }, [dictionary]);

  const renderList = async () => {
    const agencyTypes = Agencies.map(row => {
      return {
        ...row,
        Icon: 0,
        id: row.AgencyID,
      };
    });
    const updated = await updateIcons(agencyTypes);
    setAgencyTypes(updated);
  };

  const updateIcons = async agencies => {
    const fetchedIcons = await service.find();
    fetchedIcons.forEach(f => {
      agencies.forEach(ag => {
        if (ag.AgencyID === f.CDRKey && ag.Icon !== f.ValueNumeric) {
          ag.Icon = f.ValueNumeric;
        }
      });
    });
    return agencies;
  };

  const save = async () => {
    props.showSpinner();
    try {
      const result = await service.create({ CDRKey: agencyID, ValueNumeric: selectedIconIndex });
      if (typeof result === 'string') {
        setSaveError(result);
        props.hideSpinner();
        return;
      }
      setSaveError(null);
      props.getSettings();
      setView('list');
      setSelectedIconIndex(0);
      setFilterText('');
    } catch (err) {
      props.handleError(err, 'Error saving initials');
    }
    props.hideSpinner();
    setEdit(false);
  };

  const renderEditIcon = () => {
    const MenuIcon = options[selectedIconIndex];

    return (
      <div className={classes.edit}>
        <div className={classes.form}>
          <TextField
            className={classes.textField}
            label="Agency Type"
            value={agencyID}
            variant="outlined"
            size="small"
            disabled={edit}
          />
          <div>
            <Fab
              className="ml-2 mr-2"
              size="small"
              color="primary"
              onClick={handleIconOptionsClick}>
              <MenuIcon />
            </Fab>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleIconOptionsClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 10,
                  width: '48ch',
                },
              }}>
              <Grid container>
                {/* <Grid item xs={12} className={`${classes.iconFilter} m-3`}>
                  <TextField
                    fullWidth
                    className={classes.textField}
                    label="search"
                    value={filterText}
                    onChange={ev => setFilterText(ev.target.value)}
                    variant='outlined'
                    size="small"
                    type="search"
                  />
                </Grid> */}
                {Object.entries(options)
                  .filter(option => {
                    const iconName = option[1].name;
                    return iconName.toLowerCase().includes(filterText.toLowerCase());
                  })
                  .map(([key, Icon]) => (
                    <span
                      className="m-3"
                      key={key}
                      onClick={event => handleMenuItemClick(event, key)}>
                      <Fab size="small" color="inherit">
                        <Icon />
                      </Fab>
                    </span>
                  ))}
              </Grid>
            </Menu>
          </div>
          <div className={classes.addressWrap}>
            <div className={classes.buttons}>
              <Button variant="contained" color="primary" onClick={save}>
                <SaveIcon />
                Save
              </Button>
              <Button color="primary" onClick={handleClose}>
                <CloseIcon />
                Close
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const delIcon = async () => {
    if (!window.confirm('Are you sure you want to remove this icon?')) return;
    try {
      service.remove(selection[0]);
      renderList();
      props.getSettings();
    } catch (err) {
      props.handleError(err, 'Error deleting icon');
    }
  };

  const editIcon = () => {
    const agencyType = agencyTypes.find(a => a.AgencyID === selection[0]);
    if (!agencyType) return;
    setAgencyID(agencyType.AgencyID);
    setSelectedIconIndex(agencyType.Icon);
    setView('edit');
    setEdit(true);
  };

  const handleClose = () => {
    setView('list');
    setSelectedIconIndex(0);
    setFilterText('');
  };

  const renderActions = () => {
    const disabled = selection.length === 0;
    const selected = agencyTypes.find(a => a.AgencyID === selection[0]);
    const dltDisabled = selected?.Icon === 0;
    return (
      <div className={classes.actions}>
        <Fab size="small" color="secondary" onClick={editIcon} disabled={disabled}>
          <EditIcon />
        </Fab>
        <Fab size="small" color="secondary" onClick={delIcon} disabled={disabled || dltDisabled}>
          <DeleteIcon />
        </Fab>
        {view !== 'list' && (
          <Fab size="small" color="secondary" onClick={handleClose}>
            <CloseIcon />
          </Fab>
        )}
      </div>
    );
  };

  const handleSelectChange = ({ selectionModel }) => {
    setSelection(selectionModel);
  };

  const renderAgencyIcons = () => {
    return (
      <div className={classes.list}>
        <XGrid
          columns={initialsColumns}
          rows={agencyTypes}
          rowHeight={38}
          onSelectionModelChange={handleSelectChange}
          disableSelectionOnClick={view === 'edit'}
          disableMultipleSelection
          hideFooter
        />
      </div>
    );
  };

  return (
    <Content>
      <div className={classes.wrap}>
        <h4> Agency Type Icons </h4>
        {view === 'edit' && renderEditIcon()}
        {view === 'list' && renderActions()}
        {renderAgencyIcons()}
      </div>
    </Content>
  );
}

const mapStateToProps = state => {
  return {
    dictionary: state.config.dictionary,
  };
};

export default connect(mapStateToProps, {
  handleError,
  showSpinner,
  hideSpinner,
  getSettings,
})(AgencyTypeIcons);
