import { getService } from 'reducers/service';

const path_mail = `Platinumplus.settings.email.users.template`;
const path_url = `Platinumplus.settings.users.login.url`;

export const findEmailInDatabase = async CDRKey => {
  const cdr = getService('cdr');
  const emailData = await cdr.find({ query: { Path: path_mail, CDRKey: CDRKey } });
  const emailUrl = await cdr.find({ query: { Path: path_url, CDRKey: CDRKey } });

  if (emailData.length || emailUrl.length) return true;
  else return false;
};

export const saveCDRdata = async (CDRKey, emailText, siteUrl) => {
  const cdr = getService('cdr');

  try {
    await cdr.create({
      ValueBoolean: 1,
      Path: path_mail,
      CDRKey,
      ValueString: emailText,
    });
    await cdr.create({
      ValueBoolean: 1,
      Path: path_url,
      CDRKey,
      ValueString: siteUrl,
    });

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const updateCDRdata = async (CDRKey, emailText, siteUrl) => {
  const cdr = getService('cdr');

  try {
    await cdr.patch(null, {
      ValueBoolean: 1,
      Path: path_mail,
      CDRKey,
      ValueString: emailText,
    });
    await cdr.patch(null, {
      ValueBoolean: 1,
      Path: path_url,
      CDRKey,
      ValueString: siteUrl,
    });

    return true;
  } catch (error) {
    console.log(error);

    return false;
  }
};

export const findMailTemplateData = async CDRKey => {
  const cdr = getService('cdr');
  const emailData = await cdr.find({ query: { Path: path_mail, CDRKey: CDRKey } });
  const emailUrl = await cdr.find({ query: { Path: path_url, CDRKey: CDRKey } });

  if (emailData.length || emailUrl.length)
    return { emailText: emailData[0].ValueString, url: emailUrl[0].ValueString };
  else return { emailText: '', url: '' };
};
