import moment from 'moment';
import store from '../config/configureStore';
import htmlForPrinting from './htmlForPrinting';
const locale = window.navigator.userLanguage || window.navigator.language;
moment.locale(locale);

export const isProduction = process.env.NODE_ENV !== 'development';
export const isDevelopment = process.env.NODE_ENV === 'development';

export function formatDate(date) {
  if (!date) return;
  const fixedDate = addZtoDate(date);
  const m = moment(fixedDate);
  if (!m.isValid()) return '';
  return m.format('L LT');
}

export function formatUTCdate(date) {
  if (!date) return;
  const fixedDate = addZtoDate(date);
  const m = moment(fixedDate);
  if (!m.isValid()) return '';
  const format24 = getTimeFormat24();
  return format24 ? m.utc().format('L HH:mm') : m.utc().format('L LT');
}

export function getTimeFormat24() {
  const state = store.store.getState();
  return state?.config?.options?.timeFormat24 || false;
}

export function formatIfDate(str) {
  if (isValidDate(str)) return formatDate(str);
  return str;
}

export function getTime(date) {
  if (!date) return;
  const fixedDate = addZtoDate(date);
  const m = moment(fixedDate);
  if (!m.isValid()) return '';
  return m.format('LT');
}

export function getDate(date) {
  if (!date) return;
  const fixedDate = addZtoDate(date);
  const m = moment(fixedDate);
  if (!m.isValid()) return '';
  return m.format('L');
}

export function isValidDate(date) {
  if (typeof date !== 'string' || date.length > 24) return false;
  var dateReg = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;
  if (date.match(dateReg) !== null && moment(date).isValid()) return true;
  return false;
}

/** Fixes issue in some dates in database - adds Z at the end */
function addZtoDate(date) {
  return date + (date.substr(-1) === 'Z' ? '' : 'Z');
}

export function getInitials(status, statusDescription) {
  if (!status) return '';
  if (statusDescription) {
    const words = statusDescription.split(' ');
    if (words.length > 1) {
      return words[0][0] + words[words.length - 1][0];
    }
  }
  const words2 = status.split('-');
  if (words2.length > 1) {
    return words2[0][0] + words2[1][0];
  }
  let nw = '';
  for (let i = 0; i < status.length; i++) {
    const ch = status[i];
    if (i === 0) {
      nw += ch;
    } else if (!isVowel(ch) && nw.length < 2) nw += ch;
  }
  if (nw.length === 2) return nw;
  return status.substr(0, 2);
}

export function isVowel(char) {
  if (char.length === 1) {
    return /[aeiouyAEIOUY]/.test(char);
  }
}

export function groupAndSort(arr, sortBy, groupBy, sortDirection = 'ASC', groupDirection = 'ASC') {
  const groups = arr
    .reduce((res, ev) => {
      const key = ev[groupBy];
      if (res.indexOf(key) === -1) res.push(key);
      return res;
    }, [])
    .sort((a, b) => ((groupDirection === 'ASC' ? a > b : a < b) ? 1 : -1));
  let result = [];
  groups.forEach(val => {
    const group = arr.filter(obj => obj[groupBy] === val);
    const sorted = sortObjArr(group, sortBy, sortDirection);
    result = [...result, ...sorted];
  });
  return result;
}

export function sortObjArr(arr, sortBy, sortDirection = 'ASC') {
  if (!Array.isArray(arr)) return arr;
  const newArr = [...arr];
  newArr.sort((a, b) => {
    let valA = a[sortBy];
    let valB = b[sortBy];
    if (isValidDate(valA) && isValidDate(valB)) {
      if (sortDirection === 'ASC') {
        return moment(valB).valueOf() - moment(valA).valueOf();
      } else {
        return moment(valA).valueOf() - moment(valB).valueOf();
      }
    } else {
      if (typeof valA === 'string' && typeof valB === 'string') {
        valA = valA.toLocaleLowerCase();
        valB = valB.toLocaleLowerCase();
      }
      if (sortDirection === 'ASC') {
        return valA < valB ? -1 : valA > valB ? 1 : 0;
      } else {
        return valA > valB ? -1 : valA < valB ? 1 : 0;
      }
    }
  });
  return newArr;
}

export const asyncForEach = async (array, callback) => {
  if (!array) return;
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

/** Used in processing input phone number to format (123) 123-4567 ext 890 */
export const processInputPhoneNo = value => {
  const maxLength = 24;
  let digits = '';
  value.split('').forEach(ch => {
    const reg = /^\d+$/;
    if (ch.match(reg)) digits += ch;
  });
  const digitsArr = digits.split('');
  const len = digitsArr.length;
  len > 0 && digitsArr.splice(0, 0, '(');
  len > 3 && digitsArr.splice(4, 0, ') ');
  len > 6 && digitsArr.splice(8, 0, '-');
  len > 10 && digitsArr.splice(13, 0, ' ext ');
  const output = digitsArr.join('').substr(0, maxLength);
  return output;
};
export const processPhoneNoWithoutExtra = value => {
  if (!value) {
    return '';
  }
  const maxLength = 14;
  let digits = '';
  value.split('').forEach(ch => {
    const reg = /^\d+$/;
    if (ch.match(reg)) digits += ch;
  });
  const digitsArr = digits.split('');
  const len = digitsArr.length;
  len > 0 && digitsArr.splice(0, 0, '(');
  len > 3 && digitsArr.splice(4, 0, ') ');
  len > 6 && digitsArr.splice(8, 0, '-');

  const output = digitsArr.join('').substr(0, maxLength);
  return output;
};
export const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const areObjEqual = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);

export const tableComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getTableComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => tableComparator(a, b, orderBy)
    : (a, b) => -tableComparator(a, b, orderBy);
};

export const tableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

/**
 * The insertText() method changes the content of a string by removing a range of
 * characters and/or adding new characters.
 *
 * @this {String}
 * @param {string} text to modify.
 * @param {number} position Index at which to start changing the string.
 * @param {number} delCount An integer indicating the number of old chars to remove.
 * @param {string} newSubStr The String that is spliced in.
 * @return {string} A new string with the spliced substring.
 */
export const insertText = (string, position, newSubStr) => {
  return string.slice(0, position) + newSubStr + string.slice(position);
};

export const getExcerpt = (text, length = 60) => {
  text = text.replace(/\n/g, ' ');
  if (text.length > length) {
    text = text.substr(0, length) + '...';
  }
  return text;
};

/**
 * Convert integer number to byte string
 * @param {*} no - number to convert
 * @param {*} pad number of total string length e.g. (1, 4) = "0001" [default 8]
 */
export const decBin = (no, pad = 8) => {
  if (no < 0) {
    no = 0xffffffff + no + 1;
  }
  return parseInt(no, 10)
    .toString(2)
    .padStart(pad, '0');
};

export const formatDateFrom = date => {
  return moment(date).format('YYYY-MM-DD') + ' 00:00:00.0';
};

export const formatDateTo = date => {
  return moment(date).format('YYYY-MM-DD') + ' 23:59:59.0';
};

/**
 * returns array of filtered results - designed to work witgh DataGrid or XGrid widgets
 *
 * @param {string} filter e.g 'tango'
 * @param {array} data to be filtered
 * @param {array} columns - column definitions
 */
export const filterResults = (filter, arr, columns) => {
  filter = filter.toLocaleLowerCase();
  const result = [];
  arr.forEach(row => {
    let match = false;
    for (let i = 0; i < columns.length - 1; i++) {
      const col = columns[i].field;
      let val = row[col];
      if (typeof val === 'number') val = '' + val;
      if (typeof val === 'string') {
        if (val.toLocaleLowerCase().indexOf(filter) !== -1) {
          match = true;
          break;
        }
      }
    }
    match && result.push(row);
  });
  return result;
};

/* Used in styling flex forms */
export function getFormStyle(minWidth, maxWidth) {
  return {
    maxWidth: maxWidth,
    flexBasis: minWidth,
    minWidth: minWidth,
    flexGrow: 1,
    margin: `0 4px 8px`,
  };
}

/* Reorder list used in drag and drop ordered list */
export function reorderDnd(arr, res) {
  if (!arr) return;
  const from = res.source.index;
  const to = res.destination.index;
  arr.splice(to, 0, arr.splice(from, 1)[0]);
  return arr;
}

export function removeFromArr(arr, val) {
  const idx = arr.indexOf(val);
  arr.splice(idx, 1);
  return arr;
}

export function passValidationErrors(UserName, pass) {
  const errors = [];
  if (UserName === pass) errors.push('Cannot be the same as User ID');
  if (pass.length < 8) errors.push('Must be at least 8 characters');
  if (pass.length > 128) errors.push('Password is too long');
  if (pass.search(/[a-z]/) < 0) errors.push('Lower case character missing');
  if (pass.search(/[A-Z]/) < 0) errors.push('Upper case character missing');
  if (pass.search(/[0-9]/) < 0) errors.push('Include at least one digit');
  if (pass.search(/[!@#\$%\^&\*\.,\-+!<>=_\(\)\[\]{};:\|\?]/) < 0)
    errors.push('Special character missing or invalild');

  return errors;
}

export const printRef = (ref, title) => {
  const content = ref.current;
  let html = htmlForPrinting(content.innerHTML, `${title}`);
  const pri = document.getElementById('print-iframe').contentWindow;
  pri.document.open();
  pri.document.write(html);
  pri.document.close();
  pri.focus();
  pri.print();
};

export function arraysWithSameValues(array1, array2) {
  const arr1 = [...array1],
    arr2 = [...array2];
  const len1 = arr1.length,
    len2 = arr2.length;
  if (len1 !== len2) return false;
  arr1.sort();
  arr2.sort();
  for (let i = 0; i < len1; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
}

export function genColHeaderName(field) {
  if (!field) return 'Invalid Field';
  let current = '',
    result = '';
  for (let i = 0; i < field.length; i++) {
    if (
      field[i] == field[i].toUpperCase() &&
      current.length > 0 &&
      field[i - 1] == field[i - 1].toLowerCase()
    ) {
      if (result.length > 0) result += ' ';
      result += current;
      current = '';
    }
    current += field[i];
  }
  if (result.length > 0) return result + ' ' + current;
  return current;
}

/**
 * Advanced version of filterResults - accepts multiple words
 *
 * @param {*} filter single or multiple words 'tango inservice'
 * @param {*} rows data to be filtered
 * @param {*} cols - column definitions (strings accepted)
 * @returns [] filtered rows
 */
export const advancedFilter = (filter, rows, cols) => {
  const filters = filter
    .split(' ')
    .map(w => w.trim())
    .filter(w => Boolean(w));
  if (filters.length === 1) return filterResults(filter.trim(), rows, cols);
  const filteredRows = applyFilter(rows, filters, cols);
  return filteredRows;
};

const applyFilter = (rows, filters, cols) => {
  const filteredRows = filterResults(filters[0], rows, cols);
  if (filters.length === 1) {
    return filteredRows;
  }
  const newFilters = [...filters];
  newFilters.shift();
  return applyFilter(filteredRows, newFilters, cols);
};

export const HTMLColors = [
  { Code: 'Black', Description: 'Black', Hex: '#000000', dark: true },
  { Code: 'Navy', Description: 'Navy', Hex: '#000080', dark: true },
  { Code: 'DarkBlue', Description: 'Dark Blue', Hex: '#00008B', dark: true },
  { Code: 'MediumBlue', Description: 'Medium Blue', Hex: '#0000CD', dark: true },
  { Code: 'Blue', Description: 'Blue', Hex: '#0000FF', dark: true },
  { Code: 'DarkGreen', Description: 'Dark Green', Hex: '#006400', dark: true },
  { Code: 'Green', Description: 'Green', Hex: '#008000', dark: true },
  { Code: 'Teal', Description: 'Teal', Hex: '#008080', dark: true },
  { Code: 'DarkCyan', Description: 'Dark Cyan', Hex: '#008B8B', dark: true },
  { Code: 'DeepSkyBlue', Description: 'Deep Sky Blue', Hex: '#00BFFF', dark: true },
  { Code: 'DarkTurquoise', Description: 'Dark Turquoise', Hex: '#00CED1', dark: false },
  { Code: 'MediumSpringGreen', Description: 'Medium Spring Green', Hex: '#00FA9A', dark: false },
  { Code: 'Lime', Description: 'Lime', Hex: '#00FF00', dark: false },
  { Code: 'SpringGreen', Description: 'Spring Green', Hex: '#00FF7F', dark: false },
  { Code: 'Aqua', Description: 'Aqua', Hex: '#00FFFF', dark: false },
  { Code: 'Cyan', Description: 'Cyan', Hex: '#00FFFF', dark: false },
  { Code: 'MidnightBlue', Description: 'Midnight Blue', Hex: '#191970', dark: true },
  { Code: 'DodgerBlue', Description: 'Dodger Blue', Hex: '#1E90FF', dark: true },
  { Code: 'LightSeaGreen', Description: 'Light Sea Green', Hex: '#20B2AA', dark: true },
  { Code: 'ForestGreen', Description: 'Forest Green', Hex: '#228B22', dark: true },
  { Code: 'SeaGreen', Description: 'Sea Green', Hex: '#2E8B57', dark: true },
  { Code: 'DarkSlateGray', Description: 'Dark Slate Gray', Hex: '#2F4F4F', dark: true },
  { Code: 'LimeGreen', Description: 'Lime Green', Hex: '#32CD32', dark: false },
  { Code: 'MediumSeaGreen', Description: 'Medium Sea Green', Hex: '#3CB371', dark: true },
  { Code: 'Turquoise', Description: 'Turquoise', Hex: '#40E0D0', dark: false },
  { Code: 'RoyalBlue', Description: 'Royal Blue', Hex: '#4169E1', dark: true },
  { Code: 'SteelBlue', Description: 'Steel Blue', Hex: '#4682B4', dark: true },
  { Code: 'DarkSlateBlue', Description: 'Dark Slate Blue', Hex: '#483D8B', dark: true },
  { Code: 'MediumTurquoise', Description: 'Medium Turquoise', Hex: '#48D1CC', dark: false },
  { Code: 'Indigo', Description: 'Indigo', Hex: '#4B0082', dark: true },
  { Code: 'DarkOliveGreen', Description: 'Dark Olive Green', Hex: '#556B2F', dark: true },
  { Code: 'CadetBlue', Description: 'Cadet Blue', Hex: '#5F9EA0', dark: true },
  { Code: 'CornflowerBlue', Description: 'Cornflower Blue', Hex: '#6495ED', dark: true },
  { Code: 'RebeccaPurple', Description: 'Rebecca Purple', Hex: '#663399', dark: true },
  { Code: 'MediumAquaMarine', Description: 'Medium Aqua Marine', Hex: '#66CDAA', dark: false },
  { Code: 'DimGray', Description: 'Dim Gray', Hex: '#696969', dark: true },
  { Code: 'SlateBlue', Description: 'Slate Blue', Hex: '#6A5ACD', dark: true },
  { Code: 'OliveDrab', Description: 'Olive Drab', Hex: '#6B8E23', dark: true },
  { Code: 'SlateGray', Description: 'Slate Gray', Hex: '#708090', dark: true },
  { Code: 'LightSlateGray', Description: 'Light Slate Gray', Hex: '#778899', dark: true },
  { Code: 'MediumSlateBlue', Description: 'Medium Slate Blue', Hex: '#7B68EE', dark: true },
  { Code: 'LawnGreen', Description: 'Lawn Green', Hex: '#7CFC00', dark: false },
  { Code: 'Chartreuse', Description: 'Chartreuse', Hex: '#7FFF00', dark: false },
  { Code: 'Aquamarine', Description: 'Aquamarine', Hex: '#7FFFD4', dark: false },
  { Code: 'Maroon', Description: 'Maroon', Hex: '#800000', dark: true },
  { Code: 'Purple', Description: 'Purple', Hex: '#800080', dark: true },
  { Code: 'Olive', Description: 'Olive', Hex: '#808000', dark: true },
  { Code: 'Gray', Description: 'Gray', Hex: '#808080', dark: true },
  { Code: 'SkyBlue', Description: 'Sky Blue', Hex: '#87CEEB', dark: false },
  { Code: 'LightSkyBlue', Description: 'Light Sky Blue', Hex: '#87CEFA', dark: false },
  { Code: 'BlueViolet', Description: 'Blue Violet', Hex: '#8A2BE2', dark: true },
  { Code: 'DarkRed', Description: 'Dark Red', Hex: '#8B0000', dark: true },
  { Code: 'DarkMagenta', Description: 'Dark Magenta', Hex: '#8B008B', dark: true },
  { Code: 'SaddleBrown', Description: 'Saddle Brown', Hex: '#8B4513', dark: true },
  { Code: 'DarkSeaGreen', Description: 'Dark Sea Green', Hex: '#8FBC8F', dark: false },
  { Code: 'LightGreen', Description: 'Light Green', Hex: '#90EE90', dark: false },
  { Code: 'MediumPurple', Description: 'Medium Purple', Hex: '#9370DB', dark: true },
  { Code: 'DarkViolet', Description: 'Dark Violet', Hex: '#9400D3', dark: true },
  { Code: 'PaleGreen', Description: 'Pale Green', Hex: '#98FB98', dark: false },
  { Code: 'DarkOrchid', Description: 'Dark Orchid', Hex: '#9932CC', dark: true },
  { Code: 'YellowGreen', Description: 'Yellow Green', Hex: '#9ACD32' },
  { Code: 'Sienna', Description: 'Sienna', Hex: '#A0522D', dark: true },
  { Code: 'Brown', Description: 'Brown', Hex: '#A52A2A', dark: true },
  { Code: 'DarkGray', Description: 'Dark Gray', Hex: '#A9A9A9', dark: false },
  { Code: 'LightBlue', Description: 'Light Blue', Hex: '#ADD8E6', dark: false },
  { Code: 'GreenYellow', Description: 'Green Yellow', Hex: '#ADFF2F', dark: false },
  { Code: 'PaleTurquoise', Description: 'Pale Turquoise', Hex: '#AFEEEE', dark: false },
  { Code: 'LightSteelBlue', Description: 'Light Steel Blue', Hex: '#B0C4DE', dark: false },
  { Code: 'PowderBlue', Description: 'Powder Blue', Hex: '#B0E0E6', dark: false },
  { Code: 'FireBrick', Description: 'Fire Brick', Hex: '#B22222', dark: true },
  { Code: 'DarkGoldenRod', Description: 'Dark Golden Rod', Hex: '#B8860B', dark: true },
  { Code: 'MediumOrchid', Description: 'Medium Orchid', Hex: '#BA55D3', dark: true },
  { Code: 'RosyBrown', Description: 'Rosy Brown', Hex: '#BC8F8F', dark: false },
  { Code: 'DarkKhaki', Description: 'Dark Khaki', Hex: '#BDB76B', dark: false },
  { Code: 'Silver', Description: 'Silver', Hex: '#C0C0C0', dark: false },
  { Code: 'MediumVioletRed', Description: 'Medium VioletRed', Hex: '#C71585', dark: true },
  { Code: 'IndianRed', Description: 'Indian Red', Hex: '#CD5C5C', dark: true },
  { Code: 'Peru', Description: 'Peru', Hex: '#CD853F', dark: true },
  { Code: 'Chocolate', Description: 'Chocolate', Hex: '#D2691E', dark: true },
  { Code: 'Tan', Description: 'Tan', Hex: '#D2B48C', dark: false },
  { Code: 'LightGray', Description: 'Light Gray', Hex: '#D3D3D3', dark: false },
  { Code: 'Thistle', Description: 'Thistle', Hex: '#D8BFD8', dark: false },
  { Code: 'Orchid', Description: 'Orchid', Hex: '#DA70D6', dark: false },
  { Code: 'GoldenRod', Description: 'Golden Rod', Hex: '#DAA520', dark: false },
  { Code: 'PaleVioletRed', Description: 'Pale Violet Red', Hex: '#DB7093', dark: true },
  { Code: 'Crimson', Description: 'Crimson', Hex: '#DC143C', dark: true },
  { Code: 'Gainsboro', Description: 'Gainsboro', Hex: '#DCDCDC', dark: false },
  { Code: 'Plum', Description: 'Plum', Hex: '#DDA0DD', dark: false },
  { Code: 'BurlyWood', Description: 'BurlyWood', Hex: '#DEB887', dark: false },
  { Code: 'LightCyan', Description: 'Light Cyan', Hex: '#E0FFFF', dark: false },
  { Code: 'Lavender', Description: 'Lavender', Hex: '#E6E6FA', dark: false },
  { Code: 'DarkSalmon', Description: 'Dark Salmon', Hex: '#E9967A', dark: false },
  { Code: 'Violet', Description: 'Violet', Hex: '#EE82EE', dark: false },
  { Code: 'PaleGoldenRod', Description: 'Pale Golden Rod', Hex: '#EEE8AA', dark: false },
  { Code: 'LightCoral', Description: 'Light Coral', Hex: '#F08080', dark: false },
  { Code: 'Khaki', Description: 'Khaki', Hex: '#F0E68C', dark: false },
  { Code: 'AliceBlue', Description: 'Alice Blue', Hex: '#F0F8FF', dark: false },
  { Code: 'HoneyDew', Description: 'Honey Dew', Hex: '#F0FFF0', dark: false },
  { Code: 'Azure', Description: 'Azure', Hex: '#F0FFFF', dark: false },
  { Code: 'SandyBrown', Description: 'Sandy Brown', Hex: '#F4A460', dark: false },
  { Code: 'Wheat', Description: 'Wheat', Hex: '#F5DEB3', dark: false },
  { Code: 'Beige', Description: 'Beige', Hex: '#F5F5DC', dark: false },
  { Code: 'WhiteSmoke', Description: 'White Smoke', Hex: '#F5F5F5', dark: false },
  { Code: 'MintCream', Description: 'Mint Cream', Hex: '#F5FFFA', dark: false },
  { Code: 'GhostWhite', Description: 'Ghost White', Hex: '#F8F8FF', dark: false },
  { Code: 'Salmon', Description: 'Salmon', Hex: '#FA8072', dark: false },
  { Code: 'AntiqueWhite', Description: 'Antique White', Hex: '#FAEBD7', dark: false },
  { Code: 'Linen', Description: 'Linen', Hex: '#FAF0E6', dark: false },
  {
    Code: 'LightGoldenRodYellow',
    Description: 'Light Golden Rod Yellow',
    Hex: '#FAFAD2',
    dark: false,
  },
  { Code: 'OldLace', Description: 'Old Lace', Hex: '#FDF5E6', dark: false },
  { Code: 'Red', Description: 'Red', Hex: '#FF0000', dark: true },
  { Code: 'Fuchsia', Description: 'Fuchsia', Hex: '#FF00FF', dark: true },
  { Code: 'Magenta', Description: 'Magenta', Hex: '#FF00FF', dark: true },
  { Code: 'DeepPink', Description: 'Deep Pink', Hex: '#FF1493', dark: true },
  { Code: 'OrangeRed', Description: 'Orange Red', Hex: '#FF4500', dark: true },
  { Code: 'Tomato', Description: 'Tomato', Hex: '#FF6347', dark: true },
  { Code: 'HotPink', Description: 'Hot Pink', Hex: '#FF69B4', dark: false },
  { Code: 'Coral', Description: 'Coral', Hex: '#FF7F50', dark: false },
  { Code: 'DarkOrange', Description: 'Dark Orange', Hex: '#FF8C00', dark: false },
  { Code: 'LightSalmon', Description: 'Light Salmon', Hex: '#FFA07A', dark: false },
  { Code: 'Orange', Description: 'Orange', Hex: '#FFA500', dark: false },
  { Code: 'LightPink', Description: 'Light Pink', Hex: '#FFB6C1', dark: false },
  { Code: 'Pink', Description: 'Pink', Hex: '#FFC0CB', dark: false },
  { Code: 'Gold', Description: 'Gold', Hex: '#FFD700', dark: false },
  { Code: 'PeachPuff', Description: 'Peach Puff', Hex: '#FFDAB9', dark: false },
  { Code: 'NavajoWhite', Description: 'Navajo White', Hex: '#FFDEAD', dark: false },
  { Code: 'Moccasin', Description: 'Moccasin', Hex: '#FFE4B5', dark: false },
  { Code: 'Bisque', Description: 'Bisque', Hex: '#FFE4C4', dark: false },
  { Code: 'MistyRose', Description: 'Misty Rose', Hex: '#FFE4E1', dark: false },
  { Code: 'BlanchedAlmond', Description: 'Blanched Almond', Hex: '#FFEBCD', dark: false },
  { Code: 'PapayaWhip', Description: 'Papaya Whip', Hex: '#FFEFD5', dark: false },
  { Code: 'LavenderBlush', Description: 'Lavender Blush', Hex: '#FFF0F5', dark: false },
  { Code: 'SeaShell', Description: 'Sea Shell', Hex: '#FFF5EE', dark: false },
  { Code: 'Cornsilk', Description: 'Cornsilk', Hex: '#FFF8DC', dark: false },
  { Code: 'LemonChiffon', Description: 'Lemon Chiffon', Hex: '#FFFACD', dark: false },
  { Code: 'FloralWhite', Description: 'Floral White', Hex: '#FFFAF0', dark: false },
  { Code: 'Snow', Description: 'Snow', Hex: '#FFFAFA', dark: false },
  { Code: 'Yellow', Description: 'Yellow', Hex: '#FFFF00', dark: false },
  { Code: 'LightYellow', Description: 'Light Yellow', Hex: '#FFFFE0', dark: false },
  { Code: 'Ivory', Description: 'Ivory', Hex: '#FFFFF0', dark: false },
  { Code: 'White', Description: 'White', Hex: '#FFFFFF', dark: false },
];
export const makeAddress = (street, city, state, zip) => {
  const address = `${street ? street + ',' : ''} ${city ? city + ',' : ''} ${state ? state : ''} ${
    zip ? zip : ''
  }`;
  return address;
};

const createExampleText = obj => {
  const defaultValues = {
    prefix: 1,
    number: 2,
    month: 3,
    day: 4,
    year: 5,
    suffix: 6,
  };
  const arr = [];
  for (let i = 0; i < Object.keys(defaultValues).length; i++) {
    arr.push('');
  }
};
