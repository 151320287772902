import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { closeEditAgency } from 'reducers/DialogsReducer';
import Dialog from 'components/Dialog';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { handleError } from 'reducers/ErrorReducer';
import AgencyTab from 'Dialogs/Agencies/AddAgency/AgencyTab';
import AgencyContact from './AgencyContact';
import AgencyEmployees from './AgencyEmployees';
import AgencyVehicles from './AgencyVehicles';
import AgencyAddresses from './AgencyAddresses';
import AgencySettings from './AgencySettings';
import AgencyCad from './AgencyCad';
import AgencyRms from './AgencyRms';

const useStyles = makeStyles(theme => ({
  wrap: {
    padding: theme.spacing(3),
  },
  content: {
    margin: -theme.spacing(3),
  },
}));

function EditAgency(props) {
  const classes = useStyles();
  const { AgencyID } = props;
  const [tab, setTab] = useState('cad'); // edit
  const [agencyPlaceDepartmentId, setAgencyPlaceDepartmentId] = useState(null);
  const mountedRef = useRef(true);
  const newAddress = !AgencyID;

  useEffect(() => {
    if (props.tab) setTab(props.tab);

    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, [props.tab]);

  const close = data => {
    props.closeEditAgency(data);
  };

  return (
    <Dialog toolbar onClose={close} title={newAddress ? 'Add Agency' : 'Edit Agency'} fullScreen>
      <div className={classes.content}>
        <Paper square>
          <Tabs variant="scrollable" value={tab} onChange={(ev, val) => setTab(val)}>
            <Tab label="Edit Agency" value="edit" />
            <Tab label="Address" value="address" />
            <Tab label="Contact" value="contact" />
            <Tab label="Employees" value="employees" />
            <Tab label="Vehicle Registrations" value="vehicles" />
            <Tab label="Settings" value="settings" />
            <Tab label="CAD" value="cad" />
            <Tab label="RMS" value="rms" />
          </Tabs>
        </Paper>
        {tab === 'edit' && (
          <div className={classes.wrap}>
            <AgencyTab
              agencyId={AgencyID}
              close={close}
              setAgencyPlaceDepartmentId={setAgencyPlaceDepartmentId}
            />
          </div>
        )}
        {tab === 'address' && <AgencyAddresses agencyId={AgencyID} />}
        {tab === 'contact' && <AgencyContact agencyId={AgencyID} />}
        {tab === 'employees' && <AgencyEmployees agencyId={AgencyID} />}
        {tab === 'vehicles' && <AgencyVehicles agencyId={AgencyID} />}
        {tab === 'settings' && <AgencySettings agencyId={AgencyID} />}
        {tab === 'cad' && (
          <AgencyCad agencyId={AgencyID} placeDepartmentId={agencyPlaceDepartmentId} />
        )}
        {tab === 'rms' && <AgencyRms agencyId={AgencyID} />}
      </div>
    </Dialog>
  );
}

export default connect(null, {
  closeEditAgency,
  handleError,
})(EditAgency);
