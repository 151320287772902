import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Content from 'Settings/Content';
import { findClearanceLevels } from '../SecFunctions';
import { XGrid } from '@material-ui/x-grid';
import { handleError } from 'reducers/ErrorReducer';
import Fab from '@material-ui/core/Fab';
import QuickFilter from 'components/QuickFilter';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from 'components/Tooltip';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { showAddEditClearanceLevel } from 'reducers/DialogsReducer';
import { getService } from 'reducers/service';
import { notify } from 'reducers/NotifierReducer';
const useStyles = makeStyles(theme => ({
  wrap: {
    padding: theme.spacing(2),
    '& > h5': {
      marginBottom: 20,
    },
  },
  edit: {
    padding: theme.spacing(2, 0),
    paddingBottom: 12,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },
  form: {
    margin: '0 -4px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'start',
    flexGrow: 1,
  },
  buttons: {
    '& button': {
      marginLeft: theme.spacing(1),
    },
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  textField: {
    margin: '0 4px 8px',
  },
  list: {
    width: '100%',
    height: 500,
  },
  actions: {
    width: '100%',
    padding: theme.spacing(1, 0, 2),
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 500,
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const columns = [
  { field: 'Level', headerName: 'Level', width: 250 },
  { field: 'Description', headerName: 'Description', width: 250 },
  { field: 'Sequence', headerName: 'Sequence', width: 250 },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 250 },
];

function Clearance(props) {
  const classes = useStyles();
  const [selection, setSelection] = useState([]);
  const [rows, setRows] = useState([]);
  const service = getService('settings-sec-clearance-level');
  const [filteredRows, setFilteredRows] = useState([]);
  const [open, setOpen] = useState(false);
  const addEditClearanceLevel = useSelector(state => state.dialogs.addEditClearanceLevel);
  const dispatch = useDispatch();
  useEffect(() => {
    fetchData();
  }, [addEditClearanceLevel]);

  const fetchData = async () => {
    try {
      const result = await findClearanceLevels();
      setRows(processData(result));
    } catch (err) {
      props.handleError(err, 'Error, cannot get data.');
    }
  };

  const processData = data => {
    return data.map(row => {
      return {
        ...row,
        id: row.ptsClearanceLevelID,
      };
    });
  };
  const handleSelectChange = ({ selectionModel }) => {
    setSelection(selectionModel);
  };
  const onClickAdd = () => {
    dispatch(showAddEditClearanceLevel({ type: 'add', data: {} }));
    setOpen(true);
  };
  const onClickDelete = async () => {
    try {
      const removeAgency = await service.remove(selection[0]);

      const newRows = rows.filter(el => el.ptsClearanceLevelID != removeAgency);
      setRows(newRows);
      props.notify('Deleted', 'warning');
      setSelection([]);
    } catch (err) {
      props.handleError(err, 'Error, File not Deleted.', err);
    }
  };
  const onClickEdit = () => {
    const obj = rows.find(row => row.ptsClearanceLevelID == selection[0]);
    dispatch(showAddEditClearanceLevel({ type: 'edit', info: obj }));
    setOpen(true);
  };

  const renderActions = () => {
    const disabled = selection?.length === 0;
    const selected = rows.find(a => a.id === selection[0]);
    //const dltDisabled = selected?.Icon === 0;
    return (
      <div className={classes.actions}>
        <div>
          <Fab size="small" color="secondary" onClick={onClickAdd}>
            <AddIcon />
          </Fab>
          <Fab size="small" color="secondary" onClick={onClickEdit} disabled={disabled}>
            <EditIcon />
          </Fab>
          <Fab size="small" color="secondary" onClick={onClickDelete} disabled={disabled}>
            <DeleteIcon />
          </Fab>
        </div>
      </div>
    );
  };
  // const handleDetails = () => {
  //   console.log('selection: ', selection)
  // }

  const renderList = () => {
    return (
      <div className={classes.gridWrap}>
        <XGrid
          columns={columns}
          rows={filteredRows}
          getRowId={row => row.ptsClearanceLevelID}
          rowHeight={38}
          disableMultipleSelection={true}
          showToolbar
          disableColumnFilter
          onSelectionModelChange={handleSelectChange}
        />
      </div>
    );
  };

  return (
    <Content>
      <div className={classes.wrap}>
        <h4> Clearance Levels </h4>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className={classes.filterWrap}>
            <div className={classes.filterWrap}>
              <QuickFilter rows={rows} columns={columns} setFilteredRows={setFilteredRows} />
            </div>
            {renderActions()}
          </div>
        </MuiPickersUtilsProvider>
        {renderList()}
      </div>
    </Content>
  );
}

export default connect(null, { handleError, notify })(Clearance);
