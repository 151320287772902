import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Content from 'Settings/Content';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import {getService} from 'reducers/service';
import { handleError } from 'reducers/ErrorReducer';
import { notify } from 'reducers/NotifierReducer';
import Autocomplete2 from 'components/Autocomplete2';
import { 
  gridStyle, Row, RowInner, ColCard, Fills
} from 'utils/formStyles';
import TextField2 from 'components/TextField2';
import Checkbox2 from 'components/Checkbox2';

const useStyles = makeStyles((theme) => ({
  item: {
    ...gridStyle(200, 800),
  }
}));

function MobileApp(props) {
  const { States } = props.dictionary;
  const classes = useStyles();
  const service = getService('settings-mobile');
  const [defaultState, setDefaultState] = useState(null);
  const [idle, setIdle] = useState('');
  const [xgridKey, setXgridKey] = useState('');
  const [ncicPassValidate, setNcicPassValidate] = useState(false);
  const [eslintNoDevError, setEslintNoDevError] = useState(false);
  const [savePass, setSavePass] = useState(false);
  const [featuresCitations, setFeaturesCitations] = useState(false);
  const [avl, setAvl] = useState(false);
  const [yearRequired, setYearRequired] = useState(false);
  const [disableEslint, setDisableEslint] = useState(false);

  useEffect(() => {
    search();
  }, [States]);

  const search = async () => {
    const data = await service.find({
      query: { type: 'mobile'}
    });
    setInitialData(data);
  }

  const setInitialData = (data) => {
    for (const row of data) {
      switch(row.CDRKey) {
        case 'APP_DEFAULT_STATE':
          const state = States?.find(s => s.Code === row.ValueString) || null;
          setDefaultState(state);
          break;
        case 'APP_IDLE_TIMEOUT':
          setIdle(row.ValueString || '');
          break;
        case 'APP_XGRID_KEY':
          setXgridKey(row.ValueString || '');
          break;
        case 'AVL':
          setAvl(row.ValueBoolean);
          break;
        case 'DISABLE_ESLINT':
          setDisableEslint(row.ValueBoolean);
          break;
        case 'ESLINT_NO_DEV_ERRORS':
          setEslintNoDevError(row.ValueBoolean);
          break;
        case 'FEATURES_CITATIONS':
          setFeaturesCitations(row.ValueBoolean);
          break;
        case 'NCIC_PASS_VALIDATE':
          setNcicPassValidate(row.ValueBoolean);
          break;
        case 'SAVE_PASS':
          setSavePass(row.ValueBoolean);
          break;
        case 'YEAR_REQUIRED':
          setYearRequired(row.ValueBoolean);
          break;  
      }
    }
  }

  const formatSaveData = () => {
    return [
      {
        CDRKey: 'APP_DEFAULT_STATE',
        ValueString: defaultState?.Code || null,
        ValueBoolean: null,
      },
      {
        CDRKey: 'APP_IDLE_TIMEOUT',
        ValueString: idle || null,
        ValueBoolean: null,
      },
      {
        CDRKey: 'APP_XGRID_KEY',
        ValueString: xgridKey || null,
        ValueBoolean: null,
      },
      {
        CDRKey: 'AVL',
        ValueString: null,
        ValueBoolean: avl,
      },
      {
        CDRKey: 'DISABLE_ESLINT',
        ValueString: null,
        ValueBoolean: disableEslint,
      },
      {
        CDRKey: 'ESLINT_NO_DEV_ERRORS',
        ValueString: null,
        ValueBoolean: eslintNoDevError,
      },
      {
        CDRKey: 'FEATURES_CITATIONS',
        ValueString: null,
        ValueBoolean: featuresCitations,
      },
      {
        CDRKey: 'NCIC_PASS_VALIDATE',
        ValueString: null,
        ValueBoolean: ncicPassValidate,
      },
      {
        CDRKey: 'SAVE_PASS',
        ValueString: null,
        ValueBoolean: savePass,
      },
      {
        CDRKey: 'YEAR_REQUIRED',
        ValueString: null,
        ValueBoolean: yearRequired,
      },
    ]
  }

  const handleSaveClick = async () => {
    const data = formatSaveData();
    try {
      await service.create({ dataArr: data, type: 'mobile' });  
      props.notify("Settings Saved.", "success");
    }
    catch (err) {
      props.handleError(err, 'Error Saving Settings.');
    }
  }

  const renderDefaultState = () => {
    return (
      <div>
        <Autocomplete2
          options={States}
          className={classes.item}
          onChange={(ev, val) => setDefaultState(val)}
          value={defaultState}
          label="Default State"
        />
      </div>
    )
  }

  const renderIdleTimeout = () => {
    const onChange = (ev, val) => setIdle(val);
    return (
      <TextField2
        className={classes.item}
        label="Idle Timeout"
        value={idle}
        onChange={onChange}
      />
    );
  }

  const renderXgridKey = () => {
    const onChange = (ev, val) => setXgridKey(val);
    return (
      <TextField2
        className={classes.item}
        label="Xgrid Key"
        value={xgridKey}
        onChange={onChange}
      />
    );
  }

  const renderNcicPassValidate = () => {
    return (
      <Checkbox2
        checked={ncicPassValidate}
        onChange={() => setNcicPassValidate(!ncicPassValidate)}
        label="NCIC Pass Validate"
      />
    )
  }
  const renderEslintNoDevError = () => {
    return (
      <Checkbox2
        checked={eslintNoDevError}
        onChange={() => setEslintNoDevError(!eslintNoDevError)}
        label="Eslint No Dev Errors"
        
      />
    )
  }
  const renderSavePass = () => {
    return (
      <Checkbox2
        checked={savePass}
        onChange={() => setSavePass(!savePass)}
        label="Save Pass"
        
      />
    )
  }
  const renderFeaturesCitations = () => {
    return (
      <Checkbox2
        checked={featuresCitations}
        onChange={() => setFeaturesCitations(!featuresCitations)}
        label="Features Citations"
        
      />
    )
  }
  const renderAvl = () => {
    return (
      <Checkbox2
        checked={avl}
        onChange={() => setAvl(!avl)}
        label="AVL"
        
      />
    )
  }
  const renderYearRequired = () => {
    return (
      <Checkbox2
        checked={yearRequired}
        onChange={() => setYearRequired(!yearRequired)}
        label="Year Required"
        
      />
    )
  }
  const renderDisableEslint = () => {
    return (
      <Checkbox2
        checked={disableEslint}
        onChange={() => setDisableEslint(!disableEslint)}
        label="Disable Eslint"
        
      />
    )
  }

  const renderSaveBtn = () => {
    return (
      <Button 
        onClick={handleSaveClick} 
        variant="contained" 
        className="mt-4"
        color="primary">
        <SaveIcon className="mr-2" /> Save 
      </Button> 
    )
  }

  return (
    <Content>
      <div className='m-4'>
        <Row>
          <ColCard minWidth={500}>
            {renderDefaultState()}
            <RowInner>
              {renderIdleTimeout()}
              {renderXgridKey()}
            </RowInner>
          </ColCard>
          <ColCard minWidth={500}>
            <RowInner>
              {renderNcicPassValidate()}
              {renderEslintNoDevError()}
              {renderSavePass()}
              {renderFeaturesCitations()}
              {renderAvl()}
              {renderYearRequired()}
              {renderDisableEslint()}
              <Fills className={classes.item} />
            </RowInner>
          </ColCard>
        </Row>
        {renderSaveBtn()}
      </div>
    </Content>
  )
}

const mapStateToProps = state => {
  return {
    dictionary: state.dictionary
  }
}

export default connect(mapStateToProps, {
  handleError, notify, 
})(MobileApp);