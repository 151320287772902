import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Content from './Content';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import {getService} from 'reducers/service';
import { handleError } from 'reducers/ErrorReducer';
import { notify } from 'reducers/NotifierReducer';

const useStyles = makeStyles((theme) => ({
  
}));

function RipAndRun(props) {
  const classes = useStyles();
  const service = getService('settings-rip-and-run');
  const [isEnabled, setIsEnabled] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const result = await service.find();
    for (const r of result) {
      const { Path, ValueBoolean, ValueString } = r;
      if (Path === "RipAndRun.Email" && ValueString) setEmail(ValueString);
      else if (Path === "RipAndRun.IsEnable" && ValueBoolean) setIsEnabled(ValueBoolean);
    }
  }

  const handleSave = async () => {
    const data = {
      IsEnabled: isEnabled,
      Email: email || null
    };
    try {
      await service.create(data);
      props.notify('Settings updated!', 'success');
    }
    catch(error) {
      props.handleError(error, 'Cannot update settings');
    }
  }

  const renderCheckbox = () => {
    return (
      <div>
        <FormControlLabel 
          control={
            <Checkbox 
              checked={isEnabled}
              onChange={() => setIsEnabled(!isEnabled)}
              color="primary"
            />
          } 
          label='Enable Rip & Run' 
        />
      </div>
    )
  }

  const renderEmail = () => {
    return (
      <div>
        <TextField
          label="Email Address" 
          variant="outlined"
          size="small"
          value={email}
          onChange={e => setEmail(e.target.value)}
          disabled={!isEnabled}
          style={{minWidth: 300}}
        />
      </div>
    )
  }

  const renderSave = () => {
    return (
      <Button 
        color="primary"
        variant="contained"
        onClick={handleSave}
        className="mt-4"
      >
        <SaveIcon /> Save
      </Button>
    )
  }
  
  return (
    <Content>
      <div className='m-4'>
        <h3 className='mb-4'>Rip And Run</h3>
        {renderCheckbox()}
        {renderEmail()}
        {renderSave()}
      </div>
    </Content>
  );
}


export default connect(null, {
  handleError, notify, 
})(RipAndRun);
