import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from 'components/Dialog';
import { closeAddSecUser } from 'reducers/DialogsReducer';
import { Button, DialogContentText } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import AddUser from './AddUser';
import defaultUserObj from './defaultUserObj';
import { createSearchUser, updateSearchUser } from 'Settings/Security/SecFunctions';
import { handleError } from 'reducers/ErrorReducer';
import { notify } from 'reducers/NotifierReducer';

const useStyles = makeStyles(theme => ({}));

const AddSecUserDialog = props => {
  const classes = useStyles();

  const [newUser, setNewUser] = useState(defaultUserObj);
  const { data } = props;

  useEffect(() => {
    if (data.selectedUser) {
      const selectedUserData = {
        UserId: data.selectedUser.ptsUserID,
        userName: data.selectedUser.Username,
        ptsProfileLookupId: data.selectedUser.ptsProfileID,
        ptsClearanceLevelId: data.selectedUser.ptsClearanceLevelID,
        ptsPersonId: data.selectedUser.ptsPersonID,
        email: data.selectedUser.PasswordEmail,
        defaultAgencyId: data.selectedUser.AgencyID,
        NCICUserName: data.selectedUser.NCICUsername,
        rid: data.selectedUser.RID,
        ori: data.selectedUser.ORI,
        isLockedOut: data.selectedUser.IsLockedOut,
        isDisable: data.selectedUser.IsDisabled,
        forcePWChange: false,
      };
      setNewUser(selectedUserData);
    }
  }, []);

  const close = () => {
    props.closeAddSecUser();
  };

  const save = async () => {
    if (validateUserData()) {
      if (newUser.UserId) {
        try {
          close();
          await updateSearchUser(newUser.UserId, newUser);
          props.notify('User Updated', 'success');
        } catch (error) {
          console.log(error);
          props.handleError(error, 'Error Updating User');
        }
      } else {
        try {
          close();
          await createSearchUser(newUser);
          props.notify('User Created', 'success');
        } catch (error) {
          console.log(error);
          props.handleError(error, 'Error Creating User');
        }
      }
      await data.fetchUsers();
      data.setSelection(null);
    }
  };

  const validateUserData = () => {
    if (!newUser.defaultApplication && !newUser.UserId) {
      props.notify('Default Application is required.', 'error');
      return false;
    }
    if (!newUser.userName) {
      props.notify('Username is required.', 'error');
      return false;
    }
    if (!newUser.ptsPersonId) {
      props.notify('Person field can not be empty.', 'error');
      return false;
    }
    if (!newUser.ptsProfileLookupId) {
      props.notify('Profile Lookup is required.', 'error');
      return false;
    }
    if (!newUser.email) {
      props.notify('Email is required.', 'error');
      return false;
    }

    return true;
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={save}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  return (
    <Dialog
      toolbar
      onClose={close}
      title={newUser.UserId ? 'Update User' : 'Add User'}
      actions={renderActions()}
      maxWidth="md"
      fullWidth>
      <AddUser
        setNewUser={setNewUser}
        selectedUser={data.selectedUser ? data.selectedUser : null}
      />
      <DialogContentText style={{ marginTop: 10 }}>
        Note: * means the field's value is required
      </DialogContentText>
    </Dialog>
  );
};

const mapStateToProps = state => {
  return {
    dictionary: state.dictionary,
  };
};

export default connect(mapStateToProps, { closeAddSecUser, handleError, notify })(AddSecUserDialog);
