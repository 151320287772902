import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox2 from 'components/Checkbox2';
import TextField2 from 'components/TextField2';
import {gridStyle} from 'utils/formStyles';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import { getService } from 'reducers/service';
import { handleError } from 'reducers/ErrorReducer';
import { notify } from 'reducers/NotifierReducer';

const useStyles = makeStyles((theme) => ({
  item: {
    ...gridStyle(200, 800),
  }
}));

function DispatchTooltips(props) {
  const classes = useStyles();
  const service = getService('settings-appearance-tooltips');
  const [isShow, setIsShow] = useState(false);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    const search = async () => {
      const data = await service.find();
      const show = data.find(row => row.CDRKey === 'Show');
      setIsShow(show.ValueBoolean);
      const showDuration = data.find(row => row.CDRKey === 'ShowDuration');
      setDuration(showDuration.ValueString);
    }

    search();
  }, []);

  const handleSaveClick = async () => {
    const data = [
      {
        CDRKey: "Show",
        ValueString: null,
        ValueBoolean: isShow
      },
      {
        CDRKey: "ShowDuration",
        ValueString: duration || 0,
        ValueBoolean: null
      },
    ];
    try {
      await service.create(data);
      props.notify("Toptips Saved.", "success");
    }
    catch (err) {
      props.handleError(err, 'Error Saving Toptips.');
    }
  }

  const renderShow = () => {
    return (
      <Checkbox2
        checked={isShow}
        onChange={() => setIsShow(!isShow)}
        label="Show"
        className={classes.item}
      />
    )
  }

  const renderDuration = () => {
    const onChange = (ev, val) => setDuration(val);
    return (
      <TextField2
        className={classes.item}
        label="Seconds"
        value={duration}
        onChange={onChange}
      />
    );
  }

  const renderSaveBtn = () => {
    return (
      <Button 
        onClick={handleSaveClick} 
        variant="contained" 
        className="mt-2 ml-2"
        color="primary">
        <SaveIcon className="mr-2" /> Save 
      </Button> 
    )
  }

  return (
    <>
      <div>
        {renderShow()}
        {renderDuration()}
      </div>
      {renderSaveBtn()}
    </>
  )
}


export default connect(null, {
  handleError, notify, 
})(DispatchTooltips);