import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import QuickFilterSearch from 'components/QuickFilterSearch';
import XGrid3 from 'components/XGrid3';
import Tooltip from 'components/Tooltip';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { getAgency, findVehicleReg } from 'Settings/Agencies/AgencyFunctions';
import { showVehicleEditReg } from 'reducers/DialogsReducer';


const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: theme.spacing(3),
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  fileInput: {
    display: 'none',
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: 8,
  },
}));

const defaultColumns = [
  { field: 'VehicleID', headerName: 'Vehicle ID'},
  { field: 'ptsRegistrationID', headerName: 'RegistrationID'},
  { field: 'PlateType', headerName: 'Plate Type'},
  { field: 'PlateColor', headerName: 'Plate Color'},
  { field: 'PlateNumber', headerName: 'Plate Number'},
  { field: 'PlateState', headerName: 'Plate State'},
  { field: 'PlateBackgroundText', headerName: 'BackgroundText'},
  { field: 'PlateDetail', headerName: 'PlateDetail'},
  { field: 'PlateStatus', headerName: 'PlateStatus'},
  {
    field: 'PlateExpiration',
    headerName: 'Plate Expiration',
    format: 'date',
  },
  { field: 'OdometerReading', headerName: 'OdometerReading'},
  { field: 'InsuranceCompany', headerName: 'Insurance Company'},
  { field: 'VehicleValue', headerName: 'Vehicle Value'},
  {
    field: 'Created',
    headerName: 'Created',
    format: 'date',
  },
  { field: 'CreatedBy', headerName: 'Created By'},
  {
    field: 'Updated',
    headerName: 'Updated',
    format: 'date',
  },
  { field: 'UpdatedBy', headerName: 'Updated By'},
];

function AgencyVehicles(props) {
  const classes = useStyles();
  const { agencyId, dataUpdate } = props;
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [columns, setColumns] = useState(defaultColumns);
  const [selection, setSelection] = useState(null);
  const [ptsPlaceID, setPtsPlaceID] = useState(null);


  useEffect(() => {
    search();
  }, []);

  useEffect(() => {
    if (dataUpdate && dataUpdate.type === "vehicle-reg") search();
  }, [dataUpdate])

  const search = async () => {
    const curAgency = await getAgency(agencyId);
    const { ptsPlaceID } = curAgency;
    setPtsPlaceID(ptsPlaceID);
    const result = await findVehicleReg(ptsPlaceID);
    setRows(processData(result));
  }

  const processData = (data) => {
    return data.map((row) => ({ ...row, id: row.ptsRegistrationID }));
  };

  const add = () => props.showVehicleEditReg({
    PartyType: 'Place',
    ptsParentID: ptsPlaceID,
    disableParty: true
  });

  const edit = () => {
    const item = rows.find(row => row.id == selection);
    props.showVehicleEditReg({ ...item, disableParty: true });
  }

  return (
    <div className={classes.wrap}>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickFilterSearch rows={rows} columns={columns} setFilteredRows={setFilteredRows} />
        </div>
        <div className={classes.actions}>
          <Tooltip title="Add">
            <Fab size="small" color="secondary" onClick={add}>
              <AddIcon />
            </Fab>
          </Tooltip>

          {selection !== null && (
            <>
              <Tooltip title="Edit">
                <Fab size="small" color="secondary" onClick={edit}>
                  <EditIcon />
                </Fab>
              </Tooltip>
            </>
          )}
        </div>
      </div>
      <XGrid3 
        columns={columns}
        setColumns={setColumns}
        rows={filteredRows}
        setSelection={setSelection}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    dataUpdate: state.dataUpdate
  };
};

export default connect(mapStateToProps, { showVehicleEditReg })(AgencyVehicles);
